import { School } from '../models/schools';
import { Package } from '../models/packages';
import { User } from '../models/users';
import { USER_TYPE, USER_TYPE_SERVER_TO_CLIENT_MAPPING } from '../consts/user';
import * as Moment from 'moment';
import * as Lazy from 'lazy.js';
import * as propz from 'propz';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '../consts/consts';
import { MENU_ITEMS, MENU_ITEMS_SERVER_TO_CLIENT_MAPPING } from '../consts/sidebar';
import { Job } from '../models/jobs';
import { JOB_STATUS_SERVER_TO_CLIENT_MAPPING } from '../consts/job';
import { Order } from '../models/orders';
import {
  ORDER_STATUS_SERVER_TO_CLIENT_MAPPING,
  ORDER_DELIVERY_TYPE_SERVER_TO_CLIENT_MAPPING,
  ORDER_STATUS_CUSTOMER_SERVER_TO_CLIENT_MAPPING,
  ORDER_SOURCE_SERVER_TO_CLIENT_MAPPING,
} from '../consts/order';
import { PRICE } from '../consts/offer';
import { CURRENCY_SYMBOL } from '../consts/common';
import { Notification } from '../models/notifications';
import { NOTIFICATION_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING } from '../consts/notifications';
import { NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING } from '../consts/notificationChannels';
import { ACTION_DESCRIPTOR_TYPE_SERVER_TO_CLIENT_MAPPING } from '../consts/actionDescriptor';
import { Offer } from '../models/offers';
import { Slider } from '../models/slider';
import { SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING } from '../consts/slider';
import { Customer } from '../models/customers';
import { INTEGRATION_LOG_STATUS_TYPES_SERVER_TO_CLIENT_MAPPING } from '../consts/integrationLog';
import { IntegrationLog } from '../models/integrationLogs';
import { STUDENT_REQUEST_STATUS_SERVER_TO_CLIENT_MAPPING } from '../consts/customer';
import { SCHOOL_JOB_STATUS_SERVER_TO_CLIENT_MAPPING } from '../consts/schoolJob';
import { AppSchoolsUser2 } from '../views/AppSchools2/AppSchools2';

export function getSchoolTypes(school: School): string {
  const types = school.schoolTypes;
  const typeNames = Array.isArray(types) ? types.map(type => type.name).join('\n') : '';
  return typeNames;
}

export function getIsPreviewAvailable(item): string {
  const isPreviewAvailable = item.settings.isPreviewAvailable;

  return isPreviewAvailable ? 'Yes' : 'No';
}

export function getOutsidePackageAvailable(item): string {
  const isOutsidePackageAvailable = item.settings.isOutsidePackageAvailable;

  return isOutsidePackageAvailable ? 'Yes' : 'No';
}

export function getSharedIsPreRegistrationsUser(item): string {
  const isShared = item.shared;

  return isShared ? 'Yes' : 'No';
}

export function getIncludedInReprint(item): string {
  const isIncludedInReprint = item.isIncludedInReprint;

  return isIncludedInReprint ? 'Yes' : '';
}

export function getIsDigitalProduct(item): string {
  const isDigitalProduct = item.settings.isDigitalProduct;

  return isDigitalProduct ? 'Yes' : 'No';
}

export function getIsNoAutoAcceptAvailable(item): string {
  const isAutoAcceptAvailable = item.isAutoAcceptAvailable;

  return isAutoAcceptAvailable ? '' : 'Yes';
}

export function getIsActiveSliderImage(item): string {
  const isActiveSliderImage = item.isActive;

  return isActiveSliderImage ? 'Yes' : 'No';
}

export function getPackageProducts(packageItem: Package): string {
  const products = packageItem.products;
  const productNames = Array.isArray(products) ? products.map(product => product.name).join('\n') : '';
  return productNames;
}

export function getPackagePriceSetProducts(item: any): string {
  const products = item.products;
  const productNames = Array.isArray(products) ? products.map(product => product.name).join('\n') : '';
  return productNames;
}

export function getPackagePriceSetProductsPrice(item: any): string {
  const products = item.products;
  const productNames = Array.isArray(products) ? products.map(product => product.pricing.price).join('\n') : '';
  return productNames;
}

export function getPhotoTypeName(item): string {
  return propz.get(item, ['photoType', 'name'], '');
}

export function getIsSchoolDeliveryAvailable(item): string {
  const isSchoolDeliveryAvailable = item.properties.isSchoolDeliveryAvailable;

  return isSchoolDeliveryAvailable ? 'Yes' : 'No';
}

export function getIsSendNotificationOnImagesUpload(item): string {
  const isSendNotificationOnImagesUpload = item.properties.isSendNotificationOnImagesUpload;

  return isSendNotificationOnImagesUpload ? 'Yes' : 'No';
}

export function getUploadUser(item: any): string {
  const firstName = propz.get(item, ['uploadUser', 'firstName'], '');
  const lastName = propz.get(item, ['uploadUser', 'lastName'], '');

  return `${firstName}\n${lastName}`;
}

export function getUploadDate(item: any): string {
  const uploadDate = item.uploadDate;

  if (typeof uploadDate !== 'undefined') {
    const uploadDateFormatted = Moment(new Date(uploadDate)).format(DATE_TIME_FORMAT);
    return uploadDateFormatted;
  } else {
    return '';
  }
}

export function getNotificationsSentAt(item: any): string {
  const notificationsSentAt = item.notificationsSentAt;

  return typeof notificationsSentAt !== 'undefined'
    ? Moment(new Date(notificationsSentAt)).format(DATE_TIME_FORMAT)
    : '';
}

export function getCreatedAtWithTime(item: any): string {
  const createdAt = item.createdAt;

  if (typeof createdAt !== 'undefined') {
    return Moment(new Date(createdAt)).format(DATE_TIME_FORMAT);
  } else {
    return '';
  }
}

export function getCreatedAt(item: any): string {
  const createdAt = item.createdAt;
  return typeof createdAt !== 'undefined' ? Moment(new Date(createdAt)).format(DATE_FORMAT) : '';
}

export function getUpdatedAt(item: any): string {
  const updatedAt = item.updatedAt;
  return typeof updatedAt !== 'undefined' ? Moment(new Date(updatedAt)).format(DATE_FORMAT) : '';
}

export function getStatusUpdatedAt(item: any): string {
  const statusUpdatedAt = item.statusUpdatedAt;
  return typeof statusUpdatedAt !== 'undefined' ? Moment(new Date(statusUpdatedAt)).format(DATE_FORMAT) : '';
}

export function getUpdatedAtWithTime(item: any): string {
  const updatedAt = item.updatedAt;

  if (typeof updatedAt !== 'undefined') {
    return Moment(new Date(updatedAt)).format(DATE_TIME_FORMAT);
  } else {
    return '';
  }
}

export function getStudentBirthday(item: any): string {
  const birthdayDate = propz.get(item, ['birthday']);
  if (typeof birthdayDate === 'undefined' || birthdayDate === null) {
    return '';
  } else {
    return Moment(new Date(birthdayDate)).format(DATE_FORMAT);
  }
}

export function getImageJobDate(item: any): string {
  const jobDate = propz.get(item, ['job', 'jobDate']);

  if (typeof jobDate !== 'undefined') {
    return Moment(new Date(jobDate)).format(DATE_FORMAT);
  } else {
    return '';
  }
}

export function getUserType(user: User): string {
  const type = user.type;

  return typeof type !== 'undefined' ? USER_TYPE_SERVER_TO_CLIENT_MAPPING[type] : '';
}

export function getUserMenuItems(user: User): string {
  if (user.type !== USER_TYPE.ADMIN) {
    return '';
  }

  const result = [];
  const menuItemsEnabled = user.menuItemsEnabled;

  for (let menuItem in MENU_ITEMS) {
    if (menuItemsEnabled[menuItem] === true) {
      result.push(MENU_ITEMS_SERVER_TO_CLIENT_MAPPING[menuItem]);
    }
  }

  return result.join('\n');
}

export function getJobDate(item: any): string {
  const jobDate = item.jobDate;

  if (typeof jobDate === 'undefined' || jobDate === null) {
    return '';
  }

  const jobDateFormatted = Moment(new Date(jobDate)).format(DATE_FORMAT);
  return jobDateFormatted;
}

export function getJobStatus(job: Job): string {
  const status = job.status;

  return typeof status !== 'undefined' ? JOB_STATUS_SERVER_TO_CLIENT_MAPPING[status] : '';
}

export function getSchoolJobStatus(job: Job): string {
  const status = job.status;

  return typeof status !== 'undefined' ? SCHOOL_JOB_STATUS_SERVER_TO_CLIENT_MAPPING[status] : '';
}

export function getOrderStatus(order: Order): string {
  const orderStatus = order.orderStatus;

  return typeof orderStatus !== 'undefined' ? ORDER_STATUS_SERVER_TO_CLIENT_MAPPING[orderStatus] : '';
}

export function getOrderStatusCustomer(order: Order): string {
  const orderStatus = order.orderStatus;

  return typeof orderStatus !== 'undefined' ? ORDER_STATUS_CUSTOMER_SERVER_TO_CLIENT_MAPPING[orderStatus] : '';
}

export function getDeliveryType(order: Order): string {
  const deliveryType = order.deliveryType;

  return typeof deliveryType !== 'undefined' ? ORDER_DELIVERY_TYPE_SERVER_TO_CLIENT_MAPPING[deliveryType] : '';
}

export function getStartDate({ startDate }): string {
  return typeof startDate !== 'undefined' ? Moment(startDate).format(DATE_FORMAT) : '';
}

export function getEndDate({ endDate }): string {
  return typeof endDate !== 'undefined' ? Moment(endDate).format(DATE_FORMAT) : '';
}

export function getProductOfferPrices(offer: Offer): string {
  const { productOffers } = offer;

  return productOffers
    .map(productOffer => {
      const { priceType, price } = productOffer;
      switch (priceType) {
        case PRICE.AMOUNT:
          return `${CURRENCY_SYMBOL.POUND}${price}`;
        case PRICE.PERCENT:
          return `${price}%`;
        default:
          return price;
      }
    })
    .join('\n');
}

export function getProductOfferNames(offer: Offer): string {
  const { productOffers } = offer;

  return productOffers.map(productOffer => productOffer.name).join('\n');
}

export function getNotificationStatus(notification: Notification): string {
  const notificationStatus = notification.status;

  return typeof notificationStatus !== 'undefined'
    ? NOTIFICATION_DELIVERY_STATUS_SERVER_TO_CLIENT_MAPPING[notificationStatus]
    : '';
}

export function getNotificationChannelType(notification: Notification): string {
  const notificationChannelType = propz.get(notification, ['channel', 'type'], undefined);

  return typeof notificationChannelType !== 'undefined'
    ? NOTIFICATION_CHANNELS_TYPE_SERVER_TO_CLIENT_MAPPING[notificationChannelType]
    : '';
}

export function getNotificationActionDescriptorType(notification: Notification): string {
  const actionDescriptorType = notification.actionDescriptorType;

  return typeof actionDescriptorType !== 'undefined'
    ? ACTION_DESCRIPTOR_TYPE_SERVER_TO_CLIENT_MAPPING[actionDescriptorType]
    : '';
}

export function getSliderType(slider: Slider): string {
  const sliderType = slider.type;

  return typeof sliderType !== 'undefined' ? SLIDER_TYPES_SERVER_TO_CLIENT_MAPPING[sliderType] : '';
}

export function getSmsSentDate(customer: Customer): string {
  const smsSentAt = propz.get(customer, ['verification', 'tokens', 'smsSentAt']);

  return typeof smsSentAt !== 'undefined' ? Moment(new Date(smsSentAt)).format(DATE_TIME_FORMAT) : '';
}

export function getEmailSentDate(customer: Customer): string {
  const emailSentAt = propz.get(customer, ['verification', 'tokens', 'emailSentAt']);

  return typeof emailSentAt !== 'undefined' ? Moment(new Date(emailSentAt)).format(DATE_TIME_FORMAT) : '';
}

export function getIntegrationLogStatus(integrationLog: IntegrationLog): void {
  const status = integrationLog.status;

  return typeof status !== 'undefined' ? INTEGRATION_LOG_STATUS_TYPES_SERVER_TO_CLIENT_MAPPING[status] : '';
}

export function getIsBillingPrimary({ isBillingPrimary }): string {
  return isBillingPrimary ? 'Primary' : '';
}

export function getIsDeliveryPrimary({ isDeliveryPrimary }): string {
  return isDeliveryPrimary ? 'Primary' : '';
}

export function getPromoEmail(customer: Customer): string {
  const promoEmail = propz.get(customer, ['agreement', 'promoEmail']);
  return promoEmail ? 'Yes' : 'No';
}

export function getPromoEmailUpdatedAt(customer: Customer): string {
  const promoEmailUpdated = propz.get(customer, ['agreement', 'promoEmailUpdated']);
  return typeof promoEmailUpdated === 'undefined' ? '' : promoEmailUpdated;
}

export function getOrderSource(order: Order): string {
  const orderSource = order.orderSource;

  return typeof orderSource !== 'undefined' ? ORDER_SOURCE_SERVER_TO_CLIENT_MAPPING[orderSource] : '';
}

export function getCreatorNote(item: any): string {
  const firstName = propz.get(item, ['createdBy', 'firstName'], '');
  const lastName = propz.get(item, ['createdBy', 'lastName'], '');

  return `${firstName}\n${lastName}`;
}

export function getCustomerName(customer: Customer): string {
  const firstName = propz.get(customer, ['firstName'], '');
  const lastName = propz.get(customer, ['lastName'], '');

  return `${firstName} ${lastName}`;
}

export function getCustomerStudents(customer: Customer): string {
  const students = customer.students;
  const studentsJoined = Array.isArray(students)
    ? students
        .filter(student => {
          const studentId = propz.get(student, ['student', 'studentId']);
          return typeof studentId !== 'undefined';
        })
        .map(student => {
          const forename = propz.get(student, ['student', 'forename'], '');
          const surname = propz.get(student, ['student', 'surname'], '');
          return `${forename} ${surname}`;
        })
        .join('\n')
    : '';
  return studentsJoined;
}

export function getCustomerWithStudentsView(customer: Customer): string {
  const students = customer.students;
  const studentsJoined = Array.isArray(students)
    ? students
        .map(student => {
          const studentId = propz.get(student, ['student', 'studentId']);
          const isConfirmed = typeof studentId !== 'undefined';
          const forename = isConfirmed
            ? propz.get(student, ['student', 'forename'], '')
            : propz.get(student, ['forename'], '');
          const surname = isConfirmed
            ? propz.get(student, ['student', 'surname'], '')
            : propz.get(student, ['surname'], '');
          return `${forename} ${surname}`;
        })
        .join('\n')
    : '';
  return studentsJoined;
}

export function getCustomerStudentsRequestStatus(customer: Customer): string {
  const students = customer.students;
  const studentsJoined = Array.isArray(students)
    ? students.map(student => STUDENT_REQUEST_STATUS_SERVER_TO_CLIENT_MAPPING[student.requestStatus]).join('\n')
    : '';
  return studentsJoined;
}

export function getCustomerStudentSchools(customer: Customer): string {
  const students = customer.students;
  const studentsJoined = Array.isArray(students)
    ? Lazy(students)
        .filter(student => {
          const studentId = propz.get(student, ['student', 'studentId']);
          return typeof studentId !== 'undefined';
        })
        .map(student => `${propz.get(student, ['school', 'schoolName'], '')}`)
        .uniq()
        .toArray()
        .join('\n')
    : '';
  return studentsJoined;
}

export function getCustomerWithStudentSchoolsView(customer: Customer): string {
  const students = customer.students;
  return Array.isArray(students)
    ? students
        .map((student, index) => {
          if (student.manualSchoolName) {
            return `Manual School: ${student.manualSchoolName}`;
          } else if (student.school?.schoolName) {
            return student.school.schoolName;
          } else if (student.selectedSchool?.schoolName) {
            return student.selectedSchool.schoolName;
          }
          console.warn(`Student at index ${index} is missing school information`, student);
          return 'N/A';
        })
        .join('\n')
    : '';
}

export function getCustomerStudentForms(customer: Customer): string {
  const students = customer.students;
  return Array.isArray(students)
    ? students
        .map((student, index) => {
          if (student.form?.formName) {
            return student.form.formName;
          } else if (student.manualFormName) {
            return student.manualFormName;
          }
          console.warn(`Student at index ${index} is missing form information`, student);
          return 'N/A';
        })
        .join('\n')
    : '';
}

export function getBpImageIds(item: any): string {
  const bpImageId = item.bpImageId;
  const bpImageIds = Array.isArray(bpImageId) ? bpImageId.join('\n') : '';
  return bpImageIds;
}

export function getIsDataNotificationEnabled(schoolUser: AppSchoolsUser2): string {
  const isDataNotificationEnabled = propz.get(schoolUser, ['isDataNotificationEnabled']);
  return isDataNotificationEnabled ? 'Yes' : 'No';
}

export function getIsSchoolAdmin(schoolUser: AppSchoolsUser2): string {
  const isSchoolAdmin = propz.get(schoolUser, ['isSchoolAdmin']);
  return isSchoolAdmin ? 'Yes' : 'No';
}

import * as React from 'react';
import { Field, ErrorMessage } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

interface SortableItemProps {
  index: number;
  student: { _id?: string; name: string; surname: string; order?: number };
  values: any;
  setFieldValue: (field: string, value: any) => void;
  setHasChanges: (hasChanges: boolean) => void;
  onDragStart: (index: number) => void;
  onDrop: (index: number) => void;
  fontName: string;
}

const fontMapping: Record<string, string> = {
  ARIAL: 'Arial',
  GILL_SANS: 'Gill Sans',
  HELVETICA: 'Helvetica',
  TIMES_NEW_ROMAN: 'Times New Roman',
};

const SortableItem: React.FC<SortableItemProps> = ({
  index,
  student,
  values,
  setFieldValue,
  setHasChanges,
  onDragStart,
  onDrop,
  fontName,
}) => {
  const handleRemove = () => {
    const newStudents = [...values.groupStudents];
    newStudents.splice(index, 1);
    setFieldValue('groupStudents', newStudents);
    setHasChanges(true);
  };

  return (
    <div
      draggable
      onDragStart={() => onDragStart(index)}
      onDragOver={e => e.preventDefault()}
      onDrop={() => onDrop(index)}
      style={{
        width: 'calc(33.33% - 10px)',
        padding: '4px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div style={{ marginRight: '0.5rem', cursor: 'grab' }}>
        <FontAwesomeIcon icon={faBars} style={{ color: '#27ae60' }} />
      </div>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '4px' }}>
        <Field
          name={`groupStudents[${index}].name`}
          placeholder="Name"
          className="custom-form-control"
          style={{
            fontSize: '0.8rem',
            padding: '0.25rem',
            marginBottom: 0,
            fontFamily: fontMapping[fontName],
          }}
        />
        <Field
          name={`groupStudents[${index}].surname`}
          placeholder="Surname"
          className="custom-form-control"
          style={{
            fontSize: '0.8rem',
            padding: '0.25rem',
            fontFamily: fontMapping[fontName],
          }}
        />
        <ErrorMessage
          name={`groupStudents[${index}].surname`}
          render={msg => (
            <div className="alert alert-danger mt-1" style={{ fontSize: '0.7rem' }}>
              {msg}
            </div>
          )}
        />
      </div>
      <button type="button" className="btn btn-danger btn-sm" onClick={handleRemove} style={{ marginLeft: '0.5rem' }}>
        &times;
      </button>
    </div>
  );
};

export default SortableItem;

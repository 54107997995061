import { FILTER_TYPE, LIMIT, SORT } from '../consts/table';
import * as propz from 'propz';
import * as Moment from 'moment';
import { NOTIFICATION_DELIVERY_STATUS_FOR_SELECT } from '../consts/notifications';
import { PLUS_REPLACEMENT } from '../consts/common';

export function getPagesCount(total: number, limit: number): number {
  return Math.ceil(total / limit);
}

export type TABLE_SORT_DIRECTION = 'ASC' | 'DESC' | '';

export interface SelectOption {
  text: string;
  value: string;
}

interface BasicColumnDefinition {
  text: string;
  field: string;
  isSort: boolean;
  isShort?: boolean;
  type?: string;
}

export interface PathAccessorColumnDefinition extends BasicColumnDefinition {
  accessor: string[];
}

export interface FunctionAccessorColumnDefinition extends BasicColumnDefinition {
  accessor: (item, user?) => any;
}

export interface CustomCellColumnDefinition extends BasicColumnDefinition {
  cell: (item) => JSX.Element; // it is jsx. Creator should set key
}

export type ColumnDefinition =
  | PathAccessorColumnDefinition
  | FunctionAccessorColumnDefinition
  | CustomCellColumnDefinition;

export function isPathAccessorBasedColumnDefinition(cd: ColumnDefinition): cd is PathAccessorColumnDefinition {
  return Array.isArray((cd as any).accessor);
}

export function isFunctionAccessorBasedColumnDefinition(cd: ColumnDefinition): cd is FunctionAccessorColumnDefinition {
  return typeof (cd as any).accessor === 'function';
}

export function isCustomCellColumnDefinition(cd: ColumnDefinition): cd is CustomCellColumnDefinition {
  return typeof (cd as any).cell === 'function';
}

export function isFilterExist(filters): boolean {
  let result = [];
  for (let key in filters) {
    const filterValue = filters[key];
    const isFilterValueArray = Array.isArray(filterValue);
    const isFilterValueString = typeof filterValue === 'string';
    const isFilterValueArrayOrString = isFilterValueArray || isFilterValueString;
    const isFilterValueObject = typeof filterValue === 'object';

    //ex: birthday: {to: '...', from: '...'}
    if (isFilterValueObject && Object.keys(filterValue).length > 0) {
      for (let innerKey in filterValue) {
        //may be useful
        //result.push(filterValue[innerKey] !== '' && filterValue[innerKey] !== false);
        result.push(filterValue[innerKey] !== '');
      }
    }

    if (isFilterValueArrayOrString && filterValue.length > 0) {
      result.push(true);
    }
  }

  return result.some(check => check === true);
}

export function isSortExist(sortDirection, sortByField): boolean {
  return sortDirection !== '' && sortByField !== '';
}

export function getServerFieldSectionWhere(filters, options?) {
  let where = {};

  for (let filterField in filters) {
    const isEmptyString = filters[filterField] === '';
    const isEmptyArray = Array.isArray(filters[filterField]) && filters[filterField].length === 0;
    const filterValue = filters[filterField];

    if (!isEmptyString && !isEmptyArray) {
      switch (true) {
        case filterField === 'menuItems': {
          filterValue.forEach(filterVal => {
            where[`menuItemsEnabled.${filterVal}`] = true;
          });
          break;
        }

        case filterField === 'schoolTypes': {
          let typeIds;
          if (Array.isArray(filterValue)) {
            typeIds = filterValue;
          } else {
            typeIds = [filterValue];
          }

          if (typeIds.length > 0) {
            where['schoolTypes.typeId'] = { $in: typeIds };
          }
          break;
        }

        case filterField === 'orderStatus': {
          let statuses;
          if (Array.isArray(filterValue)) {
            statuses = filterValue;
          } else {
            statuses = [filterValue];
          }

          if (statuses.length > 0) {
            where['orderStatus'] = { $in: statuses };
          }
          break;
        }

        case filterField === 'deliveryType': {
          let deliveryTypes;
          if (Array.isArray(filterValue)) {
            deliveryTypes = filterValue;
          } else {
            deliveryTypes = [filterValue];
          }

          if (deliveryTypes.length > 0) {
            where['deliveryType'] = { $in: deliveryTypes };
          }
          break;
        }

        case filterField === 'orderSource': {
          let orderSources;
          if (Array.isArray(filterValue)) {
            orderSources = filterValue;
          } else {
            orderSources = [filterValue];
          }

          if (orderSources.length > 0) {
            where['orderSource'] = { $in: orderSources };
          }
          break;
        }

        case filterField === 'createdBy': {
          const email = filterValue;
          if (email.length > 0) {
            where['$or'] = [
              { customerEmail: { like: email, options: 'i' } },
              {
                'invoice.email': {
                  like: email,
                  options: 'i',
                },
              },
              { 'delivery.email': { like: email, options: 'i' } },
            ];
          }
          break;
        }

        case filterField === 'customerStudents': {
          const name = filterValue;
          if (name.length > 0) {
            where['$or'] = [
              { 'students.forename': { like: name, options: 'i' } },
              { 'students.surname': { like: name, options: 'i' } },
            ];
          }
          break;
        }

        case filterField === 'customerName': {
          const name = filterValue;
          if (name.length > 0) {
            where['$or'] = [{ firstName: { like: name, options: 'i' } }, { lastName: { like: name, options: 'i' } }];
          }
          break;
        }

        case filterField === 'customerStudentSchools':
          propz.set(where, ['students.school.schoolName'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'postcode': {
          const postcode = filterValue;
          if (postcode.length > 0) {
            where['$or'] = [
              {
                'invoice.postcode': {
                  like: postcode,
                  options: 'i',
                },
              },
              { 'delivery.postcode': { like: postcode, options: 'i' } },
            ];
          }
          break;
        }

        case filterField === 'userTypes': {
          let userTypes;
          if (Array.isArray(filterValue)) {
            userTypes = filterValue;
          } else {
            userTypes = [filterValue];
          }

          if (userTypes.length > 0) {
            where['type'] = { $in: userTypes };
          }
          break;
        }

        case filterField === 'integrationLogStatus': {
          let statusTypes;
          if (Array.isArray(filterValue)) {
            statusTypes = filterValue;
          } else {
            statusTypes = [filterValue];
          }

          if (statusTypes.length > 0) {
            where['status'] = { $in: statusTypes };
          }
          break;
        }

        case filterField === 'requestStatus': {
          let statuses;
          if (Array.isArray(filterValue)) {
            statuses = filterValue;
          } else {
            statuses = [filterValue];
          }

          if (statuses.length > 0) {
            where['students.requestStatus'] = { $in: statuses };
          }
          break;
        }

        case filterField === 'photoType': {
          let typeIds;
          if (Array.isArray(filterValue)) {
            typeIds = filterValue;
          } else {
            typeIds = [filterValue];
          }

          if (typeIds.length > 0) {
            where['photoType.typeId'] = { $in: typeIds };
          }
          break;
        }

        case filterField === 'productType': {
          let typeIds;
          if (Array.isArray(filterValue)) {
            typeIds = filterValue;
          } else {
            typeIds = [filterValue];
          }

          if (typeIds.length > 0) {
            where['productType.typeId'] = { $in: typeIds };
          }
          break;
        }

        case filterField === 'noAutoAcceptReason': {
          let noAutoAcceptReasons;
          if (Array.isArray(filterValue)) {
            noAutoAcceptReasons = filterValue;
          } else {
            noAutoAcceptReasons = [filterValue];
          }

          if (noAutoAcceptReasons.length > 0) {
            where['students.reasonForCancellation'] = { $in: noAutoAcceptReasons };
          }
          break;
        }

        case filterField === 'orderImagesSchoolName':
          propz.set(where, ['school.name'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'nameOfSchool':
          propz.set(where, ['school.name'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'form':
          propz.set(where, ['form.formName'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'isAutoAcceptAvailable':
          propz.set(where, ['isAutoAcceptAvailable'], filterValue);
          break;

        case filterField === 'schoolName':
          propz.set(where, ['schoolDelivery.schoolName'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'schoolCode':
          propz.set(where, ['school.code'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'packageName':
          propz.set(where, ['package.name'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'priceSetName':
          propz.set(where, ['priceSet.name'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'studentFirstName':
          propz.set(where, ['student.firstName'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'offerProductName':
          propz.set(where, ['product.name'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'offerProductOfferName':
          propz.set(where, ['productOffer.name'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'studentLastName':
          propz.set(where, ['student.lastName'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'studentClass':
          propz.set(where, ['student.class'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'staffDataTitle':
          propz.set(where, ['staffData.title'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'staffDataJobTitle':
          propz.set(where, ['staffData.jobTitle'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'staffDataName':
          propz.set(where, ['staffData.name'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'staffDataSurname':
          propz.set(where, ['staffData.surname'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'orderImagesOrderNumber':
          propz.set(where, ['order.orderNumber'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'jobReferenceImages':
          propz.set(where, ['job.jobReference'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'productName':
          propz.set(where, ['product.name'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'statusNotification':
          if (filterValue === NOTIFICATION_DELIVERY_STATUS_FOR_SELECT.OTHER) {
            where['status'] = {
              $nin: [NOTIFICATION_DELIVERY_STATUS_FOR_SELECT.ERROR, NOTIFICATION_DELIVERY_STATUS_FOR_SELECT.SENT],
            };
          } else {
            where['status'] = { $in: filterValue };
          }
          break;

        case filterField === 'channelType':
          propz.set(where, ['channel.type'], filterValue);
          break;

        case filterField === 'promoEmail':
          propz.set(where, ['agreement.promoEmail'], filterValue);
          break;

        case filterField === 'isSchoolDeliveryAvailable':
          propz.set(where, ['properties.isSchoolDeliveryAvailable'], filterValue);
          break;

        case filterField === 'isSendNotificationOnImagesUpload':
          propz.set(where, ['properties.isSendNotificationOnImagesUpload'], filterValue);
          break;

        case filterField === 'isPreviewAvailable':
          propz.set(where, ['settings.isPreviewAvailable'], filterValue);
          break;

        case filterField === 'isOutsidePackageAvailable':
          propz.set(where, ['settings.isOutsidePackageAvailable'], filterValue);
          break;

        case filterField === 'isDigitalProduct':
          propz.set(where, ['settings.isDigitalProduct'], filterValue);
          break;

        case filterField === 'shared':
          propz.set(where, ['shared'], filterValue);
          break;

        case filterField === 'isDataNotificationEnabled':
          propz.set(where, ['isDataNotificationEnabled'], filterValue);
          break;

        case filterField === 'isSchoolAdmin':
          propz.set(where, ['isSchoolAdmin'], filterValue);
          break;

        case filterField === 'isPreferred':
          propz.set(where, ['isPreferred'], filterValue);
          break;

        case filterField === 'isHidden':
          propz.set(where, ['isHidden'], filterValue);
          break;

        case filterField === 'imageJobDate': {
          if (typeof filters['imageJobDate'].to !== 'undefined' && filters['imageJobDate'].to !== '') {
            const toFormatted = Moment(filters['imageJobDate'].to, 'DD-MM-YYYY').toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);

            where['job.jobDate'] = { ...where['job.jobDate'] };
            where['job.jobDate'].$lte = toFormatted.toISOString();
          }
          if (typeof filters['imageJobDate'].from !== 'undefined' && filters['imageJobDate'].from !== '') {
            const fromFormatted = Moment(filters['imageJobDate'].from, 'DD-MM-YYYY').toDate();
            where['job.jobDate'] = { ...where['job.jobDate'] };
            where['job.jobDate'].$gte = fromFormatted.toISOString();
          }
          break;
        }

        case filterField === 'birthday':
        case filterField === 'jobDate':
        case filterField === 'updatedAt':
        case filterField === 'createdAt':
        case filterField === 'timestamp': {
          if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
            const toFormatted = Moment(filters[filterField].to, 'DD-MM-YYYY').toDate();
            toFormatted.setHours(23);
            toFormatted.setMinutes(59);

            where[filterField] = { ...where[filterField] };
            where[filterField].$lte = toFormatted.toISOString();
          }
          if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
            const fromFormatted = Moment(filters[filterField].from, 'DD-MM-YYYY').toDate();
            where[filterField] = { ...where[filterField] };
            where[filterField].$gte = fromFormatted.toISOString();
          }
          break;
        }

        case filterField === 'orderAmount':
        case filterField === 'deliveryAmount': {
          if (typeof filters[filterField].to !== 'undefined' && filters[filterField].to !== '') {
            where[filterField] = { ...where[filterField] };
            where[filterField].$lte = filterValue.to;
          }
          if (typeof filters[filterField].from !== 'undefined' && filters[filterField].from !== '') {
            where[filterField] = { ...where[filterField] };
            where[filterField].$gte = filterValue.from;
          }
          break;
        }

        case filterField === 'productPrice': {
          if (typeof filters['productPrice'].to !== 'undefined' && filters['productPrice'].to !== '') {
            where['product.price'] = { ...where['product.price'] };
            where['product.price'].$lte = filterValue.to;
          }
          if (typeof filters['productPrice'].from !== 'undefined' && filters['productPrice'].from !== '') {
            where['product.price'] = { ...where['product.price'] };
            where['product.price'].$gte = filterValue.from;
          }
          break;
        }

        case filterField === 'creatorNote':
          propz.set(where, ['createdBy.email'], {
            like: filterValue,
            options: 'i',
          });
          break;

        case filterField === 'studentFirstNameWithCheckbox': {
          const { checked, value } = filterValue;
          if (checked) {
            where['$or'] = [{ 'student.firstName': { $exists: false } }, { 'student.firstName': '' }];
          }

          if (value) {
            propz.set(where, ['student.firstName'], {
              like: value,
              options: 'i',
            });
          }

          break;
        }

        case filterField === 'studentLastNameWithCheckbox': {
          const { checked, value } = filterValue;
          if (checked) {
            where['$or'] = [{ 'student.lastName': { $exists: false } }, { 'student.lastName': '' }];
          }

          if (value) {
            propz.set(where, ['student.lastName'], {
              like: value,
              options: 'i',
            });
          }

          break;
        }

        case filterField === 'userName': {
          const userName = filterValue;
          if (userName.length > 0) {
            where['$or'] = [
              { userName: { like: userName, options: 'i' } },
              { userSurname: { like: userName, options: 'i' } },
              { userEmail: { like: userName, options: 'i' } },
            ];
          }
          break;
        }

        default: {
          where[filterField] = { like: filterValue, options: 'i' };
        }
      }
    }
  }

  return where;
}

export function getSortColumnsNameRedefined(order: string): string {
  let sortColumnsNameRedefined;
  const [sortColumnsName, sortDirection] = order.split(' ');

  switch (sortColumnsName) {
    case 'isSchoolDeliveryAvailable':
      sortColumnsNameRedefined = 'properties.isSchoolDeliveryAvailable';
      break;
    case 'isSendNotificationOnImagesUpload':
      sortColumnsNameRedefined = 'properties.isSendNotificationOnImagesUpload';
      break;
    case 'isPreviewAvailable':
      sortColumnsNameRedefined = 'settings.isPreviewAvailable';
      break;
    case 'isOutsidePackageAvailable':
      sortColumnsNameRedefined = 'settings.isOutsidePackageAvailable';
      break;
    case 'isDigitalProduct':
      sortColumnsNameRedefined = 'settings.isDigitalProduct';
      break;
    case 'basePrice':
      sortColumnsNameRedefined = 'pricing.price';
      break;
    case 'deliveryCharge':
      sortColumnsNameRedefined = 'pricing.deliveryCharge';
      break;
    case 'orderImagesSchoolName':
      sortColumnsNameRedefined = 'school.name';
      break;
    case 'nameOfSchool':
      sortColumnsNameRedefined = 'school.name';
      break;
    case 'schoolName':
      sortColumnsNameRedefined = 'schoolDelivery.schoolName';
      break;
    case 'schoolId':
      sortColumnsNameRedefined = 'school.schoolId';
      break;
    case 'imageJobDate':
      sortColumnsNameRedefined = 'job.jobDate';
      break;
    case 'photoType':
      sortColumnsNameRedefined = 'photoType.name';
      break;
    case 'offerProductName':
      sortColumnsNameRedefined = 'product.name';
      break;
    case 'offerProductOfferName':
      sortColumnsNameRedefined = 'productOffer.name';
      break;
    case 'schoolCode':
      sortColumnsNameRedefined = 'school.code';
      break;
    case 'orderImagesOrderNumber':
      sortColumnsNameRedefined = 'order.orderNumber';
      break;
    case 'jobReference':
      sortColumnsNameRedefined = 'job.jobReference';
      break;
    case 'productName':
      sortColumnsNameRedefined = 'product.name';
      break;
    case 'productPrice':
      sortColumnsNameRedefined = 'product.price';
      break;
    default:
      sortColumnsNameRedefined = sortColumnsName;
  }

  return `${sortColumnsNameRedefined} ${sortDirection}`;
}

export function getServerQueryFilter(currentPage: number, where, order?: string) {
  const isFilter = Object.keys(where).length > 0;
  const isOrderExist = typeof order !== 'undefined';

  switch (true) {
    //sort and filter
    case isOrderExist && isFilter: {
      return {
        limit: LIMIT,
        skip: (currentPage - 1) * LIMIT,
        where: where,
        order: getSortColumnsNameRedefined(order),
      };
    }
    //only sort
    case isOrderExist: {
      return {
        limit: LIMIT,
        skip: (currentPage - 1) * LIMIT,
        order: getSortColumnsNameRedefined(order),
      };
    }
    //only filter
    case isFilter: {
      return {
        limit: LIMIT,
        skip: (currentPage - 1) * LIMIT,
        where: where,
      };
    }
    //without sort and without filter
    default:
      return {
        limit: LIMIT,
        skip: (currentPage - 1) * LIMIT,
      };
  }
}

export function getOrder(sortField: string, sortDirection: string, sortByField: string): string {
  let order = sortDirection;
  if (sortField !== sortByField) {
    order = SORT.ASC;
  } else {
    order = sortDirection === SORT.ASC ? SORT.DESC : SORT.ASC;
  }

  return order;
}

export function getSearchFilter(filters): string {
  let search = [];

  for (let field in filters) {
    const fieldValue = filters[field];
    const isFieldValueString = typeof fieldValue === 'string';
    const isFieldValueArray = Array.isArray(fieldValue);
    const isFieldValueObject = typeof fieldValue === 'object';

    switch (true) {
      case fieldValue !== '' && isFieldValueString:
        search.push(`${field}=${fieldValue}`);
        break;
      case fieldValue !== '' && isFieldValueArray:
        const subSearch = fieldValue.map(value => `${field}=${value}`).join('&');
        search.push(subSearch);
        break;
      case fieldValue !== '' && isFieldValueObject:
        let addInSearch = false;

        for (let innerField in fieldValue) {
          const innerFieldValue = fieldValue[innerField];
          if (innerFieldValue !== '') {
            addInSearch = true;
          }
        }

        for (let innerField in fieldValue) {
          const innerFieldValue = fieldValue[innerField];
          if (addInSearch) {
            search.push(`${field}=${innerField}_${innerFieldValue}`);
          }
        }
        break;
    }
  }

  return search.filter(val => val !== '').join('&');
}

export function getSearchOrder(sortDirection: string, sortByField: string): string {
  return `order=${sortByField}:${sortDirection}`;
}

export function getColSpanNumber(tableHead): number {
  return tableHead.length + 1;
}

export function getFilters(columns: ColumnDefinition[], search): any {
  const filters = {};

  const columnsWithFilter = columns.filter(column => column.type !== FILTER_TYPE.NONE);

  columnsWithFilter.forEach(filter => {
    const { type, field } = filter;

    const isFilterTypeMultiSelect = type === FILTER_TYPE.MULTISELECT;
    const isFilterTypeAutocomplete = type === FILTER_TYPE.AUTOCOMPLETE;
    const isFilterTypeTextWithCheckbox = type === FILTER_TYPE.TEXT_WITH_CHECKBOX;
    const searchField = search[field];
    const isSearchFieldUndefined = typeof searchField === 'undefined';
    const isSearchFieldString = typeof searchField === 'string';
    const isSearchFieldArray = Array.isArray(searchField);

    switch (true) {
      case isFilterTypeMultiSelect && isSearchFieldUndefined:
        filters[field] = [];
        break;
      case isFilterTypeMultiSelect && isSearchFieldString:
        filters[field] = [searchField];
        break;
      case isFilterTypeMultiSelect && !isSearchFieldString:
        filters[field] = searchField;
        break;
      case isFilterTypeAutocomplete && isSearchFieldUndefined:
        filters[field] = '';
        break;
      case isFilterTypeAutocomplete && !isSearchFieldUndefined:
        //query-parse inserts space instead plus symbol, so we have replaced plus symbol by '_plus_' and now replace it back
        filters[field] = searchField.replace(PLUS_REPLACEMENT, '+');
        break;
      case isSearchFieldString:
        filters[field] = searchField.replace(PLUS_REPLACEMENT, '+');
        break;
      case isFilterTypeTextWithCheckbox && isSearchFieldArray:
        const valueIndex = (searchField as string[]).findIndex(field => field.indexOf('value') !== -1);
        const checkedIndex = (searchField as string[]).findIndex(field => field.indexOf('checked') !== -1);

        const value = searchField[valueIndex];
        const checked = searchField[checkedIndex];

        filters[field] = {
          value: '',
          checked: '',
        };

        if (typeof value !== 'undefined') {
          filters[field].value = value.split('_')[1];
        }

        if (typeof checked !== 'undefined') {
          filters[field].checked = checked.split('_')[1] === 'true';
        }
        break;
      case isSearchFieldArray:
        const toIndex = (searchField as string[]).findIndex(field => field.indexOf('to') !== -1);
        const fromIndex = (searchField as string[]).findIndex(field => field.indexOf('from') !== -1);

        const to = searchField[toIndex];
        const from = searchField[fromIndex];

        filters[field] = {
          to: '',
          from: '',
        };

        if (typeof to !== 'undefined') {
          filters[field].to = to.split('_')[1];
        }

        if (typeof from !== 'undefined') {
          filters[field].from = from.split('_')[1];
        }
        break;
      case field === 'birthday':
      case field === 'createdAt':
      case field === 'updatedAt':
      case field === 'jobDate':
      case field === 'imageJobDate':
        filters[field] = {
          to: '',
          from: '',
        };
        break;
      case field === 'studentFirstNameWithCheckbox':
      case field === 'studentLastNameWithCheckbox':
      case field === 'firstNameWithCheckbox':
      case field === 'lastNameWithCheckbox':
        filters[field] = {
          value: '',
          checked: false,
        };
        break;
      default:
        filters[field] = '';
    }
  });

  return filters;
}

export function getFrom(total: number, currentPage: number, limit?: number): number {
  return total === 0 ? 0 : (currentPage - 1) * (limit || LIMIT) + 1;
}

export function getTo(total: number, currentPage: number, limit?: number): number {
  return currentPage * LIMIT < total ? currentPage * (limit || LIMIT) : total;
}

export function convertToFilterWithPlus(filters: any) {
  let filtersConverted = {};

  for (let field in filters) {
    if (typeof filters[field] === 'string') {
      //query-parse inserts space instead plus symbol, so not to lose plus symbol we replace it by '_plus_'
      const filterWithPlus = filters[field].replace('+', PLUS_REPLACEMENT);
      filtersConverted = { ...filtersConverted, [field]: filterWithPlus };
    } else {
      filtersConverted = { ...filtersConverted, [field]: filters[field] };
    }
  }
  return filtersConverted;
}

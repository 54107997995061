import * as React from 'react';
import { AllSchool } from 'src/models/allSchools';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { AppUser } from '../../../../../App';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  allSchool?: AllSchool;
  user: AppUser;
}

import { useFormikContext } from 'formik';

export function AllSchoolForm(props: Props) {
  const { allSchool, onSubmit, onCancel, user } = props;
  const AllSchoolSchema = React.useMemo(() => {
    return Yup.object().shape({
      code: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
      leaNumber: Yup.string().required('Required'),
      dfesNumber: Yup.string().required('Required'),
    });
  }, [allSchool?.id, user]);

  const isSchoolExist = typeof allSchool !== 'undefined';
  const allSchoolForm = {
    code: isSchoolExist ? allSchool.code : '',
    name: isSchoolExist ? allSchool.code : '',
    city: isSchoolExist ? allSchool.city : '',
    leaNumber: isSchoolExist ? allSchool.leaNumber : undefined,
    dfesNumber: isSchoolExist ? allSchool.dfesNumber : undefined,
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={allSchoolForm}
            validationSchema={AllSchoolSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>School Reference</label>
                    <Field name="code" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="code" />
                  </div>

                  <div className="form-group">
                    <label>School name</label>
                    <Field name="name" type="string" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="name" />
                  </div>

                  <div className="form-group">
                    <label>Town</label>
                    <Field name="city" type="string" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="city" />
                  </div>

                  <div className="form-group">
                    <label>LEA Number</label>
                    <Field name="leaNumber" type="string" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="leaNumber" />
                  </div>

                  <div className="form-group">
                    <label>DFES Number</label>
                    <Field name="dfesNumber" type="string" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="dfesNumber" />
                  </div>
                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

import * as React from 'react';
import * as BPromise from 'bluebird';
import * as propz from 'propz';
import { loginCustomer } from '../../../helpers/auth';
import { LoginFormOrders } from './LoginForm';
import './LoginForm.scss';
import { History } from 'history';
import StorageHelper from '../../../helpers/storage';
import { getCustomerTickets } from '../../../services/customer/tickets';
import { LIMIT_DOWNLOAD_IMAGES } from '../../../consts/images';
import { AppOneTimeCode } from '../AppOrders';
import { addOneTimeCodeBasketToCustomer } from '../../../services/public/basket';
import { addAccessCode } from '../../../services/customer/customer';
import { getFromHistory } from '../../../helpers/history';
import { setTermsAndConditionsAgreement, setPromoEmailAgreement } from 'Src/services/customer/customer';
import {
  getIsSignFromOneTimeCodeWaitingFromStorage,
  clearIsSignFromOneTimeCodeWaitingInStorage,
} from 'Src/helpers/sessionStorage';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { ConfirmationModal } from 'Src/views/AppOrders/CommonComponents/PhotoSlider/ConfirmationModal/ConfirmationModal';

interface Props {
  history: History;
  onFormSubmit: (tickets, session, redirectCallback?: any, basketId?: string) => void;
  oneTimeCode: AppOneTimeCode;
  isAuthorizedOneTimeCode: boolean;
}

interface State {
  username: string;
  password: string;
  textError: string;
  schoolCode: string;
  isAccessCodeAddedModalOpen: boolean;
  accessCodeAddedModalText: string;
  tickets: any[];
  session: any;
}

export class LoginOrders extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { history } = props;
    const username = getFromHistory(history, 'username') || '';
    const initialSchoolCode = getFromHistory(history, 'schoolCode') || '';

    this.state = {
      username: username,
      password: '',
      textError: '',
      schoolCode: initialSchoolCode,
      isAccessCodeAddedModalOpen: false,
      accessCodeAddedModalText: '',
      tickets: [],
      session: undefined,
    };
  }

  onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ username: value });
  };

  onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ password: value });
  };

  redirectCallback = () => {
    this.props.history.push('/customer/basket');
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { password } = this.state;
    const username = this.state.username.trim();

    let session;
    let basketId;
    let state;
    let isResetPassword;

    const schoolCode = this.state.schoolCode || getFromHistory(this.props.history, 'schoolCode');
    const isSchoolCodeValid = Boolean(schoolCode) && schoolCode !== 'undefined'; // Changed: Ensure schoolCode is valid

    const searchParams = new URLSearchParams(this.props.history.location.search);
    const isParentInvite = searchParams.get('isParentInvite');

    if (isSchoolCodeValid || isParentInvite === 'true') {
      loginCustomer(username, password)
        .then(_session => {
          session = _session;
          const { key, userId } = session;
          const filter = {
            skip: 0,
            limit: LIMIT_DOWNLOAD_IMAGES,
          };
          return getCustomerTickets({ sessionKey: key, customerId: userId, basketId }, filter);
        })
        .then(tickets => {
          // One year cookie. just count of days because js-cookie convert days to msec itself
          StorageHelper.Cookie.set('session', session, { expires: 365 });
          this.props.onFormSubmit(tickets, session);

          // Build redirect URL with only valid query parameters
          let redirectURL = '/customer/profile';
          const queryParams = new URLSearchParams();

          if (isSchoolCodeValid) {
            queryParams.append('schoolCode', schoolCode);
          }

          if (isParentInvite === 'true') {
            queryParams.append('isParentInvite', 'true');
          }

          const queryString = queryParams.toString();
          if (queryString) {
            redirectURL += `?${queryString}`;
          }

          window.location.href = redirectURL;
        })
        .catch(err => {
          this.setState({ textError: 'Incorrect email or password' });

          const newSearchParams = new URLSearchParams();

          if (isSchoolCodeValid) {
            newSearchParams.append('schoolCode', schoolCode);
          }

          if (isParentInvite === 'true') {
            newSearchParams.append('isParentInvite', 'true');
          }

          this.props.history.replace({
            pathname: this.props.history.location.pathname,
            search: newSearchParams.toString(),
          });
        });
    } else {
      let setPromoEmailAgreementPromise;
      let getCustomerTicketsPromise;

      loginCustomer(username, password)
        .then(_session => {
          session = _session;
          const { key, userId } = session;

          state = { ...(this.props.history.location.state as any) };
          const { promoEmailAgreement } = state;
          isResetPassword = typeof promoEmailAgreement !== 'undefined';

          const setTermsAndConditionsAgreementPromise = isResetPassword
            ? setTermsAndConditionsAgreement({
                sessionKey: key,
                customerId: userId,
              })
            : BPromise.resolve(true);

          return setTermsAndConditionsAgreementPromise;
        })
        .then(() => {
          const filter = {
            skip: 0,
            limit: LIMIT_DOWNLOAD_IMAGES,
          };

          const { key, userId } = session;
          const { promoEmailAgreement } = state;

          setPromoEmailAgreementPromise =
            isResetPassword && promoEmailAgreement
              ? setPromoEmailAgreement({ sessionKey: key, customerId: userId }, promoEmailAgreement)
              : BPromise.resolve(true);

          getCustomerTicketsPromise = getCustomerTickets({ sessionKey: key, customerId: userId, basketId }, filter);
          const promises = [setPromoEmailAgreementPromise, getCustomerTicketsPromise];
          return BPromise.all(promises);
        })
        .then(([res, tickets]) => {
          delete state.promoEmailAgreement;
          this.props.history.replace({ ...this.props.history.location, state });

          const { oneTimeCode } = this.props;
          const { username, password, basketId } = oneTimeCode;

          const { userId, key } = session;

          if (basketId) {
            let basketId;
            addOneTimeCodeBasketToCustomer(oneTimeCode, userId)
              .then(basket => {
                basketId = basket.id;
                // One year cookie. just count of days because js-cookie convert days to msec itself
                StorageHelper.Cookie.set('session', session, { expires: 365 });
                return addAccessCode(
                  {
                    customerId: userId,
                    sessionKey: key,
                  },
                  username,
                  password
                );
              })
              .then(res => {
                this.props.onFormSubmit(tickets, session, this.redirectCallback, basketId);
              })
              .catch(err => {
                this.props.onFormSubmit(tickets, session, this.redirectCallback, basketId);
              });
          } else {
            // One year cookie. just count of days because js-cookie convert days to msec itself
            StorageHelper.Cookie.set('session', session, { expires: 365 });

            const isLoginFromOneTimeCodeWaiting = getIsSignFromOneTimeCodeWaitingFromStorage();
            const addAccessCodePromise = isLoginFromOneTimeCodeWaiting
              ? addAccessCode({ customerId: userId, sessionKey: key }, username, password)
              : BPromise.resolve(undefined);

            BPromise.all([addAccessCodePromise])
              .then(() => {
                this.props.onFormSubmit(tickets, session);
                console.log('Redirecting to /customer');

                if (isSchoolCodeValid) {
                  window.location.href = `/customer/profile?schoolCode=${schoolCode}`;
                } else {
                  this.props.history.push('/customer');
                }
              })
              .catch(error => {
                this.setState({
                  isAccessCodeAddedModalOpen: true,
                  accessCodeAddedModalText: propz.get(error, ['details', 'text']),
                  tickets,
                  session,
                });
              });
          }
        })
        .catch(err => {
          this.setState({ textError: 'Incorrect email or password' });
        });
    }
  };

  onSignUpClick = () => {
    this.props.history.push('/registration');
  };

  renderAccessCodeAddedModal(): React.ReactNode {
    const { isAccessCodeAddedModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isAccessCodeAddedModalOpen}>
        <ConfirmationModal
          text={'Access code has been already added to your account.'}
          onOk={this.closeAccessCodeModal}
        />
      </SimpleModal>
    );
  }

  closeAccessCodeModal = () => {
    const { tickets, session } = this.state;

    this.props.onFormSubmit(tickets, session);
    clearIsSignFromOneTimeCodeWaitingInStorage();

    this.setState({
      isAccessCodeAddedModalOpen: false,
    });

    this.props.history.push('/customer');
  };

  render() {
    const { username, password, textError } = this.state;

    return (
      <div>
        {this.renderAccessCodeAddedModal()}
        <LoginFormOrders
          username={username}
          password={password}
          onUsernameChange={this.onUsernameChange}
          onPasswordChange={this.onPasswordChange}
          onFormSubmit={this.onFormSubmit}
          textError={textError}
          onSignUpClick={this.onSignUpClick}
        />
      </div>
    );
  }
}

import axios from 'axios';
import { AppCustomer } from '../../views/AppOrders/AppOrders';

export function addCustomerStudents(customer: AppCustomer, schoolId: string, formId: string, data) {
  const { sessionKey: key } = customer;

  const payload = {
    ...data,
    schoolId,
    formId,
  };
  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/schools/${schoolId}/forms/${formId}/students`, payload, config)
    .then(response => {
      return response.data;
    });
}

export function addManualFormCustomerStudents(customer: AppCustomer, schoolId: string, formName: string, data: any) {
  const { sessionKey: key } = customer;

  const payload = {
    ...data,
    schoolId,
    formName,
  };
  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/schools/${schoolId}/manualFormStudents`, payload, config)
    .then(response => response.data);
}

export function addManualCustomerStudents(customer: AppCustomer, data) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/customer/manualstudents`, data, config).then(response => {
    return response.data;
  });
}

export function checkedCustomerStudent(customer: AppCustomer, schoolId: string, formId: string, data) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios
    .post(`${window.apiBase}/customer/schools/${schoolId}/forms/${formId}/studentCheck`, data, config)
    .then(response => {
      return response.data;
    });
}

export function removeCustomerStudents(customer: AppCustomer, studentId: string) {
  const { sessionKey: key } = customer;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/customer/students/${studentId}`, config).then(response => {
    return response.data;
  });
}

import * as React from 'react';
import './ParentInvite.scss';
import { Link } from 'react-router-dom';
import { History, Location } from 'history';
import { getFromHistory } from '../../../helpers/history';
import { useState } from 'react';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';

interface Props {
  history: History;
  location: Location;
  isAuthorizedCustomer: boolean;
}

export function ParentInvite(props: Props) {
  const { history, isAuthorizedCustomer } = props;

  const schoolCode = getFromHistory(history, 'schoolCode');
  const isSchoolCodeValid = !!schoolCode && schoolCode !== 'undefined';

  const searchParams = new URLSearchParams();
  if (isSchoolCodeValid) {
    searchParams.append('schoolCode', schoolCode as string);
  } else {
    searchParams.append('isParentInvite', 'true');
  }

  let existingCustomerPathname;
  if (isAuthorizedCustomer) {
    existingCustomerPathname = `/customer/profile?${searchParams.toString()}`;
  } else {
    existingCustomerPathname = `/login?${searchParams.toString()}`;
  }

  const [isParentInviteInfoFormModalOpen, setIsParentInviteInfoFormModalOpen] = useState(false);
  const onParentInviteInfoFormModalOpen = (): void => {
    setIsParentInviteInfoFormModalOpen(true);
  };
  const onParentInviteInfoFormModalClose = (): void => {
    setIsParentInviteInfoFormModalOpen(false);
  };

  const renderInfoModal = () => {
    const infoText =
      'We are delighted to bring you our new Quick Pics service.\n' +
      '\n' +
      "Quick Pics will allow us to supply you with a bespoke service ensuring that you never miss your child's school photographs.\n" +
      '\n' +
      'We are able to link any new images to your account during the school academic year simply by you registering your child(s) details.\n' +
      '\n' +
      'We promise we will NEVER pass your information to any third party.';

    return (
      <SimpleModal
        isOpen={isParentInviteInfoFormModalOpen}
        title={''}
        body={infoText}
        buttonCancelText={'Ok'}
        onCloseClick={onParentInviteInfoFormModalClose}
      />
    );
  };

  const classes = isParentInviteInfoFormModalOpen ? 'mt-3 modal-open' : 'mt-3';

  return (
    <div className={classes}>
      {renderInfoModal()}
      <div className="container">
        <div className="row">
          <div className={'col-xl-12'}>
            <div className={'eParentInviteTitle'}>Quick Pics</div>
          </div>
          <div className="mb-4 w-100 text-center">
            <a className="eParentInviteLinkText" onClick={onParentInviteInfoFormModalOpen}>
              What's this?
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <div className={'text-center eParentInviteLink mColorBlue'}>
              <Link to={{ pathname: '/registration', search: searchParams.toString() }}>New customer</Link>
            </div>
          </div>
          <div className="col-xl-6">
            <div className={'text-center eParentInviteLink mColorRed'}>
              <Link to={existingCustomerPathname}>Existing customer</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

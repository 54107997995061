import * as React from 'react';
import { parseDomainName } from './api';
import * as propz from 'propz';
import './cell.css';
import { REASON_FOR_CANCELLATION, REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING } from '../consts/customer';

export function imageCell(item): JSX.Element {
  const { ticket, isHidden, isPreferred, fileName } = item;
  const imageUrl = `${window.apiFile}/storage/images/${ticket.ticket}`;

  const hiddenOverlayStyle: React.CSSProperties = {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 0, 0, 0.3)',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 700,
    zIndex: 2,
    pointerEvents: 'none' as 'none',
  };

  const preferredIconStyle: React.CSSProperties = {
    position: 'absolute' as 'absolute',
    top: '5px',
    right: '5px',
    color: 'gold',
    fontSize: '1.5rem',
    zIndex: 3,
  };

  const imageContainerStyle: React.CSSProperties = {
    position: 'relative' as 'relative',
    display: 'inline-block',
    border: '2px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
    margin: '5px',
    width: '100px',
  };

  const imageStyle: React.CSSProperties = {
    objectFit: 'cover' as 'cover',
  };

  return (
    <td key={item.id}>
      <div style={imageContainerStyle}>
        {isPreferred && (
          <span style={preferredIconStyle} title="Preferred Image">
            ⭐
          </span>
        )}
        <img className="img-fluid img-thumbnail" src={imageUrl} alt={fileName} style={imageStyle} />
        {isHidden && <div style={hiddenOverlayStyle}>HIDDEN</div>}
      </div>
    </td>
  );
}

export function imagePublicCell(item): JSX.Element {
  return (
    <td key={item.id}>
      <div style={{ maxWidth: '200px' }}>
        <img className="img-fluid img-thumbnail" src={item.pic} />
      </div>
    </td>
  );
}

export function jobPrivateLinkCell(item): JSX.Element {
  const privateLink = item.privateLink;

  if (typeof privateLink === 'undefined') {
    return <td key={item.id} />;
  }

  const hostname = document.location.hostname;
  const protocol = document.location.protocol;

  const hostnameParsed = parseDomainName(hostname);
  const { env } = hostnameParsed;

  const port = document.location.port;
  const isDev = env === 'dev';
  const isLocal = env === 'local';

  const hostnameParts = hostname.split('.');

  const [model, ...rest] = hostnameParts;

  const restJoined = rest.join('.');

  const link =
    isDev || isLocal
      ? `${protocol}//orders.${restJoined}:${port}/privateLink/${privateLink}`
      : `${protocol}//orders.${restJoined}/privateLink/${privateLink}`;

  return (
    <td key={item.id}>
      <a href={link} target="_blank">
        Link
      </a>
    </td>
  );
}

export function imagePublicLinkCell(item): JSX.Element {
  const ticket = item.ticket;

  const link = `${window.apiFile}/storage/images/${ticket.ticket}`;

  return (
    <td key={ticket.ticket}>
      <a href={link} target="_blank">
        Link
      </a>
    </td>
  );
}

export function orderEmailsCell(item): JSX.Element {
  const customerEmail = propz.get(item, ['customerEmail'], '');
  const billingEmail = propz.get(item, ['invoice', 'email'], '');
  const deliveryEmail = propz.get(item, ['delivery', 'email'], '');
  const isCustomerEmailExist = customerEmail != '';
  const isBillingEmailExist = billingEmail != '';
  const isDeliveryEmailExist = deliveryEmail != '';

  return (
    <td key={item.id}>
      {isDeliveryEmailExist && (
        <div>
          <a href={`mailto:${deliveryEmail}`}>{deliveryEmail}</a>
        </div>
      )}
      {isCustomerEmailExist && (
        <div>
          <a href={`mailto:${customerEmail}`}>{customerEmail}</a>
        </div>
      )}
      {isBillingEmailExist && (
        <div>
          <a href={`mailto:${billingEmail}`}>{billingEmail}</a>
        </div>
      )}
    </td>
  );
}

export function orderPostcodesCell(item): JSX.Element {
  const billingPostcode = propz.get(item, ['invoice', 'postcode'], '');
  const deliveryPostcode = propz.get(item, ['delivery', 'postcode'], '');
  const isBillingPostcodeExist = billingPostcode != '';
  const isDeliveryPostcodeExist = deliveryPostcode != '';

  return (
    <td key={`postcode_${item.id}`}>
      {isDeliveryPostcodeExist && <div>{deliveryPostcode}</div>}
      {isBillingPostcodeExist && <div>{billingPostcode}</div>}
    </td>
  );
}

export function customerEmailCell(item): JSX.Element {
  const customerEmail = propz.get(item, ['email'], '');
  const isCustomerEmailExist = customerEmail != '';

  return (
    <td key={item.id}>
      {isCustomerEmailExist && (
        <div>
          <a href={`mailto:${customerEmail}`}>{customerEmail}</a>
        </div>
      )}
    </td>
  );
}

export function getReasonsForCancellation(item): JSX.Element {
  const students = item.students;
  const reasonsForCancellation = Array.isArray(students) ? (
    students.map((student, index) => {
      const reasonForCancellation = propz.get(student, ['reasonForCancellation']);
      const isExistReasonForCancellation = typeof reasonForCancellation !== 'undefined';
      const isAutoAcceptUnavailable = reasonForCancellation === REASON_FOR_CANCELLATION.AUTO_ACCEPT_UNAVAILABLE;

      return isExistReasonForCancellation ? (
        <div key={`reason_${index}`} className={isAutoAcceptUnavailable ? 'mReasonAutoAcceptUnavailable mt-1' : 'mt-1'}>
          {REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING[reasonForCancellation]}
        </div>
      ) : (
        <div />
      );
    })
  ) : (
    <div />
  );
  return <div>{reasonsForCancellation}</div>;
}

import axios from 'axios';
import * as BPromise from 'bluebird';
import { AppUser } from '../../views/App/App';
import { ALL_LIMIT } from '../../consts/table';
import { STUDENT_REQUEST_STATUS } from '../../consts/customer';

export function getCustomerStudents(user: AppUser, customerId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/admin/customers/${customerId}/students`, config).then(response => {
    return response.data;
  });
}

export function getAllCustomerStudents(user: AppUser, customerId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/admin/customers/${customerId}/students`, config).then(response => {
    return response.data;
  });
}

export function getCustomerStudentsCount(user: AppUser, customerId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/admin/customers/${customerId}/students/count`, config).then(response => {
    return response.data;
  });
}

export function addCustomerStudent(user: AppUser, customerId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/customers/${customerId}/students`, data, config).then(response => {
    return response.data;
  });
}

export function updateCustomerStudent(user: AppUser, customerId: string, studentId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .put(`${window.apiBase}/admin/customers/${customerId}/students/${studentId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function deleteCustomerStudent(user: AppUser, customerId: string, studentId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(`${window.apiBase}/admin/customers/${customerId}/students/${studentId}`, config)
    .then(response => {
      return response.data;
    });
}

export function deleteCustomerStudentNoNotification(user: AppUser, customerId: string, studentId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .delete(`${window.apiBase}/admin/customers/${customerId}/students/${studentId}/silentDelete`, config)
    .then(response => {
      return response.data;
    });
}

export function approvedAllStudentsStatusNoMatch(user: AppUser) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/admin/customers/students/autoMatch`, {}, config).then(response => {
    return response.data;
  });
}

export function updateRequestStatusCustomerStudent(
  user: AppUser,
  customerId: string,
  studentId: string,
  status: string,
  data?
) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  switch (status) {
    case STUDENT_REQUEST_STATUS.NEW:
      return axios
        .post(`${window.apiBase}/admin/customers/${customerId}/students/${studentId}/requestStatusNew`, {}, config)
        .then(response => {
          return response.data;
        });
    case STUDENT_REQUEST_STATUS.POSTPONED:
      return axios
        .post(
          `${window.apiBase}/admin/customers/${customerId}/students/${studentId}/requestStatusPostponed`,
          data,
          config
        )
        .then(response => {
          return response.data;
        });
    case STUDENT_REQUEST_STATUS.NO_MATCH:
      return axios
        .post(
          `${window.apiBase}/admin/customers/${customerId}/students/${studentId}/requestStatusNoMatch`,
          data,
          config
        )
        .then(response => {
          return response.data;
        });
    default:
      console.error('Student request status not defined');
      return BPromise.resolve();
  }
}

export function updateCustomerStudentSchool(user: AppUser, customerId: string, studentRequestId, selectedSchoolId) {
  const { sessionKey: key } = user;
  const config = {
    headers: { usid: key },
  };

  return axios
    .put(`${window.apiBase}/customers/manualstudents/${studentRequestId}`, { selectedSchoolId }, config)
    .then(response => response.data)
    .catch(error => {
      throw error;
    });
}

import { AppUser } from '../views/App/App';
import * as propz from 'propz';
import { getProducts } from '../services/superadmin/products';
import { getSchools } from '../services/superadmin/schools';
import { getAllSchools } from '../services/superadmin/allSchools';
import { getPackage, getPackages } from '../services/superadmin/packages';
import { getPriceSets } from '../services/superadmin/priceSets';
import { getJobs } from '../services/superadmin/jobs';
import { getCustomers } from '../services/superadmin/customers';
import { getSchoolForms } from '../services/superadmin/schoolForms';
import { AppCustomer } from '../views/AppOrders/AppOrders';
import { getCustomerSchoolForms } from '../services/customer/schoolForms';
import { getStudents } from '../services/superadmin/students';
import { Customer } from '../models/customers';
import { Student } from '../models/students';
import { getOrderSchools } from '../services/superadmin/orders';
import { getImages } from '../services/superadmin/images';

export function getName(item): string {
  return item.name;
}

export function getEmail(item): string {
  return item.email;
}

export function getFormName(item): string {
  return typeof item.name !== 'undefined' ? item.name : propz.get(item, ['form', 'name'], '');
}

export function getFirstNameAndLastName(item): string {
  const firstName = propz.get(item, ['firstName'], '');
  const lastName = propz.get(item, ['lastName'], '');
  return `${firstName} ${lastName}`;
}

export function getForenameAndSurname(item): string {
  const forename = propz.get(item, ['forename'], '');
  const surname = propz.get(item, ['surname'], '');
  const formName = propz.get(item, ['form', 'formName'], '');
  const isFormNameExist = formName !== '';
  if (!forename && !surname) {
    return ``;
  } else {
    return isFormNameExist ? `${forename} ${surname} (${formName})` : `${forename} ${surname}`;
  }
}

export function getSchoolNameAndCode(item): string {
  const schoolName = propz.get(item, ['name'], '');
  const code = propz.get(item, ['code'], '');
  if (code !== '') {
    return `${code} (${schoolName})`;
  } else {
    return schoolName;
  }
}

export function getJobReference(item): string {
  //server field - jobReference, frontend field - name
  return item.jobReference || item.name;
}

export function searchFunctionProducts(user: AppUser, text: string, products?) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  if (Array.isArray(products) && products.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$nin'],
      products.map(product => product.id)
    );
  }

  return getProducts(user, filter);
}

export function searchFunctionProductOffers(user: AppUser, text: string, products?) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  if (Array.isArray(products) && products.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$nin'],
      products.map(product => product.productId)
    );
  }

  return getProducts(user, filter);
}

export function searchFunctionSchools(user: AppUser, text: string, schools?) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  if (Array.isArray(schools) && schools.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$nin'],
      schools.map(school => school.schoolId)
    );
  }

  return getSchools(user, filter);
}

export function searchFunctionSchoolsByCode(user: AppUser, text: string, schools?) {
  const filter = {
    where: {
      code: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  if (Array.isArray(schools) && schools.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$nin'],
      schools.map(school => school.schoolId)
    );
  }

  return getSchools(user, filter);
}

export function searchFunctionOrderSchoolsByCode(user: AppUser, text: string, orderId: string) {
  const filter = {
    where: {
      code: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  return getOrderSchools(user, orderId, filter);
}

export function searchFunctionPackages(user: AppUser, text: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  return getPackages(user, filter);
}

export function searchFunctionPriceSets(user: AppUser, packageId: string, text: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  return getPriceSets(user, packageId, filter);
}

export function searchFunctionJobs(user: AppUser, text: string, jobs?) {
  const filter = {
    where: {
      jobReference: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  if (Array.isArray(jobs) && jobs.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$nin'],
      jobs.map(job => job.jobReferenceId)
    );
  }

  return getJobs(user, filter);
}

export function searchFunctionCustomers(user: AppUser, text: string, customers?) {
  const filter = {
    where: {
      $or: [
        {
          firstName: {
            like: text || '',
            options: 'i',
          },
        },
        {
          lastName: {
            like: text || '',
            options: 'i',
          },
        },
      ],
    },
    limit: 10,
    skip: 0,
  };

  if (Array.isArray(customers) && customers.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$nin'],
      customers.map(customer => customer.customerId)
    );
  }

  return getCustomers(user, filter);
}

export function searchFunctionCustomerEmails(user: AppUser, text: string) {
  const filter = {
    where: {
      email: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  return getCustomers(user, filter);
}

export function searchFunctionForms(user: AppUser, schoolId: string, text: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  return getSchoolForms(user, schoolId, filter);
}

export function searchFunctionCustomerSchoolForms(customer: AppCustomer, schoolId: string, text: string) {
  const filter = {
    where: {
      name: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  return getCustomerSchoolForms(customer, schoolId, filter);
}

export function searchFunctionStudents(
  user: AppUser,
  text: string,
  allCustomerStudents: Student[],
  schoolId: string,
  formId: string,
  currentStudentsFromForm: any[]
) {
  const customerStudentIdsFilterByFormId = allCustomerStudents
    .filter(student => formId === propz.get(student, ['form', 'formId'], ''))
    .map(formStudent => propz.get(formStudent, ['student', 'studentId'], ''));
  const studentIdsFromForm = currentStudentsFromForm
    .filter(currentStudent => typeof currentStudent.studentId !== undefined)
    .filter(currentStudent => currentStudent.studentId !== '')
    .map(currentStudent => currentStudent.studentId);
  const filter = {
    where: {
      'form.formId': formId,
      id: {
        $nin: [...customerStudentIdsFilterByFormId, ...studentIdsFromForm],
      },
      $or: [
        {
          forename: {
            like: text || '',
            options: 'i',
          },
        },
        {
          surname: {
            like: text || '',
            options: 'i',
          },
        },
      ],
    },
    limit: 10,
    skip: 0,
  };

  return getStudents(user, schoolId, filter);
}

export function searchFunctionSchoolStudent(
  user: AppUser,
  schoolId: string,
  allCustomerStudents: Student[],
  currentStudentsFromForm: any[],
  text: string
) {
  const linkedStudentIds = allCustomerStudents
    .filter(student => {
      const studentId = propz.get(student, ['student', 'studentId']);
      return typeof studentId !== 'undefined';
    })
    .map(linkedStudent => propz.get(linkedStudent, ['student', 'studentId']));
  const studentIdsFromForm = currentStudentsFromForm
    .filter(currentStudent => typeof currentStudent.studentId !== undefined)
    .filter(currentStudent => currentStudent.studentId !== '')
    .map(currentStudent => currentStudent.studentId);
  const filter = {
    where: {
      id: {
        $nin: [...linkedStudentIds, ...studentIdsFromForm],
      },
      $or: [
        {
          forename: {
            like: text || '',
            options: 'i',
          },
        },
        {
          surname: {
            like: text || '',
            options: 'i',
          },
        },
      ],
    },
    limit: 10,
    skip: 0,
    order: 'surname ASC',
  };

  return getStudents(user, schoolId, filter);
}

export function searchFunctionStudentsBySchoolAndForm(
  user: AppUser,
  schoolId: string,
  formId: string,
  text: string,
  customer: Customer
) {
  let filter = {
    where: {
      'form.formId': formId,
      $or: [
        {
          forename: {
            like: text || '',
            options: 'i',
          },
        },
        {
          surname: {
            like: text || '',
            options: 'i',
          },
        },
      ],
    },
    limit: 10,
    skip: 0,
  };

  const students = customer.students || [];
  if (Array.isArray(students) && students.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$nin'],
      students
        .map(student => propz.get(student, ['student', 'studentId']))
        .filter(studentId => typeof studentId !== 'undefined')
    );
  }

  return getStudents(user, schoolId, filter);
}

//TO DO Replace by new server method
export function searchFunctionBulkImageId(user: AppUser, text: string, images?) {
  const filter = {
    where: {
      bulkImageId: {
        like: text || '',
        options: 'i',
      },
    },
    limit: 10,
    skip: 0,
  };

  if (Array.isArray(images) && images.length > 0) {
    propz.set(
      filter,
      ['where', 'id', '$in'],
      images.map(image => image.id)
    );
  }

  return getImages(user, filter);
}

export function getBulkImageId(item): string {
  return item.bulkImageId;
}

import * as React from 'react';
import * as propz from 'propz';
import { Voucher } from 'Src/models/vouchers';
import * as Yup from 'yup';
import {
  VOUCHER_DISCOUNT_TYPE,
  VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING,
  VOUCHER_PURCHASE_SOURCE,
  VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING,
  VOUCHER_SCOPE,
  VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING,
} from 'Src/consts/voucher';
import { Button } from 'Src/components/Button/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import {
  getFirstNameAndLastName,
  getJobReference,
  getName,
  searchFunctionCustomers,
  searchFunctionJobs,
  searchFunctionProducts,
  searchFunctionSchools,
} from '../../../../../../../helpers/autocomplete';
import { Autocomplete } from '../../../../../../../components/Autocomplete/Autocomplete';
import { AppUser } from '../../../../../App';
import { CURRENCY_SYMBOL } from '../../../../../../../consts/common';
import { PRICE, PRICE_SERVER_TO_CLIENT_MAPPING } from '../../../../../../../consts/offer';
import { getCurrencyMask } from '../../../../../../../helpers/currencyMask';
import { checkAvailability } from '../../../../../../../services/public/register';
import { Switch } from '../../../../../../../components/Switch/Switch';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  voucher?: Voucher;
  user: AppUser;
}

interface State {
  discountType: string;
  scopeType: string;
  priceType: string;
  schools: any[];
  customers: any[];
  jobs: any[];
}

export class VoucherForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { voucher } = props;
    const isVoucherExist = typeof voucher !== 'undefined';

    this.state = {
      discountType: isVoucherExist ? voucher.discount.discountType : VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT,
      scopeType: isVoucherExist ? voucher.scope.scopeType : VOUCHER_SCOPE.WHOLE_PLATFORM,
      priceType: isVoucherExist ? voucher.discount.priceType : PRICE.AMOUNT,
      schools: isVoucherExist ? voucher.scope.schools : [],
      customers: isVoucherExist ? voucher.scope.customers : [],
      jobs: isVoucherExist ? voucher.scope.jobs : [],
    };
  }

  getProducts = (text: string) => {
    const { user } = this.props;
    return searchFunctionProducts(user, text);
  };

  getSchools = (text: string) => {
    const { user } = this.props;
    const { schools } = this.state;
    return searchFunctionSchools(user, text, schools);
  };

  getCustomers = (text: string) => {
    const { user } = this.props;
    const { customers } = this.state;
    return searchFunctionCustomers(user, text, customers);
  };

  getJobs = (text: string) => {
    const { user } = this.props;
    const { jobs } = this.state;
    return searchFunctionJobs(user, text, jobs);
  };

  renderSchools(schools, setFieldValue) {
    return schools.map(school => {
      return (
        <div key={school.schoolId} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getName}
            defaultItem={school}
            disabled
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const schoolsFiltered = schools.filter(s => s.schoolId !== school.schoolId);
                setFieldValue('scope.schools', schoolsFiltered);
                this.setState({
                  schools: schoolsFiltered,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  renderCustomers(customers, setFieldValue) {
    return customers.map(customer => {
      return (
        <div key={customer.customerId} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getFirstNameAndLastName}
            defaultItem={customer}
            disabled
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const customerFiltered = customers.filter(c => c.customerId !== customer.customerId);
                setFieldValue('scope.customers', customerFiltered);
                this.setState({
                  customers: customerFiltered,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  renderJobs(jobs, setFieldValue) {
    return jobs.map(job => {
      return (
        <div key={job.jobReferenceId} className={'mb-3'}>
          <Autocomplete
            onSelect={() => {}}
            searchFunction={() => {}}
            getElementTitle={getName}
            defaultItem={job}
            disabled
          />
          <div className="eClearButton ml-3">
            <button
              type="button"
              className="close"
              onClick={() => {
                const josFiltered = jobs.filter(j => j.jobReferenceId !== job.jobReferenceId);
                setFieldValue('scope.jobs', josFiltered);
                this.setState({
                  jobs: josFiltered,
                });
              }}
            >
              <span>&times;</span>
            </button>
          </div>
        </div>
      );
    });
  }

  render() {
    const { onCancel, onSubmit, voucher } = this.props;
    const { discountType, scopeType, priceType } = this.state;

    const isVoucherExist = typeof voucher !== 'undefined';

    const now = new Date();
    const weekAhead = new Date();
    weekAhead.setDate(weekAhead.getDate() + 7);

    const voucherForm = {
      code: isVoucherExist ? voucher.code : '',
      description: isVoucherExist ? voucher.description : '',
      startDate: isVoucherExist ? new Date(voucher.startDate) : now,
      endDate: isVoucherExist ? new Date(voucher.endDate) : weekAhead,
      isFreePostage: isVoucherExist ? voucher.isFreePostage : false,
      isOneOffVoucher: isVoucherExist ? voucher.isOneOffVoucher : false,
      discount: {
        discountType: isVoucherExist ? voucher.discount.discountType : VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT,
        discountAmount: isVoucherExist ? voucher.discount.discountAmount : '',
        minOrderAmount: isVoucherExist ? voucher.discount.minOrderAmount : '',
        priceType: isVoucherExist ? voucher.discount.priceType : PRICE.AMOUNT,
        product: {
          productId: isVoucherExist ? propz.get(voucher, ['discount', 'product', 'productId'], '') : '',
          name: isVoucherExist ? propz.get(voucher, ['discount', 'product', 'name'], '') : '',
        },
        isMultipleProductsVoucher: isVoucherExist ? voucher.discount.isMultipleProductsVoucher : false,
      },
      scope: {
        scopeType: isVoucherExist ? voucher.scope.scopeType : VOUCHER_SCOPE.WHOLE_PLATFORM,
        purchaseSource: isVoucherExist ? voucher.scope.purchaseSource : VOUCHER_PURCHASE_SOURCE.ALL_SOURCES,
        jobs: isVoucherExist ? voucher.scope.jobs : [],
        schools: isVoucherExist ? voucher.scope.schools : [],
        customers: isVoucherExist ? voucher.scope.customers : [],
      },
    };

    const isDiscountTypeProductDiscount = discountType === VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT;
    const isDiscountTypeOrderDiscount = discountType === VOUCHER_DISCOUNT_TYPE.ORDER_DISCOUNT;
    const isDiscountTypeReducedPostage = discountType === VOUCHER_DISCOUNT_TYPE.REDUCED_POSTAGE;
    const isDiscountTypeFreePostage = discountType === VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE;

    const isScopeWholePlatform = scopeType === VOUCHER_SCOPE.WHOLE_PLATFORM;
    const isScopeSchool = scopeType === VOUCHER_SCOPE.SCHOOL;
    const isScopeListOfCustomers = scopeType === VOUCHER_SCOPE.LIST_OF_CUSTOMERS;
    const isScopeJob = scopeType === VOUCHER_SCOPE.JOB;

    const isPriceTypeAmount = priceType === PRICE.AMOUNT;
    const isPriceTypePercent = priceType === PRICE.PERCENT;

    let VoucherSchema;

    switch (true) {
      //scope whole platform
      case isDiscountTypeProductDiscount && isScopeWholePlatform:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            product: Yup.object().shape({
              productId: Yup.string().required('Required'),
              name: Yup.string().required('Required'),
            }),
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
        });
        break;
      case isDiscountTypeOrderDiscount && isScopeWholePlatform:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
        });
        break;
      case isDiscountTypeReducedPostage && isScopeWholePlatform:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
        });
        break;
      case isDiscountTypeFreePostage && isScopeWholePlatform:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
          }),
        });
        break;

      //scope list of customers
      case isDiscountTypeProductDiscount && isScopeListOfCustomers:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            product: Yup.object().shape({
              productId: Yup.string().required('Required'),
              name: Yup.string().required('Required'),
            }),
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
          }),
        });
        break;
      case isDiscountTypeOrderDiscount && isScopeListOfCustomers:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
          }),
        });
        break;
      case isDiscountTypeReducedPostage && isScopeListOfCustomers:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
          }),
        });
        break;
      case isDiscountTypeFreePostage && isScopeListOfCustomers:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
          }),
          scope: Yup.object().shape({
            customers: Yup.array().min(1, 'Should contain minimum 1 customer'),
          }),
        });
        break;

      //scope list of schools
      case isDiscountTypeProductDiscount && isScopeSchool:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            product: Yup.object().shape({
              productId: Yup.string().required('Required'),
              name: Yup.string().required('Required'),
            }),
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            schools: Yup.array().min(1, 'Should contain minimum 1 school'),
          }),
        });
        break;
      case isDiscountTypeOrderDiscount && isScopeSchool:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
            scope: Yup.object().shape({
              schools: Yup.array().min(1, 'Should contain minimum 1 school'),
            }),
          }),
        });
        break;
      case isDiscountTypeReducedPostage && isScopeSchool:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            schools: Yup.array().min(1, 'Should contain minimum 1 school'),
          }),
        });
        break;
      case isDiscountTypeFreePostage && isScopeSchool:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
          }),
          scope: Yup.object().shape({
            schools: Yup.array().min(1, 'Should contain minimum 1 school'),
          }),
        });
        break;

      //scope list of jobs
      case isDiscountTypeProductDiscount && isScopeJob:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            product: Yup.object().shape({
              productId: Yup.string().required('Required'),
              name: Yup.string().required('Required'),
            }),
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
            isMultipleProductsVoucher: Yup.boolean(),
          }),
          scope: Yup.object().shape({
            jobs: Yup.array().min(1, 'Should contain minimum 1 job'),
          }),
        });
        break;
      case isDiscountTypeOrderDiscount && isScopeJob:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            jobs: Yup.array().min(1, 'Should contain minimum 1 job'),
          }),
        });
        break;
      case isDiscountTypeReducedPostage && isScopeJob:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
            discountAmount: isPriceTypeAmount
              ? Yup.string()
                  .required('Required')
                  .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number')
                  .test('discountAmount', 'Must be less or equal than min order amount', function(value) {
                    return Number(value) <= Number(this.parent.minOrderAmount);
                  })
              : Yup.string()
                  .required('Required')
                  .matches(/(^\d{1,2}|100)$/, 'Should contain number from 0 to 100'),
          }),
          scope: Yup.object().shape({
            jobs: Yup.array().min(1, 'Should contain minimum 1 job'),
          }),
        });
        break;
      case isDiscountTypeFreePostage && isScopeJob:
        VoucherSchema = Yup.object().shape({
          code: Yup.string().required('Required'),
          startDate: Yup.date().max(Yup.ref('endDate'), 'Must be less then end'),
          endDate: Yup.date().min(Yup.ref('startDate'), 'Must be more then start'),
          discount: Yup.object().shape({
            minOrderAmount: Yup.string()
              .required('Required')
              .matches(/^\d{0,100}(.)?\d{1,2}$/, 'Should contain only number'),
          }),
          scope: Yup.object().shape({
            jobs: Yup.array().min(1, 'Should contain minimum 1 job'),
          }),
        });
        break;
    }

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <Formik initialValues={voucherForm} validationSchema={VoucherSchema} onSubmit={onSubmit}>
              {({ handleSubmit, setFieldValue, values }) => (
                <Form>
                  <div className="form-group form-check">
                    <div className="form-group">
                      <label>Code</label>
                      <Field name="code" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="code" />
                    </div>

                    <div className="form-group">
                      <label>Description</label>
                      <Field name="description" className="form-control mb-3" />
                      <ErrorMessage component="div" className="alert alert-danger" name="description" />
                    </div>

                    <div className="form-group">
                      <label>Start date</label>
                      <Field name="startDate">
                        {({ field }) => (
                          <div className="mb-3">
                            <DatePicker
                              selected={field.value}
                              onChange={startDate => {
                                setFieldValue('startDate', startDate);
                              }}
                              className="form-control"
                              dateFormat={'dd-MM-yyyy'}
                              customInput={
                                <MaskedInput
                                  mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                  placeholder="DD-MM-YYYY"
                                />
                              }
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="startDate" />
                    </div>

                    <div className="form-group">
                      <label>End date</label>
                      <Field name="endDate">
                        {({ field }) => (
                          <div className="mb-3">
                            <DatePicker
                              selected={field.value}
                              onChange={endDate => {
                                setFieldValue('endDate', endDate);
                              }}
                              className="form-control"
                              dateFormat={'dd-MM-yyyy'}
                              customInput={
                                <MaskedInput
                                  mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                  placeholder="DD-MM-YYYY"
                                />
                              }
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name="endDate" />
                    </div>

                    <div className="form-group">
                      <label>Discount type</label>
                      <Field
                        name="discount.discountType"
                        component="select"
                        className="form-control mb-3"
                        onChange={event => {
                          const discountType = event.target.value;

                          if (discountType === VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE) {
                            setFieldValue('discount.discountAmount', '');
                            setFieldValue('isFreePostage', false);
                          }

                          if (discountType === VOUCHER_DISCOUNT_TYPE.REDUCED_POSTAGE) {
                            setFieldValue('isFreePostage', false);
                          }

                          setFieldValue('discount.discountType', discountType);
                          setFieldValue('discount.product.productId', '');
                          this.setState({ discountType });
                        }}
                      >
                        <option value={VOUCHER_DISCOUNT_TYPE.PRODUCT_DISCOUNT}>
                          {VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING.PRODUCT_DISCOUNT}
                        </option>
                        <option value={VOUCHER_DISCOUNT_TYPE.ORDER_DISCOUNT}>
                          {VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING.ORDER_DISCOUNT}
                        </option>
                        <option value={VOUCHER_DISCOUNT_TYPE.REDUCED_POSTAGE}>
                          {VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING.REDUCED_POSTAGE}
                        </option>
                        <option value={VOUCHER_DISCOUNT_TYPE.FREE_POSTAGE}>
                          {VOUCHER_DISCOUNT_TYPE_SERVER_TO_CLIENT_MAPPING.FREE_POSTAGE}
                        </option>
                      </Field>
                    </div>
                    {isDiscountTypeProductDiscount && (
                      <div className="form-group">
                        <Field name="discount.isMultipleProductsVoucher">
                          {({ field }) => <Switch {...field} text={'Apply to multiple products'} customClass="mb-3" />}
                        </Field>
                      </div>
                    )}
                    {(isDiscountTypeProductDiscount || isDiscountTypeOrderDiscount) && (
                      <Field name="isFreePostage">
                        {({ field }) => <Switch {...field} text={'Free postage'} customClass="mb-3" />}
                      </Field>
                    )}

                    {isDiscountTypeProductDiscount && (
                      <>
                        <div>Product</div>
                        <Field name="discount.product.productId">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getProducts}
                              getElementTitle={getName}
                              customClass="mFullWidth mb-3"
                              defaultItem={values.discount.product}
                              onSelect={product => {
                                setFieldValue('discount.product.productId', product.id);
                                setFieldValue('discount.product.name', product.name);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          component="div"
                          className="alert alert-danger"
                          name={`discount.product.productId`}
                        />
                      </>
                    )}

                    {(isDiscountTypeProductDiscount || isDiscountTypeOrderDiscount || isDiscountTypeReducedPostage) && (
                      <div className="form-group">
                        <label>Discount</label>
                        <Field
                          name="discount.priceType"
                          component="select"
                          className="form-control mb-3"
                          onChange={event => {
                            const priceType = event.target.value;
                            setFieldValue('discount.priceType', priceType);
                            setFieldValue('discount.discountAmount', 0);
                            this.setState({ priceType });
                          }}
                        >
                          <option value={PRICE.AMOUNT}>{PRICE_SERVER_TO_CLIENT_MAPPING.AMOUNT}</option>
                          <option value={PRICE.PERCENT}>{PRICE_SERVER_TO_CLIENT_MAPPING.PERCENT}</option>
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name="discount.priceType" />
                      </div>
                    )}

                    {(isDiscountTypeProductDiscount || isDiscountTypeOrderDiscount || isDiscountTypeReducedPostage) &&
                      isPriceTypeAmount && (
                        <div className="form-group">
                          <label>Discount amount</label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                            </div>
                            <Field name="discount.discountAmount">
                              {({ field }) => (
                                <MaskedInput
                                  type="text"
                                  className="form-control"
                                  mask={getCurrencyMask}
                                  onChange={event => {
                                    setFieldValue(`discount.discountAmount`, event.target.value);
                                  }}
                                  value={values.discount.discountAmount}
                                />
                              )}
                            </Field>
                          </div>
                          <ErrorMessage
                            component="div"
                            className="alert alert-danger mt-3"
                            name="discount.discountAmount"
                          />
                        </div>
                      )}

                    {(isDiscountTypeProductDiscount || isDiscountTypeOrderDiscount || isDiscountTypeReducedPostage) &&
                      isPriceTypePercent && (
                        <div className="form-group">
                          <label>Discount percent</label>
                          <div className="input-group">
                            <Field name="discount.discountAmount" className="form-control" />
                            <div className="input-group-append">
                              <div className="input-group-text">%</div>
                            </div>
                          </div>
                          <ErrorMessage
                            component="div"
                            className="alert alert-danger mt-3"
                            name="discount.discountAmount"
                          />
                        </div>
                      )}

                    <div className="form-group">
                      <label>Min order amount</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">{CURRENCY_SYMBOL.POUND}</div>
                        </div>
                        <Field name="discount.minOrderAmount">
                          {({ field }) => (
                            <MaskedInput
                              type="text"
                              className="form-control"
                              mask={getCurrencyMask}
                              onChange={event => {
                                setFieldValue(`discount.minOrderAmount`, event.target.value);
                              }}
                              value={values.discount.minOrderAmount}
                            />
                          )}
                        </Field>
                      </div>
                      <ErrorMessage
                        component="div"
                        className="alert alert-danger mt-3"
                        name="discount.minOrderAmount"
                      />
                    </div>

                    <div className="form-group">
                      <label>Purchase source</label>
                      <Field
                        name="scope.purchaseSource"
                        component="select"
                        className="form-control mb-3"
                        onChange={event => {
                          const purchaseSource = event.target.value;
                          setFieldValue('scope.purchaseSource', purchaseSource);
                        }}
                      >
                        <option value={VOUCHER_PURCHASE_SOURCE.ALL_SOURCES}>
                          {VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING.ALL_SOURCES}
                        </option>
                        <option value={VOUCHER_PURCHASE_SOURCE.WEB_PURCHASE}>
                          {VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING.WEB_PURCHASE}
                        </option>
                        <option value={VOUCHER_PURCHASE_SOURCE.MOBILE_PURCHASE}>
                          {VOUCHER_PURCHASE_SOURCE_SERVER_TO_CLIENT_MAPPING.MOBILE_PURCHASE}
                        </option>
                      </Field>
                    </div>

                    <Field name="isOneOffVoucher">
                      {({ field }) => <Switch {...field} text={'One off voucher'} customClass="mb-3" />}
                    </Field>

                    <div className="form-group">
                      <label>Voucher scope</label>
                      <Field
                        name="scope.scopeType"
                        component="select"
                        className="form-control mb-3"
                        onChange={event => {
                          const scopeType = event.target.value;

                          if (scopeType !== VOUCHER_SCOPE.SCHOOL) {
                            setFieldValue('scope.schools', []);
                          }

                          if (scopeType !== VOUCHER_SCOPE.JOB) {
                            setFieldValue('scope.jobs', []);
                          }

                          if (scopeType !== VOUCHER_SCOPE.LIST_OF_CUSTOMERS) {
                            setFieldValue('scope.customers', []);
                          }

                          setFieldValue('scope.scopeType', scopeType);
                          this.setState({ scopeType });
                        }}
                      >
                        <option value={VOUCHER_SCOPE.WHOLE_PLATFORM}>
                          {VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING.WHOLE_PLATFORM}
                        </option>
                        <option value={VOUCHER_SCOPE.SCHOOL}>{VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING.SCHOOL}</option>
                        <option value={VOUCHER_SCOPE.LIST_OF_CUSTOMERS}>
                          {VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING.LIST_OF_CUSTOMERS}
                        </option>
                        <option value={VOUCHER_SCOPE.JOB}>{VOUCHER_SCOPE_SERVER_TO_CLIENT_MAPPING.JOB}</option>
                      </Field>
                    </div>

                    {isScopeListOfCustomers && (
                      <>
                        <div>Customers</div>
                        {this.renderCustomers(values.scope.customers, setFieldValue)}
                        <Field name="scope.customers">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getCustomers}
                              getElementTitle={getFirstNameAndLastName}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={customer => {
                                const nextCustomers = [].concat(values.scope.customers, {
                                  customerId: customer.id,
                                  firstName: customer.firstName,
                                  lastName: customer.lastName,
                                });

                                setFieldValue('scope.customers', nextCustomers);
                                this.setState({
                                  customers: nextCustomers,
                                });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name={`scope.customers`} />
                      </>
                    )}

                    {isScopeSchool && (
                      <>
                        <div>Schools</div>
                        {this.renderSchools(values.scope.schools, setFieldValue)}
                        <Field name="scope.schools">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getSchools}
                              getElementTitle={getName}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={school => {
                                const nextSchools = [].concat(values.scope.schools, {
                                  schoolId: school.id,
                                  name: school.name,
                                });

                                setFieldValue('scope.schools', nextSchools);
                                this.setState({
                                  schools: nextSchools,
                                });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name={`scope.schools`} />
                      </>
                    )}

                    {isScopeJob && (
                      <>
                        <div>Jobs</div>
                        {this.renderJobs(values.scope.jobs, setFieldValue)}
                        <Field name="scope.jobs">
                          {() => (
                            <Autocomplete
                              searchFunction={this.getJobs}
                              getElementTitle={getJobReference}
                              customClass="mFullWidth mb-3"
                              defaultItem={undefined}
                              onSelect={job => {
                                const nextJobs = [].concat(values.scope.jobs, {
                                  jobReferenceId: job.id,
                                  name: job.jobReference,
                                });

                                setFieldValue('scope.jobs', nextJobs);
                                this.setState({
                                  jobs: nextJobs,
                                });
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage component="div" className="alert alert-danger" name={`scope.jobs`} />
                      </>
                    )}

                    <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                    <Button
                      onClick={handleSubmit}
                      text={'Save'}
                      customClass={'mt-3 mb-3 btn btn-primary'}
                      type={'submit'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import { Component } from 'react';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../../consts/common';
import { Button } from '../../../components/Button/Button';
import { verifyEmail, verifyPhone } from '../../../services/customer/confirm';
import { checkCustomerSession } from '../../../services/customer/customer';
import { AppCustomer } from '../AppOrders';
import { History, Location } from 'history';
import { Loader } from '../../../components/Loader/Loader';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import { EmailChangeForm } from './EmailChangeForm/EmailChangeForm';
import { PhoneChangeForm } from './PhoneChangeForm/PhoneChangeForm';
import {
  changeEmail,
  changePhone,
  resendEmailCode,
  resendPhoneCode,
  getCustomerProfile,
} from '../../../services/customer/customer';
import { getPhoneWithoutFirstZero } from '../../../helpers/phone';

interface Props {
  customer: AppCustomer;
  history: History;
  location: Location;
  isRegistrationFromOneTimeCode: boolean;
  updateIsCustomerVerified: () => void;
  onLogoutClick: () => void;
}

interface State {
  emailToken: string;
  phoneToken: string;
  parentCode: string;
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  isEmailChangeModalOpen: boolean;
  isPhoneChangeModalOpen: boolean;
  isSuccessModalOpen: boolean;
  isLoading: boolean;
}

export class Verification extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      emailToken: '',
      phoneToken: '',
      isPhoneVerified: undefined,
      isEmailVerified: undefined,
      isEmailChangeModalOpen: false,
      isPhoneChangeModalOpen: false,
      isSuccessModalOpen: false,
      isLoading: false,
      parentCode: '',
    };
  }

  componentDidMount() {
    const { customer, onLogoutClick } = this.props;

    const { sessionKey } = customer;
    checkCustomerSession(sessionKey).then(status => {
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        onLogoutClick();
        this.props.history.push('/login');
      }
    });

    this.setState({
      isLoading: true,
    });

    getCustomerProfile(customer).then(profile => {
      const { verification, parentCode } = profile;
      const { status } = verification;
      const { sms, email } = status;
      switch (true) {
        case sms && email:
          this.setState({
            isPhoneVerified: sms,
            isEmailVerified: email,
            isLoading: false,
            parentCode: parentCode,
          });
          break;
        case sms:
          this.setState({
            isPhoneVerified: sms,
            isLoading: false,
            parentCode: parentCode,
          });
          break;
        case email:
          this.setState({
            isEmailVerified: email,
            isLoading: false,
            parentCode: parentCode,
          });
          break;
        default:
          this.setState({
            isLoading: false,
            parentCode: parentCode,
          });
      }
    });
  }

  onEmailTokenChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ emailToken: value });
  };

  onPhoneTokenChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ phoneToken: value });
  };

  onVerifyEmailClick = () => {
    const { customer } = this.props;
    const { emailToken } = this.state;

    verifyEmail(customer, emailToken).then(res => {
      const { confirmed } = res;
      this.setState({
        isEmailVerified: confirmed,
      });
    });
  };

  onVerifyPhoneClick = () => {
    const { customer } = this.props;
    const { phoneToken } = this.state;

    verifyPhone(customer, phoneToken).then(res => {
      const { confirmed } = res;
      this.setState({
        isPhoneVerified: confirmed,
      });
    });
  };

  onStartClick = () => {
    const { history, isRegistrationFromOneTimeCode, customer, updateIsCustomerVerified } = this.props;
    const state = { ...(history.location.state as any) };

    const searchParams = new URLSearchParams(history.location.search);
    const schoolCode = searchParams.get('schoolCode') || state.schoolCode;
    const isParentInvite = searchParams.get('isParentInvite');

    delete state.phone;
    delete state.email;
    history.replace({ ...history.location, state });

    const isParentCodeExist = typeof schoolCode !== 'undefined' && schoolCode !== '';
    const isBasketExist = typeof customer.basketId !== 'undefined' && customer.basketId !== '';

    const queryParams = new URLSearchParams();
    if (isParentCodeExist) {
      queryParams.append('schoolCode', schoolCode);
    }
    if (isParentInvite === 'true') {
      queryParams.append('isParentInvite', 'true');
    }

    if (isParentCodeExist || isParentInvite === 'true') {
      updateIsCustomerVerified();
      history.push({
        pathname: '/customer/profile',
        search: queryParams.toString(),
      });
    } else if (isRegistrationFromOneTimeCode && isBasketExist) {
      history.push('/customer/basket');
    } else {
      history.push('/customer');
    }
  };

  onChangeEmailClick = () => {
    this.setState({
      isEmailChangeModalOpen: true,
    });
  };

  onChangePhoneClick = () => {
    this.setState({
      isPhoneChangeModalOpen: true,
    });
  };

  onPhoneChangeFormCancelClick = () => {
    this.setState({
      isPhoneChangeModalOpen: false,
    });
  };

  onEmailChangeFormCancelClick = () => {
    this.setState({
      isEmailChangeModalOpen: false,
    });
  };

  onResendEmailCodeClick = () => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    resendEmailCode(customer).then(res => {
      this.setState({
        isLoading: false,
        isSuccessModalOpen: true,
      });
    });
  };

  onResendPhoneCodeClick = () => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    resendPhoneCode(customer).then(res => {
      this.setState({
        isLoading: false,
        isSuccessModalOpen: true,
      });
    });
  };

  onEmailChangeFormSubmit = data => {
    const { customer } = this.props;
    this.setState({
      isLoading: true,
    });

    changeEmail(customer, data.email).then(res => {
      this.setState({
        isLoading: false,
        isEmailChangeModalOpen: false,
        isSuccessModalOpen: true,
      });
      const { history } = this.props;
      const state = { ...(history.location.state as any) };
      state.email = data.email;
      history.replace({ ...history.location, state });
    });
  };

  onPhoneChangeFormSubmit = data => {
    const { customer } = this.props;
    this.setState({
      isLoading: true,
    });

    const { phone, phoneCode } = data;
    const phoneWithoutFirstZero = getPhoneWithoutFirstZero(phone);

    const phoneToPost = `${phoneCode}${phoneWithoutFirstZero}`;

    changePhone(customer, phoneToPost).then(res => {
      this.setState({
        isLoading: false,
        isPhoneChangeModalOpen: false,
        isSuccessModalOpen: true,
      });
      const { history } = this.props;
      const state = { ...(history.location.state as any) };
      state.phone = phoneWithoutFirstZero;
      state.code = data.phoneCode;
      history.replace({ ...history.location, state });
    });
  };

  renderEmailChangeModal() {
    const { isEmailChangeModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isEmailChangeModalOpen}>
        <EmailChangeForm
          onCancel={this.onEmailChangeFormCancelClick}
          onSubmit={this.onEmailChangeFormSubmit}
          {...this.props}
        />
      </SimpleModal>
    );
  }

  renderPhoneChangeModal() {
    const { isPhoneChangeModalOpen } = this.state;
    return (
      <SimpleModal isOpen={isPhoneChangeModalOpen}>
        <PhoneChangeForm
          onCancel={this.onPhoneChangeFormCancelClick}
          onSubmit={this.onPhoneChangeFormSubmit}
          {...this.props}
        />
      </SimpleModal>
    );
  }

  onOkSuccessModalClick = () => {
    this.setState({
      isSuccessModalOpen: false,
    });
  };

  renderSuccessModal() {
    const { isSuccessModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isSuccessModalOpen}
        title={'Info'}
        body={'Success'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onOkSuccessModalClick}
      />
    );
  }

  render() {
    const {
      emailToken,
      phoneToken,
      isEmailVerified,
      isPhoneVerified,
      isLoading,
      isEmailChangeModalOpen,
      isPhoneChangeModalOpen,
      isSuccessModalOpen,
    } = this.state;

    const { history } = this.props;
    const state = { ...(history.location.state as any) };

    const { email, phone, code } = state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isEmailChangeModalOpen || isPhoneChangeModalOpen || isSuccessModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderEmailChangeModal()}
        {this.renderPhoneChangeModal()}
        {this.renderSuccessModal()}
        <div className="container">
          <div className="row">
            <div className="col-xl-6 offset-xl-3">
              <h1 className={'mt-3 mb-3'}>Verification form</h1>
              <div className={'mt-3 mb-3'}>
                <label>Email verification</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Enter code here"
                  value={emailToken}
                  onChange={this.onEmailTokenChange}
                  disabled={isEmailVerified}
                />
                <p>We have sent your verification code to {email}</p>
                {isEmailVerified === false && <div className="alert alert-danger">Bad code</div>}
                {isEmailVerified && <div className="alert alert-success">Verified</div>}
                <Button disabled={isEmailVerified} text={'Verify'} onClick={this.onVerifyEmailClick} />
                <Button
                  disabled={isEmailVerified}
                  text={'Change email'}
                  onClick={this.onChangeEmailClick}
                  customClass={'btn-light ml-3'}
                />
                <Button
                  disabled={isEmailVerified}
                  text={'Resend email code'}
                  onClick={this.onResendEmailCodeClick}
                  customClass={'btn-light ml-3'}
                />
              </div>
              <div className={'mt-3 mb-3'}>
                <label>Phone verification</label>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Enter code here"
                  value={phoneToken}
                  onChange={this.onPhoneTokenChange}
                  disabled={isPhoneVerified}
                />
                <p>
                  We have sent your verification code to {code} {phone}
                </p>
                {isPhoneVerified === false && <div className="alert alert-danger">Bad code</div>}
                {isPhoneVerified && <div className="alert alert-success">Verified</div>}
                <Button disabled={isPhoneVerified} text={'Verify'} onClick={this.onVerifyPhoneClick} />
                <Button
                  disabled={isPhoneVerified}
                  text={'Change phone'}
                  onClick={this.onChangePhoneClick}
                  customClass={'btn-light ml-3'}
                />
                <Button
                  disabled={isPhoneVerified}
                  text={'Resend phone code'}
                  onClick={this.onResendPhoneCodeClick}
                  customClass={'btn-light ml-3'}
                />
              </div>

              {isEmailVerified && isPhoneVerified && <Button text={'Start'} onClick={this.onStartClick} />}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

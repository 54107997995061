import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import { Component } from 'react';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
} from 'Src/helpers/table';
import { CHECKBOX_FIELDS, DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { History, Location } from 'history';
import { SimpleModal } from '../../../../../../../components/SimpleModal/SimpleModal';
import { Job } from '../../../../../../../models/jobs';
import { getJob } from '../../../../../../../services/superadmin/jobs';
import {
  deleteJobImage,
  getAllJobImages,
  getJobImages,
  getJobImagesCount,
  updateJobImage,
} from '../../../../../../../services/superadmin/jobImages';
import { deleteImages } from '../../../../../../../services/superadmin/images';
import { JobImageForm } from './JobImageForm/JobImageForm';
import { JobImagePriceSetForm } from './JobImagePriceSetForm/JobImagePriceSetForm';
import { ImageWithTicket } from '../../../../../../../models/images';
import { ResizablePanel } from '../../../../../../../components/ResizablePanel/ResizablePanel';
import { JOB_IMAGES_TABS } from '../../../../../../../consts/job';
import { TABS } from '../../../../../../../consts/common';
import { GenericSummary } from '../../../../../../../components/GenericSummary/GenericSummary';
import { JobImageSummary } from './JobImageSummary/JobImageSummary';
import { createTicket } from '../../../../../../../services/superadmin/image';
import { imageCell } from '../../../../../../../helpers/cell';
import { convertToFilterWithPlus } from '../../../../../../../helpers/table';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Image',
    field: 'image',
    isSort: false,
    type: FILTER_TYPE.NONE,
    cell: imageCell,
  },
  {
    text: 'Username',
    field: 'username',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['username'],
  },
  {
    text: 'Password',
    field: 'password',
    isSort: true,
    type: FILTER_TYPE.TEXT,
    accessor: ['password'],
  },
  {
    text: 'Package name',
    field: 'packageName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['package', 'name'],
  },
  {
    text: 'Price set name',
    field: 'priceSetName',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['priceSet', 'name'],
  },
  {
    text: 'Student class',
    field: 'studentClass',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['student', 'class'],
  },
  {
    text: 'Student first name',
    field: 'studentFirstNameWithCheckbox',
    isSort: false,
    type: FILTER_TYPE.TEXT_WITH_CHECKBOX,
    accessor: ['student', 'firstName'],
  },
  {
    text: 'Student last name',
    field: 'studentLastNameWithCheckbox',
    isSort: false,
    type: FILTER_TYPE.TEXT_WITH_CHECKBOX,
    accessor: ['student', 'lastName'],
  },
  {
    text: 'Barcode number',
    field: 'bulkImageId',
    isSort: false,
    type: FILTER_TYPE.TEXT,
    accessor: ['bulkImageId'],
  },
];

interface State {
  items: ImageWithTicket[];
  currentPage: number;
  selectedItems: ImageWithTicket[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  job: Job;
  isConfirmationModalOpen: boolean;
  isJobImageFormModalOpen: boolean;
  isReplacePriceSetFormModalOpen: boolean;
  isReplacePriceSetConfirmationModalOpen: boolean;
  packageId: string;
  priceSetId: string;
  packageName: string;
  priceSetName: string;
  currentTabIndex: number;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class JobImages extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      job: undefined,
      isConfirmationModalOpen: false,
      isJobImageFormModalOpen: false,
      isReplacePriceSetFormModalOpen: false,
      isReplacePriceSetConfirmationModalOpen: false,
      packageId: '',
      priceSetId: '',
      packageName: '',
      priceSetName: '',
      currentTabIndex: 0,
    };
  }

  getJobId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const jobId = search.job as string;
    return jobId;
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  setAdditionalItems() {
    const { user } = this.props;
    const jobId = this.getJobId();

    return getJob(user, jobId).then(job => {
      this.setState({
        job: job,
      });

      return true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const jobId = this.getJobId();

    const getItemsPromise = getJobImages(user, jobId, serverQueryFilter);
    const getItemsCountPromise = getJobImagesCount(user, jobId, where);

    const promises = [getItemsCountPromise, getItemsPromise];
    let countObj, items;
    return Promise.all(promises)
      .then(([_countObj, _items]) => {
        countObj = _countObj;
        items = _items;

        return Promise.all(
          items.map(item => {
            const data = {
              key: item.key,
              mimeType: item.mimeType,
            };

            return createTicket(user, data);
          })
        );
      })
      .then(tickets => {
        const imagesExtended = items.map((item, index) => ({
          ...item,
          ticket: tickets[index],
        }));

        const { selectedItems } = this.state;
        let updatedSelectedItem;
        if (selectedItems.length === 1) {
          updatedSelectedItem = imagesExtended.find(image => image.id === selectedItems[0].id);
          this.setState({
            currentPage: page,
            sortDirection: sortDirection as TABLE_SORT_DIRECTION,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: imagesExtended,
            selectedItems: [updatedSelectedItem],
            total: countObj.count,
            basePath: history.location.pathname,
            isLoading: false,
          });
        } else {
          this.setState({
            currentPage: page,
            sortDirection: sortDirection as TABLE_SORT_DIRECTION,
            sortColumnsName: sortColumnsName,
            isShowFilter: isShowFilter,
            isDataFiltered: isDataFiltered,
            filters: filters,
            items: imagesExtended,
            total: countObj.count,
            basePath: history.location.pathname,
            isLoading: false,
          });
        }
        return true;
      });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const jobId = this.getJobId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `job=${jobId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const jobId = this.getJobId();

    getAllJobImages(user, jobId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const jobId = this.getJobId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `job=${jobId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
        case CHECKBOX_FIELDS.CHECKBOX: {
          const checked = event.target.checked;
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              value: '',
              checked: checked,
            },
          };
          break;
        }
        case CHECKBOX_FIELDS.TEXT:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              value: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const jobId = this.getJobId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `job=${jobId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const jobId = this.getJobId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `job=${jobId}`,
      state: history.location.state,
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search } = state as any;
    const jobId = this.getJobId();

    history.push({
      pathname: '/jobs',
      search: search,
      state: { jobId: jobId },
    });
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        body={`Are you sure you want to remove ${selectedItems.length === 1 ? 'image' : 'images'}?`}
        buttonText={'Remove'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onDeleteImagesClick}
        onCloseClick={this.hideConfirmationModal}
      />
    );
  }

  onDeleteImagesClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    const selectedItemIds = selectedItems.map(item => item.id);

    // Chunk ids array to avoid over request size
    const chunkSize = 1000;
    const selectedItemIdsChunked = [];

    for (let i = 0; i < selectedItemIds.length; i += chunkSize) {
      selectedItemIdsChunked.push(selectedItemIds.slice(i, i + chunkSize));
    }

    const promises = [];

    selectedItemIdsChunked.forEach(selectedItemIds => {
      promises.push(deleteImages(user, selectedItemIds));
    });

    Promise.all(promises).then(res => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false,
      });

      this.setItems();
    });
  };

  onEditImageClick = () => {
    this.setState({
      isJobImageFormModalOpen: true,
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isJobImageFormModalOpen: false,
    });
  };

  onReplacePriceSetClick = () => {
    this.setState({
      isReplacePriceSetFormModalOpen: true,
    });
  };

  onCancelReplacePriceSetButtonClick = () => {
    this.setState({
      isReplacePriceSetFormModalOpen: false,
    });
  };

  onSubmitForm = data => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    const jobId = this.getJobId();

    this.setState({
      isLoading: true,
    });

    const image = selectedItems[0];
    const { id } = image;

    let imageExtended;

    updateJobImage(user, jobId, id, data)
      .then(image => {
        imageExtended = image;
        return createTicket(user, image);
      })
      .then(ticket => {
        imageExtended = { ...imageExtended, ticket };
        this.setState({
          isJobImageFormModalOpen: false,
          selectedItems: [imageExtended],
          isSelectAllChecked: false,
        });
        this.setItems();
      });
  };

  renderFormModal(): React.ReactNode {
    const { isJobImageFormModalOpen, selectedItems } = this.state;

    const image = selectedItems[0];
    return (
      <SimpleModal isOpen={isJobImageFormModalOpen}>
        <JobImageForm image={image} onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitForm} />
      </SimpleModal>
    );
  }

  onSubmitReplacePriceSetForm = data => {
    this.setState({
      packageId: data.packageItem.id,
      priceSetId: data.priceSet.id,
      packageName: data.packageItem.name,
      priceSetName: data.priceSet.name,
      isReplacePriceSetFormModalOpen: false,
      isReplacePriceSetConfirmationModalOpen: true,
    });
  };

  onCancelReplacePriceSetConfirmationButtonClick = () => {
    this.setState({
      isReplacePriceSetConfirmationModalOpen: false,
    });
  };

  onSubmitReplacePriceSetConfirmation = () => {
    const { selectedItems, packageId, priceSetId, packageName, priceSetName } = this.state;
    const { user } = this.props;

    const data = {
      package: { packageId, name: packageName },
      priceSet: { priceSetId, name: priceSetName },
    };

    const jobId = this.getJobId();

    this.setState({
      isLoading: true,
    });

    Promise.all(selectedItems.map(selectedItem => updateJobImage(user, jobId, selectedItem.id, data))).then(res => {
      this.setState({
        isReplacePriceSetConfirmationModalOpen: false,
        isSelectAllChecked: false,
      });

      this.setItems();
    });
  };

  renderReplacePriceSetConfirmationFormModal(): React.ReactNode {
    const { isReplacePriceSetConfirmationModalOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isReplacePriceSetConfirmationModalOpen}
        title={'Confirmation'}
        body={`Package and Price set will be updated for ${selectedItems.length} images. Are you sure?`}
        buttonText={'Update'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onSubmitReplacePriceSetConfirmation}
        onCloseClick={this.onCancelReplacePriceSetConfirmationButtonClick}
      />
    );
  }

  renderReplacePriceSetFormModal(): React.ReactNode {
    const { isReplacePriceSetFormModalOpen } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isReplacePriceSetFormModalOpen}>
        <JobImagePriceSetForm
          onSubmit={this.onSubmitReplacePriceSetForm}
          onCancel={this.onCancelReplacePriceSetButtonClick}
          user={user}
        />
      </SimpleModal>
    );
  }

  renderGrid(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      job,
    } = this.state;

    const actionItems = [
      {
        itemText: 'Edit image',
        onItemClick: this.onEditImageClick,
        isActive: selectedItems.length === 1,
      },
      {
        itemText: 'Replace package/price set',
        onItemClick: this.onReplacePriceSetClick,
        isActive: selectedItems.length > 0,
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete image' : 'Delete images',
        onItemClick: this.showConfirmationModal,
        isActive: selectedItems.length > 0,
      },
    ];

    const filterOptions = {};

    const gridTitle = typeof job !== 'undefined' ? `Job ${job.jobReference} / Images` : '';

    return (
      <Grid
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
      />
    );
  }

  getTabs(): React.ReactNode {
    const { currentTabIndex, selectedItems } = this.state;

    const tabs = JOB_IMAGES_TABS;
    const currentTab = tabs[currentTabIndex];
    const [image] = selectedItems;

    switch (currentTab) {
      case TABS.SUMMARY:
        return <JobImageSummary image={image} />;
      default:
        return <div />;
    }
  }

  onTabClick = (event, index: number) => {
    event.preventDefault();

    this.setState({
      currentTabIndex: index,
    });
  };

  renderSummary(): React.ReactNode {
    const { selectedItems, currentTabIndex } = this.state;
    const isOneItemSelected = selectedItems.length === 1;

    if (!isOneItemSelected) {
      return <div className="font-weight-bold">Select an image above</div>;
    }

    const tabs = this.getTabs();

    return (
      <GenericSummary onTabClick={this.onTabClick} tabs={JOB_IMAGES_TABS} currentTabIndex={currentTabIndex}>
        {tabs}
      </GenericSummary>
    );
  }

  render() {
    const {
      isLoading,
      isConfirmationModalOpen,
      isJobImageFormModalOpen,
      isReplacePriceSetFormModalOpen,
      isReplacePriceSetConfirmationModalOpen,
    } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes =
      isConfirmationModalOpen ||
      isJobImageFormModalOpen ||
      isReplacePriceSetFormModalOpen ||
      isReplacePriceSetConfirmationModalOpen
        ? 'mt-3 modal-open'
        : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderConfirmationModal()}
        {this.renderFormModal()}
        {this.renderReplacePriceSetFormModal()}
        {this.renderReplacePriceSetConfirmationFormModal()}
        <div className="row">
          <div className="col-md-12">
            <ResizablePanel>
              {this.renderGrid()}
              {this.renderSummary()}
            </ResizablePanel>
          </div>
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { Profile } from '../../../../models/profile';
import * as moment from 'moment';
import {
  checkCustomerSession,
  getCustomerProfile,
  updateCustomerProfile,
  deleteCustomerProfile,
} from '../../../../services/customer/customer';
import { AppCustomer } from '../../AppOrders';
import { Loader } from '../../../../components/Loader/Loader';
import { GenericSummary } from '../../../../components/GenericSummary/GenericSummary';
import { PROFILE_CUSTOMER_TABS, PROFILE_TABS } from '../../../../consts/profile';
import { NOT_AUTHORIZED_STATUS_CODE } from '../../../../consts/common';
import { PAY360_RESULT } from '../../../../consts/payment';
import { PAYMENT_CHANNEL, BUYER_TYPE } from '../../../../consts/payment';
import { DEFAULT_COUNTRY_OPTION_VALUE, ORDER_DELIVERY_TYPE } from 'Src/consts/order';
import { CustomerMyDetailsForm } from './Tabs/CustomerMyDetailsForm';
import { CustomerAddressBookTab } from './Tabs/CustomerAddressBookTab';
import { CustomerOrderHistoryTab } from './Tabs/CustomerOrderHistoryTab';
import { CustomerSubscriptionsTab } from './Tabs/CustomerSubscriptionsTab';
import { getPhoneWithoutFirstZero } from '../../../../helpers/phone';
import {
  setPrimaryCustomerBillingAddress,
  setPrimaryCustomerDeliveryAddress,
  updateCustomerAddress,
} from '../../../../services/customer/addressBook';
import { CustomerAddressForm } from './Tabs/CustomerAddressForm';
import { CustomerMyDetailsTab } from './Tabs/CustomerMyDetailsTab';
import { Order } from '../../../../models/orders';
import { getAllCustomerOrders } from '../../../../services/customer/orders';
import { getCustomerOrderImages } from '../../../../services/customer/orderImages';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { CustomerProfileOrderInformation } from './Modals/CustomerProfileOrderInformation';
import { OrderImage } from '../../../../models/orderImages';
import { getCallback, getCustomerCallbackC, getCustomerCallbackY } from '../../../../helpers/worldpay';
import { getConfig } from '../../../../services/public/config';
import { setPromoEmailAgreement } from '../../../../services/customer/customer';
import { PublicConfig } from '../../../../models/config';
import { CustomerStudentsTab } from './Tabs/CustomerStudentsTab';
import {
  addCustomerStudents,
  checkedCustomerStudent,
  removeCustomerStudents,
  addManualFormCustomerStudents,
  addManualCustomerStudents,
} from '../../../../services/customer/students';
import { CustomerAddStudentForm } from './Modals/CustomerAddStudentForm';
import { getFromHistory } from '../../../../helpers/history';
import { History, Location } from 'history';
import { NOTIFICATION_CHANNELS_TYPE } from '../../../../consts/notificationChannels';
import * as BPromise from 'bluebird';
import {
  createCustomerProfileNotificationChannel,
  deleteCustomerProfileNotificationChannel,
} from '../../../../services/superadmin/customers';
import { CustomerChangePasswordForm } from './Tabs/CustomerChangePasswordForm';
import { CustomerVerificationTab } from './Tabs/CustomerVerificationTab/CustomerVerificationTab';
import './CustomerProfile.scss';
import { CustomerConfirmStudentBirthdayForm } from './Modals/CustomerConfirmStudentBirthdayForm';
import { CustomerConfirmDetailsForm } from './Modals/CustomerConfirmDetailsForm';
import { payment360 } from '../../../../services/public/payment360';
import {
  getIsSignFromOneTimeCodeWaitingFromStorage,
  clearIsSignFromOneTimeCodeWaitingInStorage,
} from 'Src/helpers/sessionStorage';
import { ConfirmationModal } from 'Src/views/AppOrders/CommonComponents/PhotoSlider/ConfirmationModal/ConfirmationModal';

interface Props {
  customer: AppCustomer;
  onLogoutClick: () => void;
  history: History;
  location: Location;
  currentTab: string;
  isCustomerVerified: boolean;
  updateIsCustomerVerified: () => void;
}
interface State {
  profile: Profile;
  isLoading: boolean;
  currentTabIndex: number;
  isEditAddressMode: boolean;
  isEditMyDetailsMode: boolean;
  isEditChangePassword: boolean;
  isConfirmationDeleteProfileModalOpen: boolean;
  addressId: string;
  orderId: string;
  orders: Order[];
  orderImages: OrderImage[];
  isOrderModalOpen: boolean;
  isRenderHiddenForm: boolean;
  config: PublicConfig;
  customerStudent: any;
  isConfirmationDeleteStudentModalOpen: boolean;
  isAddStudentMode: boolean;
  studentId: string;
  isPasswordChangeModalOpen: boolean;
  isExplanationModalOpen: boolean;
  isConfirmedStatusText: boolean;
  existStudentPhotoCount: number;
  isStudentPhotoExistModalOpen: boolean;
  isNoAllowDuplicateStudentModalOpen: boolean;
  duplicateStudentText: string;
  isConfirmStudentBirthdayModalOpen: boolean;
  isConfirmDetailsModalOpen: boolean;

  iFrameSrc: string | undefined;
  sessionId: string | undefined;
  isPaymentUnavailable: boolean;
  isAccessCodeAddedModalOpen: boolean;
}

export class CustomerProfile extends Component<Props, State> {
  formRef: any;
  iframeRef: any;

  constructor(props) {
    super(props);
    const isSignFromOneTimeCodeWaiting = getIsSignFromOneTimeCodeWaitingFromStorage();

    this.state = {
      profile: undefined,
      isLoading: true,
      currentTabIndex: 0,
      isEditAddressMode: false,
      isEditMyDetailsMode: false,
      isEditChangePassword: false,
      isConfirmationDeleteProfileModalOpen: false,
      addressId: '',
      orderId: '',
      orders: [],
      orderImages: [],
      isOrderModalOpen: false,
      isRenderHiddenForm: false,
      config: undefined,
      customerStudent: undefined,
      isConfirmationDeleteStudentModalOpen: false,
      isAddStudentMode: false,
      studentId: '',
      isPasswordChangeModalOpen: false,
      isExplanationModalOpen: false,
      isConfirmedStatusText: false,
      existStudentPhotoCount: 0,
      isStudentPhotoExistModalOpen: false,
      isNoAllowDuplicateStudentModalOpen: false,
      duplicateStudentText: '',
      isConfirmStudentBirthdayModalOpen: false,
      isConfirmDetailsModalOpen: false,

      iFrameSrc: undefined,
      sessionId: undefined,
      isPaymentUnavailable: false,
      isAccessCodeAddedModalOpen: isSignFromOneTimeCodeWaiting,
    };

    this.formRef = React.createRef();
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    const { customer, onLogoutClick, history, currentTab } = this.props;
    const { sessionKey } = customer;

    window.addEventListener('message', this.payment);

    const searchParams = new URLSearchParams(history.location.search);
    const isParentInvite = searchParams.get('isParentInvite') === 'true';
    const schoolCode = searchParams.get('schoolCode') || '';
    const isExistSchoolCode = typeof schoolCode !== 'undefined' && schoolCode !== '';
    const isCurrentTabExist = typeof currentTab !== 'undefined' && currentTab !== '';

    checkCustomerSession(sessionKey).then(status => {
      if (status === NOT_AUTHORIZED_STATUS_CODE) {
        onLogoutClick();
      }
    });

    this.setState({
      isLoading: true,
    });

    const currentTabIndex = isCurrentTabExist
      ? PROFILE_TABS.indexOf(currentTab)
      : PROFILE_TABS.indexOf(PROFILE_CUSTOMER_TABS.STUDENTS);

    getCustomerProfile(customer).then(profile => {
      if (isExistSchoolCode || isParentInvite) {
        this.setState({
          isLoading: false,
          profile,
          currentTabIndex,
          isAddStudentMode: true,
        });
      } else {
        this.setState({
          isLoading: false,
          currentTabIndex,
          isAddStudentMode: false,
          profile,
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.payment);
  }

  payment = event => {
    const { data } = event;

    const isDataPaid = data === PAY360_RESULT.PAID;
    const isDataCanceled = data === PAY360_RESULT.CANCELED;
    const isDataTimeout = data === PAY360_RESULT.TIMEOUT;
    if (isDataPaid || isDataCanceled || isDataTimeout) {
      this.props.history.push(`/customer?status=${data}`);
    }
  };

  updatePhoneInProfile = phone => {
    const { profile } = this.state;

    const profileUpdated = { ...profile, phone };

    this.setState({
      profile: profileUpdated,
    });
  };

  updateEmailInProfile = email => {
    const { profile } = this.state;

    const profileUpdated = { ...profile, email };

    this.setState({
      profile: profileUpdated,
    });
  };

  onMyDetailsFormCancel = () => {
    this.setState({
      isEditMyDetailsMode: false,
    });
  };

  onChangePasswordFormCancel = () => {
    this.setState({
      isEditChangePassword: false,
    });
  };

  onMyDetailsFormSubmit = (data): void => {
    const { email, phone, phoneCode, firstName, lastName, promoEmail, isExistEmailChannels, isExistSmsChannels } = data;

    const { profile } = this.state;
    const { customer } = this.props;
    const {
      email: emailProfile,
      phone: phoneProfile,
      firstName: firstNameProfile,
      lastName: lastNameProfile,
      agreement,
    } = profile;
    const { promoEmail: promoEmailProfile } = agreement;

    const phoneWithoutFirstZero = getPhoneWithoutFirstZero(phone);
    const phoneDataWithoutFirstZero = `${phoneCode}${phoneWithoutFirstZero}`;
    const phoneData = `${phoneCode}${phone}`;
    const isEmailChange = email !== emailProfile;
    const isPhoneChange = phoneData !== phoneProfile;
    const isFirstNameChange = firstName !== firstNameProfile;
    const isLastNameChange = lastName !== lastNameProfile;
    const isPromoEmailChange = promoEmail !== promoEmailProfile;

    const isProfileChange = isEmailChange || isPhoneChange || isFirstNameChange || isLastNameChange;

    const emailChannels = profile.notificationChannels.filter(
      channel => channel.type === NOTIFICATION_CHANNELS_TYPE.EMAIL
    );
    const isExistCurrentEmailChannels = emailChannels.length > 0;
    const isEmailChannelChange = isExistCurrentEmailChannels !== isExistEmailChannels;

    const smsChannels = profile.notificationChannels.filter(channel => channel.type === NOTIFICATION_CHANNELS_TYPE.SMS);
    const isExistCurrentSmsChannels = smsChannels.length > 0;
    const isSmsChannelChange = isExistCurrentSmsChannels !== isExistSmsChannels;

    if (isEmailChannelChange) {
      this.setState({
        isLoading: true,
      });
      if (isExistCurrentEmailChannels) {
        BPromise.all(
          emailChannels.map(channel => {
            return deleteCustomerProfileNotificationChannel(customer, channel.id);
          })
        ).then(res => {
          getCustomerProfile(customer).then(profile => {
            this.setState({
              isLoading: false,
              profile,
              isEditMyDetailsMode: false,
            });
          });
        });
      } else {
        const data = {
          type: NOTIFICATION_CHANNELS_TYPE.EMAIL,
        };
        createCustomerProfileNotificationChannel(customer, data).then(res => {
          getCustomerProfile(customer).then(profile => {
            this.setState({
              isLoading: false,
              profile,
              isEditMyDetailsMode: false,
            });
          });
        });
      }
    }

    if (isSmsChannelChange) {
      this.setState({
        isLoading: true,
      });
      if (isExistCurrentSmsChannels) {
        BPromise.all(
          smsChannels.map(channel => {
            return deleteCustomerProfileNotificationChannel(customer, channel.id);
          })
        ).then(res => {
          getCustomerProfile(customer).then(profile => {
            this.setState({
              isLoading: false,
              profile,
              isEditMyDetailsMode: false,
            });
          });
        });
      } else {
        const data = {
          type: NOTIFICATION_CHANNELS_TYPE.SMS,
        };
        createCustomerProfileNotificationChannel(customer, data).then(res => {
          getCustomerProfile(customer).then(profile => {
            this.setState({
              isLoading: false,
              profile,
              isEditMyDetailsMode: false,
            });
          });
        });
      }
    }

    if (isProfileChange) {
      const dataToTransfer = {
        firstName,
        lastName,
        email,
        phone: phoneDataWithoutFirstZero,
      };

      this.setState({
        isLoading: true,
      });

      updateCustomerProfile(customer, dataToTransfer).then(profileUpdated => {
        this.setState({
          isLoading: false,
          profile: profileUpdated,
          isEditMyDetailsMode: false,
        });
      });
    }

    if (isPromoEmailChange) {
      this.setState({
        isLoading: true,
      });

      setPromoEmailAgreement(customer, promoEmail).then(profileUpdated => {
        this.setState({
          isLoading: false,
          profile: profileUpdated,
          isEditMyDetailsMode: false,
        });
      });
    }
  };

  onChangePasswordFormSubmit = (data): void => {
    const { password } = data;

    const { profile } = this.state;
    const { customer } = this.props;
    const isPasswordEmpty = password === '';

    if (!isPasswordEmpty) {
      const dataToTransfer = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phone: propz.get(profile, ['phone'], ''),
        password,
      };
      this.setState({
        isLoading: true,
      });
      updateCustomerProfile(customer, dataToTransfer).then(profileUpdated => {
        this.setState({
          isLoading: false,
          profile: profileUpdated,
          isPasswordChangeModalOpen: true,
        });
      });
    }
  };

  onDeleteProfileClick = () => {
    this.setState({ isConfirmationDeleteProfileModalOpen: true });
  };

  onCancelDeleteProfileClick = () => {
    this.setState({
      isConfirmationDeleteProfileModalOpen: false,
    });
  };

  onEditAddress = (addressId: string): void => {
    this.setState({
      addressId,
      isEditAddressMode: true,
    });
  };

  onEditMyDetails = (): void => {
    this.setState({
      isEditMyDetailsMode: true,
    });
  };

  onEditChangePassword = (): void => {
    this.setState({
      isEditChangePassword: true,
    });
  };

  onAddressFormSubmit = (data): void => {
    const { customer } = this.props;
    const { addressId } = this.state;
    this.setState({
      isLoading: true,
    });

    updateCustomerAddress(customer, addressId, data).then(profile => {
      this.setState({
        isEditAddressMode: false,
        isLoading: false,
        profile,
      });
    });
  };

  onAddressFormCancel = (): void => {
    this.setState({
      isEditAddressMode: false,
    });
  };

  onViewOrderClick = (orderId: string): void => {
    const { orders } = this.state;
    const { customer } = this.props;
    const order = orders.find(orderState => orderState.id === orderId);

    if (typeof order === 'undefined') {
      console.error(`Can not find order with id: ${orderId}`);
    }

    this.setState({
      isLoading: true,
    });

    getCustomerOrderImages(customer, orderId).then(orderImages => {
      this.setState({
        isLoading: false,
        isOrderModalOpen: true,
        orderId,
        orderImages,
      });
    });
  };

  onPayOrderClick = (orderId: string): void => {
    const { orders } = this.state;
    const order = orders.find(orderState => orderState.id === orderId);

    if (typeof order === 'undefined') {
      console.error(`Can not find order with id: ${orderId}`);
      return;
    }

    getConfig().then(config => {
      const { payments } = config;
      const { type } = payments;

      const channel = PAYMENT_CHANNEL.WEB;
      const buyerType = BUYER_TYPE.CUSTOMER;

      switch (type) {
        case 'worldPay': {
          this.setState(
            {
              isLoading: false,
              isRenderHiddenForm: true,
              orderId,
              config,
            },
            () => {
              this.formRef.current.submit();
            }
          );
          break;
        }

        case 'pay360Hosted': {
          payment360(orderId, channel, buyerType).then(res => {
            const { redirectUrl } = res;

            if (redirectUrl) {
              window.open(redirectUrl, '_self');
            } else {
              this.setState({
                isLoading: false,
                isPaymentUnavailable: true,
                orderId,
              });
            }
          });
          break;
        }

        case 'pay360Iframe': {
          payment360(orderId, channel, buyerType).then(res => {
            const { redirectUrl, sessionId } = res;

            if (redirectUrl && sessionId) {
              this.setState({ isLoading: false, orderId, iFrameSrc: redirectUrl, sessionId });
            } else {
              this.setState({
                isLoading: false,
                isPaymentUnavailable: true,
                orderId,
              });
            }
          });
          break;
        }
      }
    });
  };

  onDownloadImagesClick = (orderId: string) => {
    const { history } = this.props;
    history.push(`/customer/order/${orderId}`);
  };

  getTabs(): React.ReactNode {
    const {
      currentTabIndex,
      profile,
      isEditAddressMode,
      isEditMyDetailsMode,
      isEditChangePassword,
      isAddStudentMode,
      addressId,
      orders,
    } = this.state;
    const { customer, history, updateIsCustomerVerified } = this.props;

    const currentTab = PROFILE_TABS[currentTabIndex];

    switch (true) {
      case currentTab === PROFILE_CUSTOMER_TABS.MY_DETAILS && isEditMyDetailsMode:
        return (
          <CustomerMyDetailsForm
            onCancel={this.onMyDetailsFormCancel}
            onSubmit={this.onMyDetailsFormSubmit}
            profile={profile}
          />
        );
      case currentTab === PROFILE_CUSTOMER_TABS.MY_DETAILS && isEditChangePassword:
        return (
          <CustomerChangePasswordForm
            onCancel={this.onChangePasswordFormCancel}
            onSubmit={this.onChangePasswordFormSubmit}
            profile={profile}
          />
        );
      case currentTab === PROFILE_CUSTOMER_TABS.MY_DETAILS && !isEditMyDetailsMode:
        return (
          <CustomerMyDetailsTab
            profile={profile}
            onEditMyDetails={this.onEditMyDetails}
            onEditChangePassword={this.onEditChangePassword}
            onDeleteProfileClick={this.onDeleteProfileClick}
          />
        );
      case currentTab === PROFILE_CUSTOMER_TABS.ADDRESS_BOOK && !isEditAddressMode:
        return (
          <CustomerAddressBookTab
            profile={profile}
            setPrimaryBillingAddress={this.setPrimaryBillingAddress}
            setPrimaryDeliveryAddress={this.setPrimaryDeliveryAddress}
            onEditAddress={this.onEditAddress}
          />
        );
      case currentTab === PROFILE_CUSTOMER_TABS.ADDRESS_BOOK && isEditAddressMode:
        const { addressBook } = profile;
        const address = addressBook.find(addressBookAddress => addressBookAddress.id === addressId);

        if (typeof address !== 'undefined') {
          return (
            <CustomerAddressForm
              address={address}
              onSubmit={this.onAddressFormSubmit}
              onCancel={this.onAddressFormCancel}
            />
          );
        } else {
          console.error(`Can not find address with id ${addressId}`);
          return <div />;
        }
      case currentTab === PROFILE_CUSTOMER_TABS.ORDER_HISTORY:
        return (
          <CustomerOrderHistoryTab
            onPayOrderClick={this.onPayOrderClick}
            onDownloadImagesClick={this.onDownloadImagesClick}
            onViewOrderClick={this.onViewOrderClick}
            orders={orders}
          />
        );
      case currentTab === PROFILE_CUSTOMER_TABS.SUBSCRIPTIONS:
        return <CustomerSubscriptionsTab />;
      case currentTab === PROFILE_CUSTOMER_TABS.STUDENTS && !isAddStudentMode:
        return (
          <CustomerStudentsTab
            onAddStudentClick={this.onAddStudentClick}
            onShowImagesClick={this.onShowImagesClick}
            onDeleteStudentClick={this.onDeleteStudentClick}
            profile={profile}
            onOpenExplanationModalClick={this.onOpenExplanationModalClick}
          />
        );
      case currentTab === PROFILE_CUSTOMER_TABS.STUDENTS && isAddStudentMode:
        return (
          <CustomerAddStudentForm
            onSubmit={this.onSubmitAddStudentFormClick}
            onCancel={this.onCancelAddStudentFormClick}
            customer={customer}
            history={history}
          />
        );
      case currentTab === PROFILE_CUSTOMER_TABS.VERIFICATION:
        return (
          <CustomerVerificationTab
            customer={customer}
            profile={profile}
            updatePhoneInProfile={this.updatePhoneInProfile}
            updateEmailInProfile={this.updateEmailInProfile}
            {...this.props}
            updateIsCustomerVerified={updateIsCustomerVerified}
          />
        );
      default:
        return <div />;
    }
  }

  onShowImagesClick = () => {
    this.props.history.push('/customer');
  };

  onAddStudentClick = () => {
    this.setState({
      isAddStudentMode: true,
    });
  };

  onDeleteStudentClick = (studentId: string) => {
    this.setState({
      isConfirmationDeleteStudentModalOpen: true,
      studentId: studentId,
    });
  };

  onCancelNoAllowDuplicateStudentClick = () => {
    this.setState({
      isNoAllowDuplicateStudentModalOpen: false,
      isAddStudentMode: false,
      duplicateStudentText: '',
    });
  };

  onCancelDeleteStudentClick = () => {
    this.setState({
      isConfirmationDeleteStudentModalOpen: false,
      studentId: '',
    });
  };

  onCancelStudentPhotoExistClick = () => {
    const { customer } = this.props;
    this.setState({
      isLoading: true,
      isStudentPhotoExistModalOpen: false,
    });

    getCustomerProfile(customer).then(profile => {
      this.setState({
        existStudentPhotoCount: 0,
        isLoading: false,
        isAddStudentMode: false,
        profile,
      });
    });
  };

  onSubmitConfirmDetailsFormClick = data => {
    const { customerStudent } = this.state;
    const { customer } = this.props;
    const {
      formId,
      schoolId,
      forename,
      surname,
      selectedSchoolId,
      manualSchoolName,
      schoolNotServed,
      manualFormName,
    } = customerStudent;
    const { isConfirmLegallyEntitled, isLegalCarer, isParentOrGuardian, relation, requestComment } = data;

    const dataToTransfer = {
      forename: forename.trim(),
      surname: surname.trim(),
      isConfirmLegallyEntitled,
      isLegalCarer,
      isParentOrGuardian,
      relation,
      requestComment,
      selectedSchoolId,
      schoolNotServed,
    };

    const dataToTransferManual = {
      ...dataToTransfer,
      manualSchoolName: customerStudent.manualSchoolName ? customerStudent.manualSchoolName.trim() : undefined,
      manualFormName: customerStudent.manualFormName ? customerStudent.manualFormName.trim() : undefined,
    };

    this.setState({ isLoading: true });

    if (manualSchoolName || schoolNotServed) {
      addManualCustomerStudents(customer, dataToTransferManual)
        .then(() => getCustomerProfile(customer))
        .then(profile => {
          this.setState({
            isLoading: false,
            isConfirmDetailsModalOpen: false,
            isAddStudentMode: false,
            profile,
          });
        })
        .catch(error => {
          console.error('Error adding manual customer student:', error);
          this.setState({ isLoading: false });
        });
    } else if (manualFormName && schoolId) {
      console.log('Calling addManualFormCustomerStudents with:', dataToTransferManual);
      addManualFormCustomerStudents(
        customer,
        customerStudent.schoolId,
        customerStudent.manualFormName,
        dataToTransferManual
      )
        .then(() => getCustomerProfile(customer))
        .then(profile => {
          this.setState({
            isLoading: false,
            isConfirmDetailsModalOpen: false,
            isAddStudentMode: false,
            profile,
          });
        })
        .catch(error => {
          console.error('Error adding manual form customer student:', error);
          this.setState({ isLoading: false });
        });
    } else {
      console.log('Calling addCustomerStudents with:', dataToTransfer);
      addCustomerStudents(customer, schoolId, formId, dataToTransfer)
        .then(() => getCustomerProfile(customer))
        .then(profile => {
          this.setState({
            isLoading: false,
            isConfirmDetailsModalOpen: false,
            isAddStudentMode: false,
            profile,
          });
        })
        .catch(error => {
          console.error('Error adding customer student:', error);
          this.setState({ isLoading: false });
        });
    }
  };

  onOkConfirmationDeleteStudentModalClick = () => {
    const { customer } = this.props;
    const { studentId } = this.state;

    this.setState({
      isLoading: true,
    });

    removeCustomerStudents(customer, studentId)
      .then(res => {
        return getCustomerProfile(customer);
      })
      .then(profile => {
        this.setState({
          isLoading: false,
          isConfirmationDeleteStudentModalOpen: false,
          studentId: '',
          profile,
        });
      });
  };

  onTabClick = (event, index: number) => {
    event.preventDefault();
    const { isEditAddressMode, isEditMyDetailsMode, isAddStudentMode } = this.state;
    const { customer } = this.props;

    const nextTab = PROFILE_TABS[index];

    this.setState({
      isAddStudentMode: false,
    });

    if (!isEditAddressMode && !isEditMyDetailsMode) {
      switch (true) {
        case nextTab === PROFILE_CUSTOMER_TABS.ORDER_HISTORY:
          this.setState({
            isLoading: true,
          });

          getAllCustomerOrders(customer).then(orders => {
            this.setState({
              orders: orders,
              currentTabIndex: index,
              isLoading: false,
            });
          });
          break;
        default:
          this.setState({
            currentTabIndex: index,
          });
      }
    }
  };

  setPrimaryBillingAddress = addressId => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    setPrimaryCustomerBillingAddress(customer, addressId).then(profile => {
      this.setState({
        isLoading: false,
        profile,
      });
    });
  };

  setPrimaryDeliveryAddress = addressId => {
    const { customer } = this.props;

    this.setState({
      isLoading: true,
    });

    setPrimaryCustomerDeliveryAddress(customer, addressId).then(profile => {
      this.setState({
        isLoading: false,
        profile,
      });
    });
  };

  onOrderOkClick = () => {
    this.setState({
      isOrderModalOpen: false,
    });
  };

  onCancelConfirmDetailsFormClick = () => {
    this.setState({
      isConfirmDetailsModalOpen: false,
    });
  };

  onSubmitConfirmStudentBirthdayFormClick = data => {
    const { customerStudent } = this.state;
    const { customer } = this.props;
    const { dayOfBorn } = data;
    const dayOfBornNumber = Number(dayOfBorn);
    const { formId, schoolId, forename, surname } = customerStudent;

    const dataToTransfer = {
      forename: forename.trim(),
      surname: surname.trim(),
      dayOfBorn: dayOfBornNumber,
    };

    addCustomerStudents(customer, schoolId, formId, dataToTransfer)
      .then(() => {
        return getCustomerProfile(customer);
      })
      .then(profile => {
        this.setState({
          isLoading: false,
          isConfirmStudentBirthdayModalOpen: false,
          isAddStudentMode: false,
          profile,
        });
      });
  };

  onConfirmStudentBirthdayCancelClick = () => {
    this.setState({
      isLoading: false,
      isConfirmStudentBirthdayModalOpen: false,
    });
  };

  onConfirmDeleteProfile = async () => {
    const { customer, history } = this.props;
    this.props.onLogoutClick();
    this.setState({ isLoading: true });
    try {
      await deleteCustomerProfile(customer);
      window.location.href = '/login?accountDeleted=true';
    } catch (error) {
      console.error('Error deleting profile:', error);
      this.setState({ isLoading: false });
    }
  };

  onCancelDeleteProfile = () => {
    this.setState({ isConfirmationDeleteProfileModalOpen: false });
  };

  renderOrder(): React.ReactNode {
    const { isOrderModalOpen, orderId, orderImages } = this.state;

    const { orders } = this.state;
    const order = orders.find(orderState => orderState.id === orderId);

    return (
      <SimpleModal
        isOpen={isOrderModalOpen}
        title={'Order information'}
        buttonText={'Ok'}
        onButtonClick={this.onOrderOkClick}
      >
        <CustomerProfileOrderInformation order={order} orderImages={orderImages} />
      </SimpleModal>
    );
  }

  renderConfirmStudentBirthdayModal(): React.ReactNode {
    const { isConfirmStudentBirthdayModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isConfirmStudentBirthdayModalOpen} title={''}>
        <CustomerConfirmStudentBirthdayForm
          onCancel={this.onConfirmStudentBirthdayCancelClick}
          onSubmitConfirmStudentBirthdayFormClick={this.onSubmitConfirmStudentBirthdayFormClick}
        />
      </SimpleModal>
    );
  }

  renderHiddenForm() {
    const { orders, orderId, config } = this.state;
    const order = orders.find(orderState => orderState.id === orderId);
    const { deliveryType } = order;
    const isHomeDelivery = deliveryType === ORDER_DELIVERY_TYPE.HOME;

    const { customer } = this.props;
    const { customerId, basketId } = customer;
    const { payments } = config;
    const { worldpay } = payments;
    const { formActionUrl, testModeValue, instId, lang, accId1, currency } = worldpay;

    return isHomeDelivery ? (
      <form action={formActionUrl} method="POST" ref={this.formRef}>
        <input type="hidden" name="testMode" value={testModeValue} />
        <input type="hidden" name="instId" value={instId} />
        <input type="hidden" name="cartId" value={order.orderNumber} />
        <input type="hidden" name="amount" value={order.orderAmount} />
        <input type="hidden" name="currency" value={currency} />

        <input type="hidden" name="address1" value={order.invoice.address1} />
        <input type="hidden" name="town" value={order.invoice.town} />
        <input type="hidden" name="region" value={order.invoice.region} />
        <input type="hidden" name="postcode" value={order.invoice.postcode} />
        <input type="hidden" name="country" value={order.invoice.country} />
        <input type="hidden" name="authMode" value="A" />
        <input type="hidden" name="authValidFrom" value="" />
        <input type="hidden" name="authValidTo" value="" />
        <input type="hidden" name="name" value={`${order.invoice.firstName} ${order.invoice.lastName}`} />
        <input type="hidden" name="email" value={order.invoice.email} />
        <input type="hidden" name="tel" value={order.invoice.phone} />

        <input type="hidden" name="lang" value={lang} />
        <input type="hidden" name="hideCurrency" value="" />
        <input type="hidden" name="noLanguageMenu" value="" />
        <input type="hidden" name="accId1" value={accId1} />

        {/*custom field*/}
        <input type="hidden" name="MC_orderId" value={order.id} />
        <input type="hidden" name="MC_basketId" value={basketId} />
        <input type="hidden" name="MC_customerId" value={customerId} />
        <input type="hidden" name="MC_callbackY" value={getCustomerCallbackY()} />
        <input type="hidden" name="MC_callbackC" value={getCustomerCallbackC()} />
        <input type="hidden" name="MC_callback" value={getCallback()} />
        <input type="hidden" name="MC_mobileApp" value={'no'} />
      </form>
    ) : (
      <form action={formActionUrl} method="POST" ref={this.formRef}>
        <input type="hidden" name="testMode" value={testModeValue} />
        <input type="hidden" name="instId" value={instId} />
        <input type="hidden" name="cartId" value={order.orderNumber} />
        <input type="hidden" name="amount" value={order.orderAmount} />
        <input type="hidden" name="currency" value={currency} />

        <input type="hidden" name="country" value={DEFAULT_COUNTRY_OPTION_VALUE} />
        <input type="hidden" name="email" value={order.delivery.email} />
        <input type="hidden" name="tel" value={order.delivery.phone} />

        <input type="hidden" name="lang" value={lang} />
        <input type="hidden" name="hideCurrency" value="" />
        <input type="hidden" name="noLanguageMenu" value="" />
        <input type="hidden" name="accId1" value={accId1} />

        {/*custom field*/}
        <input type="hidden" name="MC_orderId" value={order.id} />
        <input type="hidden" name="MC_basketId" value={basketId} />
        <input type="hidden" name="MC_customerId" value={customerId} />
        <input type="hidden" name="MC_callbackY" value={getCustomerCallbackY()} />
        <input type="hidden" name="MC_callbackC" value={getCustomerCallbackC()} />
        <input type="hidden" name="MC_callback" value={getCallback()} />
        <input type="hidden" name="MC_mobileApp" value={'no'} />
      </form>
    );
  }

  renderHiddenIframe() {
    const { iFrameSrc } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <iframe src={iFrameSrc} width="100%" style={{ border: 'none' }} height="1300px" ref={this.iframeRef} />
          </div>
        </div>
      </div>
    );
  }

  renderStudentPhotoExistModal() {
    const { isStudentPhotoExistModalOpen, existStudentPhotoCount } = this.state;
    const isStudentPhotoExist = existStudentPhotoCount > 0;
    const existPhotoText =
      'Student has been added to your account and your photographs are available to view/purchase. Click Show images to view your photos.';
    const noExistPhotoText =
      'Student has been added to your account, although no photographs have been uploaded for them yet. You will get a notification once ' +
      'your photographs are ready.';

    return (
      <SimpleModal
        isOpen={isStudentPhotoExistModalOpen}
        title={''}
        body={isStudentPhotoExist ? existPhotoText : noExistPhotoText}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCancelStudentPhotoExistClick}
      />
    );
  }

  renderConfirmationDeleteStudentModal() {
    const { isConfirmationDeleteStudentModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationDeleteStudentModalOpen}
        title={'Confirmation'}
        body={'Are you sure you want to remove student?'}
        buttonText={'Remove'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onOkConfirmationDeleteStudentModalClick}
        onCloseClick={this.onCancelDeleteStudentClick}
      />
    );
  }

  renderNoAllowDuplicateStudentModal() {
    const { isNoAllowDuplicateStudentModalOpen, duplicateStudentText } = this.state;

    return (
      <SimpleModal
        isOpen={isNoAllowDuplicateStudentModalOpen}
        title={'Error'}
        body={duplicateStudentText}
        buttonText={'Ok'}
        onButtonClick={this.onCancelNoAllowDuplicateStudentClick}
      />
    );
  }
  onCancelAddStudentFormClick = () => {
    const { history } = this.props;
    history.push({
      search: '',
    });
    this.setState({
      isAddStudentMode: false,
    });
  };

  onSubmitAddStudentFormClick = data => {
    console.log('Data received in onSubmitAddStudentFormClick:', data);

    const { customer } = this.props;
    const { profile } = this.state;
    const { students } = profile;
    const {
      formId,
      schoolId,
      selectedSchoolId,
      forename,
      surname,
      manualSchoolName,
      manualFormName,
      schoolNotServed,
      hasForms,
    } = data;

    console.log('hasForms:', hasForms);
    console.log('schoolId:', schoolId);

    const dataToTransfer = {
      ...data,
      forename: forename.trim(),
      surname: surname.trim(),
    };

    console.log('Data to transfer to backend:', dataToTransfer);

    this.setState({
      isLoading: true,
    });

    if (manualSchoolName || (!hasForms && schoolId) || schoolNotServed) {
      this.setState({
        customerStudent: data,
        isLoading: false,
        isConfirmDetailsModalOpen: true,
      });
    } else {
      const { students } = profile;
      const duplicateAddedStudent = students.find(student => {
        const studentId = propz.get(student, ['student', 'studentId']);
        const isConfirmed = typeof studentId !== 'undefined';
        if (isConfirmed) {
          return (
            student.student.forename.trim().toLowerCase() === forename.trim().toLowerCase() &&
            student.student.surname.trim().toLowerCase() === surname.trim().toLowerCase() &&
            student.form.formId === formId
          );
        } else {
          return (
            student.forename.trim().toLowerCase() === forename.trim().toLowerCase() &&
            student.surname.trim().toLowerCase() === surname.trim().toLowerCase() &&
            student.form.formId === formId
          );
        }
      });
      const isDuplicateAddedStudentExist = typeof duplicateAddedStudent !== 'undefined';

      if (isDuplicateAddedStudentExist) {
        const studentId = propz.get(duplicateAddedStudent, ['student', 'studentId']);
        const isConfirmed = typeof studentId !== 'undefined';
        const studentVerifiedText =
          'Your account appears to be linked to this child. You can request access to another child or click Show images to view and order your photographs.';
        const studentInPendingRequestText =
          'You have a pending request to be linked to this child. You will get a notification once your access is approved.';
        const duplicateStudentText = isConfirmed ? studentVerifiedText : studentInPendingRequestText;

        this.setState({
          isLoading: false,
          duplicateStudentText,
          isNoAllowDuplicateStudentModalOpen: true,
        });
      } else {
        // Proceed with checking the customer student
        this.setState({
          customerStudent: data,
        });

        checkedCustomerStudent(customer, schoolId, formId, dataToTransfer)
          .then(res => {
            const isStudentBirthdayExist = res.isStudentBirthdayExist || false;
            const { lastName } = profile;

            const isLastNameCustomerEqualLastNameStudent =
              lastName.trim().toLowerCase() === surname.trim().toLowerCase();

            if (!isLastNameCustomerEqualLastNameStudent) {
              this.setState({
                isLoading: false,
                isConfirmDetailsModalOpen: true,
              });
            } else if (isStudentBirthdayExist) {
              this.setState({
                isLoading: false,
                isConfirmStudentBirthdayModalOpen: true,
              });
            } else {
              console.log('Calling addCustomerStudents with:', {
                customer,
                schoolId,
                formId,
                dataToTransfer,
              });
              addCustomerStudents(customer, schoolId, formId, dataToTransfer)
                .then(() => getCustomerProfile(customer))
                .then(profile => {
                  this.setState({
                    isLoading: false,
                    isAddStudentMode: false,
                    profile,
                  });
                });
            }
          })
          .catch(error => {
            console.error('Error checking customer student:', error);
            this.setState({
              isLoading: false,
            });
          });
      }
    }
  };

  onCloseErrorClick = () => {
    const { customer } = this.props;
    this.setState({
      isLoading: true,
      isEditMyDetailsMode: false,
      isEditChangePassword: false,
      isPasswordChangeModalOpen: false,
    });
    getCustomerProfile(customer).then(profile => {
      this.setState({
        isLoading: false,
        profile,
      });
    });
  };

  onOpenExplanationModalClick = isConfirmed => {
    this.setState({
      isExplanationModalOpen: true,
      isConfirmedStatusText: isConfirmed,
    });
  };

  onCloseExplanationModalClick = () => {
    this.setState({
      isExplanationModalOpen: false,
    });
  };

  renderPasswordChangeModal(): React.ReactNode {
    const { isPasswordChangeModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isPasswordChangeModalOpen}
        title={''}
        body={'Your password has been changed'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderConfirmDetailsModal(): React.ReactNode {
    const { isConfirmDetailsModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isConfirmDetailsModalOpen} title={''}>
        <CustomerConfirmDetailsForm
          onCancel={this.onCancelConfirmDetailsFormClick}
          onSubmitConfirmDetailsFormClick={this.onSubmitConfirmDetailsFormClick}
        />
      </SimpleModal>
    );
  }

  renderExplanationModal(): React.ReactNode {
    const { isExplanationModalOpen, isConfirmedStatusText } = this.state;
    const verifiedStatusText =
      'An exact match has been found. You may view the image(s) if they have been uploaded, if they have not been uploaded yet you will receive notification when they are ready to view.';
    const pendingStatusText =
      'No exact match has been found. A further check is required by our office team and you will receive notification within 48 hours.';

    return (
      <SimpleModal
        isOpen={isExplanationModalOpen}
        title={''}
        body={isConfirmedStatusText ? verifiedStatusText : pendingStatusText}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseExplanationModalClick}
      />
    );
  }

  deleteVerificationTab = () => {
    const { isCustomerVerified } = this.props;
    if (isCustomerVerified) {
      const profileTabsUpdated = [...PROFILE_TABS];
      const index = PROFILE_TABS.indexOf(PROFILE_CUSTOMER_TABS.VERIFICATION);
      profileTabsUpdated.splice(index, 1);
      return profileTabsUpdated;
    }
    return PROFILE_TABS;
  };

  onClosePaymentErrorClick = () => {
    this.setState({
      isPaymentUnavailable: false,
    });
  };

  renderPaymentError(): React.ReactNode {
    const { isPaymentUnavailable } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentUnavailable}
        title={'Error'}
        body={'Unfortunately, the payment service is currently unavailable. Please try again later'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderAccessCodeAddedModal(): React.ReactNode {
    const { isAccessCodeAddedModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isAccessCodeAddedModalOpen}>
        <ConfirmationModal
          text={
            'Access code has been added to your account. Please verify your account to receive notifications once your photos are ready.'
          }
          onOk={this.closeAccessCodeModal}
        />
      </SimpleModal>
    );
  }

  closeAccessCodeModal = () => {
    clearIsSignFromOneTimeCodeWaitingInStorage();

    this.setState({
      isAccessCodeAddedModalOpen: false,
    });
  };

  renderConfirmationDeleteProfileModal() {
    const { isConfirmationDeleteProfileModalOpen } = this.state;
    return (
      <SimpleModal
        isOpen={isConfirmationDeleteProfileModalOpen}
        title={'Confirm Deletion'}
        body={'Are you sure you want to delete your profile? This action cannot be undone.'}
        buttonText={'Delete'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onConfirmDeleteProfile}
        onCloseClick={this.onCancelDeleteProfile}
      />
    );
  }

  render() {
    const {
      isLoading,
      profile,
      currentTabIndex,
      isOrderModalOpen,
      isRenderHiddenForm,
      isConfirmationDeleteStudentModalOpen,
      isPasswordChangeModalOpen,
      isExplanationModalOpen,
      isStudentPhotoExistModalOpen,
      isConfirmStudentBirthdayModalOpen,
      isConfirmDetailsModalOpen,
      iFrameSrc,
      config,
      isPaymentUnavailable,
    } = this.state;

    const profileTabsUpdated = this.deleteVerificationTab();
    const type = propz.get(config, ['payments', 'type'], 'worldPay');

    if (isLoading) {
      return <Loader />;
    }

    const tabs = this.getTabs();

    const classes =
      isOrderModalOpen ||
      isConfirmationDeleteStudentModalOpen ||
      isPasswordChangeModalOpen ||
      isExplanationModalOpen ||
      isStudentPhotoExistModalOpen ||
      isConfirmStudentBirthdayModalOpen ||
      isConfirmDetailsModalOpen ||
      isPaymentUnavailable
        ? 'modal-open'
        : '';

    return (
      <div className={'bCustomerProfile'}>
        {isRenderHiddenForm && type === 'worldPay' && this.renderHiddenForm()}
        {typeof iFrameSrc !== 'undefined' && this.renderHiddenIframe()}
        <div className={classes}>
          {/*Place for render modal windows*/}
          {this.renderStudentPhotoExistModal()}
          {this.renderPasswordChangeModal()}
          {this.renderConfirmationDeleteProfileModal()}
          {this.renderExplanationModal()}
          {this.renderOrder()}
          {this.renderConfirmationDeleteStudentModal()}
          {this.renderNoAllowDuplicateStudentModal()}
          {this.renderConfirmStudentBirthdayModal()}
          {this.renderConfirmDetailsModal()}
          {this.renderPaymentError()}
          {this.renderAccessCodeAddedModal()}
          {typeof iFrameSrc === 'undefined' && (
            <div className="container">
              <div className="row">
                <div className="col-xl-10 offset-xl-1">
                  <div className="eCustomerProfileTitle">MY ACCOUNT</div>
                  <div className={'eCustomerProfileTextTitle'}>
                    Hello, {profile.firstName || ''} {profile.lastName || ''}.
                  </div>
                  <div className={'eCustomerProfileTextTitle'}>Welcome to your My Bentley account.</div>
                  <div className={'eCustomerProfileText'}>
                    Here you will be able to edit your details and view your order history and status.
                  </div>
                </div>
                <div className={'eCustomerProfileTabs'}>
                  <GenericSummary
                    currentTabIndex={currentTabIndex}
                    onTabClick={this.onTabClick}
                    tabs={profileTabsUpdated}
                    customClass={'eCustomerProfileTab'}
                  >
                    {tabs}
                  </GenericSummary>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { AppSchoolsUser2 } from '../../../AppSchools2';
import { SchoolImageWithTicket } from 'Src/models/schoolImages';
import * as propz from 'propz';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import {
  getSchoolUserSchoolJobImage,
  updateSchoolUserSchoolJobImage,
  createSchoolUserSchoolJobImageTicket,
  createSchoolUserImagePreview,
} from 'Src/services/schoolUser/schoolJobImages';
import { uploadFileSchoolUser } from 'Src/services/file';
import { FONT_NAME } from 'Src/consts/images';
import SortableItem from 'Src/components/SortableItem/SortableItem';

interface GroupStudent {
  _id: string;
  name: string;
  surname: string;
  order?: number;
}

interface GroupPhotoFormValues {
  groupName: string;
  fontName: string;
  groupStudents: GroupStudent[];
  crestLeft: string;
  crestRight: string;
}

interface Props {
  user: AppSchoolsUser2;
  image?: SchoolImageWithTicket;
  onCancel?: () => void;
  onSubmit?: (data: GroupPhotoFormValues) => void;
}

const GroupPhotoSchema = Yup.object().shape({
  groupName: Yup.string().required('Group name is required'),
  fontName: Yup.string().required('Font selection is required'),
  groupStudents: Yup.array().of(
    Yup.object().shape({
      name: Yup.string(),
      surname: Yup.string().test('nameOrSurname', 'Either name or surname must be provided', function(value) {
        const { name } = this.parent;
        return !!((name && name.trim()) || (value && value.trim()));
      }),
    })
  ),
});

const fontMapping: Record<string, string> = {
  ARIAL: 'Arial',
  GILL_SANS: 'Gill Sans',
  HELVETICA: 'Helvetica',
  TIMES_NEW_ROMAN: 'Times New Roman',
};

const crestContainerStyle = {
  width: '150px',
  height: '150px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #ccc',
  backgroundColor: '#f8f9fa',
};

export function GroupPhotoEdit(props: Props) {
  const { user } = props;
  const location = useLocation();
  const history = useHistory();

  const { schoolJobId, schoolImageId } = useParams() as { schoolJobId: string; schoolImageId: string };

  const initialImage: SchoolImageWithTicket | undefined = props.image || (location.state && location.state.image);

  const [currentImage, setCurrentImage] = React.useState<SchoolImageWithTicket | undefined>(initialImage);
  const [saved, setSaved] = React.useState(false);
  const [hasChanges, setHasChanges] = React.useState(false);
  const [formKey, setFormKey] = React.useState(currentImage?.id || '');
  const [uploadError, setUploadError] = React.useState<string | null>(null);
  const [leftFileName, setLeftFileName] = React.useState('No file chosen');
  const [rightFileName, setRightFileName] = React.useState('No file chosen');

  const [showPreview, setShowPreview] = React.useState(false);
  const [previewId, setPreviewId] = React.useState<string>('');

  React.useEffect(() => {
    if (schoolImageId && schoolJobId) {
      getSchoolUserSchoolJobImage(user, schoolJobId, schoolImageId)
        .then(fetchedImage => {
          if (fetchedImage) {
            setCurrentImage(fetchedImage);
            setFormKey(fetchedImage.id);
          } else {
            console.error('No image returned for id:', schoolImageId);
          }
        })
        .catch(err => console.error('Error fetching image:', err));
    }
  }, [schoolImageId, schoolJobId, user]);

  const TICKET_VALIDITY_DURATION = 60 * 60 * 1000;
  function ticketIsExpired(ticket: any): boolean {
    if (!ticket || !ticket.generatedAt) return true;
    return Date.now() >= ticket.generatedAt + TICKET_VALIDITY_DURATION;
  }

  React.useEffect(() => {
    if (currentImage && currentImage.key && currentImage.mimeType) {
      if (!currentImage.ticket || ticketIsExpired(currentImage.ticket)) {
        createSchoolUserSchoolJobImageTicket(user, {
          key: currentImage.key,
          mimeType: currentImage.mimeType,
        })
          .then(ticket => {
            const extendedTicket = { ...ticket, generatedAt: Date.now() };
            setCurrentImage(prev => ({ ...prev, ticket: extendedTicket }));
          })
          .catch(err => console.error('Error generating main image ticket:', err));
      }
    }
  }, [currentImage, user]);

  React.useEffect(() => {
    console.log('previewId:', previewId);
    console.log('Image src:', `${window.apiBase}/schoolUser/imagePreview/${previewId}?t=${Date.now()}`);
  }, [previewId]);

  const getInitialValues = (): GroupPhotoFormValues => {
    if (!currentImage) {
      return {
        groupName: '',
        fontName: FONT_NAME.ARIAL,
        groupStudents: [],
        crestLeft: '',
        crestRight: '',
      };
    }
    const sortedStudents = Array.isArray(currentImage.groupStudents)
      ? currentImage.groupStudents.slice().sort((a, b) => (a.order || 0) - (b.order || 0))
      : [];
    return {
      groupName: currentImage.groupName || '',
      fontName: currentImage.fontName ? currentImage.fontName.toUpperCase() : 'ARIAL',
      groupStudents: sortedStudents.map(student => ({
        _id: student._id,
        name: propz.get(student, ['name'], ''),
        surname: propz.get(student, ['surname'], ''),
        order: student.order,
      })),
      crestLeft: currentImage.crestLeft ? currentImage.crestLeft.pic : '',
      crestRight: currentImage.crestRight ? currentImage.crestRight.pic : '',
    };
  };

  const isMounted = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const handleFontChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    setFieldValue: (field: string, value: any) => void
  ) => {
    const newFont = e.target.value;
    setFieldValue('fontName', newFont);
  };

  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldName: string,
    setFieldValue: (field: string, value: any) => void
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (fieldName === 'crestLeft') {
        setLeftFileName(file.name);
      } else if (fieldName === 'crestRight') {
        setRightFileName(file.name);
      }
      const allowedTypes = ['image/jpeg', 'image/png', 'image/bmp'];
      if (!allowedTypes.includes(file.type)) {
        setUploadError('Only JPEG, PNG, and BMP files are allowed.');
        return;
      }
      const reader = new FileReader();
      reader.onload = event => {
        const img = new Image();
        img.onload = () => {
          if (img.width >= 200 && img.height >= 200) {
            setUploadError(null);
            const formData = new FormData();
            formData.append('file', file);
            uploadFileSchoolUser(user, formData)
              .then(data => {
                setFieldValue(fieldName, data.url);
              })
              .catch(err => {
                console.error('Error uploading file:', err);
              });
          } else {
            setUploadError('Selected image must be at least 200x200 pixels.');
          }
        };
        if (event.target?.result) {
          img.src = event.target.result as string;
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePreview = () => {
    if (!currentImage) return;

    const previewParams = {
      imageId: currentImage.id,
      job: currentImage.job,
      fileName: currentImage.fileName,
      mimeType: currentImage.mimeType,
      key: currentImage.key,
      groupName: currentImage.groupName,
      school: currentImage.school,
      photoType: currentImage.photoType,
      fontName: currentImage.fontName,
      groupStudents: currentImage.groupStudents,
      crestLeft: currentImage.crestLeft ? currentImage.crestLeft.pic : '',
      crestRight: currentImage.crestRight ? currentImage.crestRight.pic : '',
    };

    createSchoolUserImagePreview(user, previewParams)
      .then(data => {
        setPreviewId(data.previewId);
        setShowPreview(true);
      })
      .catch(err => console.error('Error generating preview:', err));
  };

  const defaultOnSubmit = async (values: GroupPhotoFormValues, formikHelpers: FormikHelpers<GroupPhotoFormValues>) => {
    if (!currentImage) return;
    const updatedGroupStudents = values.groupStudents.map((student, index) => ({
      ...student,
      order: index + 1,
    }));
    const jobId = String(currentImage.job.jobId);

    const updateData: any = {
      groupName: values.groupName,
      fontName: values.fontName,
      groupStudents: updatedGroupStudents,
    };

    if (values.crestLeft) {
      updateData.crestLeft = { pic: values.crestLeft };
    }

    if (values.crestRight) {
      updateData.crestRight = { pic: values.crestRight };
    }

    updateSchoolUserSchoolJobImage(user, jobId, currentImage.id, updateData)
      .then(updatedImage => {
        const completeImage = {
          ...updatedImage,
          key: updatedImage.key || currentImage.key,
          mimeType: updatedImage.mimeType || currentImage.mimeType,
        };
        return createSchoolUserSchoolJobImageTicket(user, {
          key: completeImage.key,
          mimeType: completeImage.mimeType,
        }).then(ticket => ({ ...completeImage, ticket: { ...ticket, generatedAt: Date.now() } }));
      })
      .then(mergedImage => {
        if (isMounted.current) {
          setCurrentImage({
            ...mergedImage,
            groupStudents: updatedGroupStudents,
          });
          formikHelpers.resetForm({
            values: {
              groupName: mergedImage.groupName,
              fontName: mergedImage.fontName,
              groupStudents: updatedGroupStudents,
              crestLeft: mergedImage.crestLeft ? mergedImage.crestLeft.pic : '',
              crestRight: mergedImage.crestRight ? mergedImage.crestRight.pic : '',
            },
          });
          setHasChanges(false);
          setSaved(true);

          setTimeout(() => {
            if (isMounted.current) {
              setSaved(false);
              setFormKey(mergedImage.id + '-' + new Date().getTime());
            }
          }, 200);
        }
      })
      .catch(err => {
        console.error('Error updating group order and crest images', err);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };

  const onSubmit = props.onSubmit || defaultOnSubmit;

  if (!currentImage) {
    return <div>Error: No image provided.</div>;
  }

  return (
    <div className="container-fluid group-photo-edit-page">
      <Button onClick={() => history.goBack()} text="Back" customClass="mb-3 btn btn-secondary" />
      {saved && (
        <div className="alert alert-success" role="alert">
          Changes saved!
        </div>
      )}
      {uploadError && (
        <div className="alert alert-danger" role="alert">
          {uploadError}
        </div>
      )}

      {showPreview && (
        <div
          className="modal-overlay"
          onClick={() => setShowPreview(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
        >
          <div
            className="modal-content"
            onClick={e => e.stopPropagation()}
            style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '4px',
              position: 'relative',
              maxWidth: '90%',
              maxHeight: '90%',
            }}
          >
            <button
              onClick={() => setShowPreview(false)}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                width: '40px',
                height: '40px',
                background: '#fff',
                fontSize: '24px',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
              }}
            >
              &times;
            </button>
            <p
              style={{
                fontStyle: 'italic',
                marginBottom: '10px',
                color: '#FF4500',
                textAlign: 'center',
                fontSize: '1.5em',
              }}
            >
              This content is for preview only. The final printed result may differ.
            </p>
            <img
              src={`${window.apiBase}/schoolUser/imagePreview/${previewId}?t=${Date.now()}`}
              alt="Preview"
              style={{ width: '100%', height: 'auto', maxHeight: '80vh', objectFit: 'contain' }}
            />
          </div>
        </div>
      )}
      <Formik
        key={formKey}
        enableReinitialize
        initialValues={getInitialValues()}
        validationSchema={GroupPhotoSchema}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, resetForm, dirty }) => {
          const [dragIndex, setDragIndex] = React.useState<number | null>(null);

          const handleDrop = (hoverIndex: number) => {
            if (dragIndex !== null && dragIndex !== hoverIndex) {
              const newOrder = [...values.groupStudents];
              const [moved] = newOrder.splice(dragIndex, 1);
              newOrder.splice(hoverIndex, 0, moved);
              setFieldValue('groupStudents', newOrder);
              setHasChanges(true);
            }
          };

          return (
            <Form>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h1>Edit Group Photo</h1>
                <Button
                  onClick={handlePreview}
                  text="Preview"
                  customClass="modern-button modern-save-button"
                  disabled={hasChanges || dirty}
                />
              </div>

              {(hasChanges || dirty) && (
                <div
                  style={{
                    backgroundColor: '#ffeb3b',
                    color: '#000',
                    padding: '10px',
                    margin: '15px 0',
                    borderRadius: '4px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  You have unsaved changes! Please scroll down and click Save to update your changes.
                </div>
              )}

              <div className="row">
                <div className="col-12 col-md-8">
                  <img
                    className="img-fluid img-thumbnail"
                    src={`${window.apiFile}/storage/images/${currentImage.ticket?.ticket || 'placeholder.jpg'}`}
                    alt={currentImage.fileName}
                    style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }}
                  />
                  <div className="mt-3">
                    <div style={{ marginBottom: '1rem' }}>
                      <strong>Student names – drag and drop items to change their order</strong>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '10px',
                        border: dirty ? '2px solid #28a745' : '1px dashed #ccc',
                        padding: '8px',
                        borderRadius: '4px',
                      }}
                    >
                      {values.groupStudents.map((student, index) => (
                        <SortableItem
                          key={student._id || index}
                          index={index}
                          student={student}
                          values={values}
                          setFieldValue={setFieldValue}
                          setHasChanges={setHasChanges}
                          onDragStart={setDragIndex}
                          onDrop={handleDrop}
                          fontName={values.fontName}
                        />
                      ))}
                    </div>
                    <div className="mt-2">
                      <Button
                        type="button"
                        onClick={() => {
                          const newStudent = { name: '', surname: '' };
                          setFieldValue('groupStudents', [...values.groupStudents, newStudent]);
                          setHasChanges(true);
                        }}
                        text="Add Student"
                        customClass="modern-button modern-add-button"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4">
                  <div className="d-flex justify-content-start align-items-center mb-3">
                    <label>
                      <strong>Group Name</strong>
                    </label>
                  </div>
                  <div className="form-group">
                    <Field
                      name="groupName"
                      placeholder="Group Name"
                      className="custom-form-control"
                      style={{ fontFamily: fontMapping[values.fontName] }}
                    />
                    <ErrorMessage name="groupName" component="div" className="alert alert-danger" />
                  </div>
                  <div className="form-group">
                    <label>
                      <strong>Font</strong>
                    </label>
                    <Field
                      as="select"
                      name="fontName"
                      className="form-control"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleFontChange(e, setFieldValue)}
                    >
                      {Object.keys(fontMapping).map(key => (
                        <option key={key} value={key} style={{ fontFamily: fontMapping[key] }}>
                          {fontMapping[key]}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="fontName" component="div" className="alert alert-danger" />
                  </div>
                  <div className="crests-container mb-3 d-flex justify-content-center" style={{ gap: '20px' }}>
                    <figure style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <figcaption style={{ textAlign: 'center', marginBottom: '5px', fontWeight: 'bold' }}>
                        Left Crest
                      </figcaption>
                      <div className="img-thumbnail" style={crestContainerStyle}>
                        {values.crestLeft ? (
                          <img
                            src={values.crestLeft}
                            alt="Left Crest"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          />
                        ) : (
                          <span>No Image</span>
                        )}
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <Field name="crestLeft">
                          {() => (
                            <>
                              <input
                                id="crestLeftInput"
                                type="file"
                                accept=".jpg,.jpeg,.png"
                                style={{ display: 'none' }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleFileChange(e, 'crestLeft', setFieldValue)
                                }
                              />
                              <label
                                htmlFor="crestLeftInput"
                                style={{
                                  display: 'inline-block',
                                  padding: '8px 16px',
                                  backgroundColor: '#efefef',
                                  color: '#000',
                                  borderRadius: '4px',
                                  border: '1px solid #333',
                                  cursor: 'pointer',
                                  marginTop: '5px',
                                  marginBottom: '0px',
                                }}
                              >
                                Choose file
                              </label>
                              <div className="truncate" style={{ marginTop: '5px' }}>
                                {leftFileName}
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                    </figure>

                    <figure style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <figcaption style={{ textAlign: 'center', marginBottom: '5px', fontWeight: 'bold' }}>
                        Right Crest
                      </figcaption>
                      <div className="img-thumbnail" style={crestContainerStyle}>
                        {values.crestRight ? (
                          <img
                            src={values.crestRight}
                            alt="Right Crest"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                          />
                        ) : (
                          <span>No Image</span>
                        )}
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <Field name="crestRight">
                          {() => (
                            <>
                              <input
                                id="crestRightInput"
                                type="file"
                                accept=".jpg,.jpeg,.png,.bmp"
                                style={{ display: 'none' }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                  handleFileChange(e, 'crestRight', setFieldValue)
                                }
                              />
                              <label
                                htmlFor="crestRightInput"
                                style={{
                                  display: 'inline-block',
                                  padding: '8px 16px',
                                  backgroundColor: '#efefef',
                                  color: '#000',
                                  borderRadius: '4px',
                                  border: '1px solid #333',
                                  cursor: 'pointer',
                                  marginTop: '5px',
                                  marginBottom: '0px',
                                }}
                              >
                                Choose file
                              </label>
                              <div className="truncate" style={{ marginTop: '5px' }}>
                                {rightFileName}
                              </div>
                            </>
                          )}
                        </Field>
                      </div>
                    </figure>
                  </div>
                </div>
              </div>

              {(hasChanges || dirty) && (
                <div className="text-right" style={{ marginTop: '15px' }}>
                  <button
                    type="submit"
                    className="modern-button modern-save-button"
                    style={{ display: 'inline-block' }}
                  >
                    Save
                  </button>
                  <div style={{ display: 'inline-block', marginLeft: '8px' }}>
                    <Button
                      onClick={() => {
                        resetForm();
                        setHasChanges(false);
                      }}
                      text="Cancel"
                      customClass="modern-button modern-cancel-button"
                    />
                  </div>
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

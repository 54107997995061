import axios from 'axios';

export function checkAvailability(data) {
  return axios.post(`${window.apiBase}/public/register/check`, data).then(response => {
    return response.data;
  });
}

export function register(data) {
  return axios.post(`${window.apiBase}/public/register`, data).then(response => {
    return response.data;
  });
}

export function checkValidParentCode(data) {
  return axios.post(`${window.apiBase}/public/schools/parentCode/check`, data).then(response => {
    return response.data;
  });
}

export function searchSchools(schoolName: string, town: string) {
  const payload = {
    schoolName,
    town,
  };

  return axios.post(`${window.apiBase}/public/allSchools/search`, payload).then(response => {
    return response.data;
  });
}

export function verifySchools(dfesNumber: string, leaNumber: string) {
  const payload = {
    dfesNumber,
    leaNumber,
  };

  return axios.post(`${window.apiBase}/public/schools/verify`, payload).then(response => {
    return response.data;
  });
}

import * as React from 'react';
import { AppUser } from 'Src/views/App/App';
import * as Promise from 'bluebird';
import { Component } from 'react';
import {
  ColumnDefinition,
  getFilters,
  getOrder,
  getSearchFilter,
  getSearchOrder,
  getServerFieldSectionWhere,
  getServerQueryFilter,
  getPagesCount,
  isFilterExist,
  isSortExist,
  TABLE_SORT_DIRECTION,
  convertToFilterWithPlus,
} from 'Src/helpers/table';
import { DATE_INTERVAL, FILTER_TYPE, FIRST_PAGE } from 'Src/consts/table';
import { parse } from 'query-string';
import { Loader } from 'Src/components/Loader/Loader';
import { Grid } from 'Src/components/Grid/Grid';
import { History, Location } from 'history';
import { SimpleModal } from 'Src/components/SimpleModal/SimpleModal';
import { Customer, CustomerStudent } from 'Src/models/customers';
import { getCustomer } from 'Src/services/superadmin/customers';
import * as propz from 'propz';
import { LIMIT } from 'Src/consts/table';
import {
  addCustomerStudent,
  deleteCustomerStudent,
  deleteCustomerStudentNoNotification,
  getAllCustomerStudents,
  getCustomerStudents,
  getCustomerStudentsCount,
} from 'Src/services/superadmin/customerStudents';
import { CustomerStudentForm } from './CustomerStudentForm/CustomerStudentForm';

const COLUMNS: ColumnDefinition[] = [
  {
    text: 'Forename',
    field: 'forename',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['forename'],
  },
  {
    text: 'Surname',
    field: 'surname',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['surname'],
  },
  {
    text: 'School name',
    field: 'schoolName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['school', 'schoolName'],
  },
  {
    text: 'Form name',
    field: 'formName',
    isSort: false,
    type: FILTER_TYPE.NONE,
    accessor: ['form', 'formName'],
  },
];

interface State {
  items: CustomerStudent[];
  currentPage: number;
  selectedItems: CustomerStudent[];
  sortDirection: TABLE_SORT_DIRECTION;
  sortColumnsName: string;
  isShowFilter: boolean;
  isDataFiltered: boolean;
  filters: any;
  isLoading: boolean;
  isSelectAllChecked: boolean;
  total: number;
  basePath: string;
  customer: Customer;
  isConfirmationModalOpen: boolean;
  isCustomerStudentFormModalOpen: boolean;
  isErrorModalOpen: boolean;
  errorMessage: string;
}

interface Props {
  user: AppUser;
  history: History;
  location: Location;
}

export class CustomerStudents extends Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      currentPage: FIRST_PAGE,
      selectedItems: [],
      sortDirection: '',
      sortColumnsName: '',
      isShowFilter: false,
      isDataFiltered: false,
      filters: {},
      isLoading: false,
      isSelectAllChecked: false,
      total: 0,
      basePath: '',
      customer: undefined,

      isConfirmationModalOpen: false,
      isCustomerStudentFormModalOpen: false,
      isErrorModalOpen: false,
      errorMessage: '',
    };
  }

  getCustomerId(): string {
    const { history } = this.props;
    const search = parse(history.location.search);
    const customerId = search.customer as string;
    return customerId;
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  }

  setAdditionalItems() {
    const { user } = this.props;
    const customerId = this.getCustomerId();

    return getCustomer(user, customerId).then(customer => {
      this.setState({
        customer: customer,
      });

      return true;
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({
        isLoading: true,
      });
      this.setItems();
    }
  }

  setItems() {
    const { history, user } = this.props;

    const search = parse(history.location.search);
    const page = typeof search.page !== 'undefined' ? Number(search.page) : FIRST_PAGE;

    let sortDirection = '';
    let sortColumnsName = '';

    if (typeof search.order === 'string') {
      [sortColumnsName, sortDirection] = search.order.split(':');
    }

    const sortByFieldExist = sortColumnsName !== '';
    const sortDirectionExist = sortDirection !== '';

    const filters = getFilters(COLUMNS, search);

    const isShowFilter = isFilterExist(filters);
    const isDataFiltered = isFilterExist(filters);

    const where = getServerFieldSectionWhere(filters);

    const order = sortByFieldExist && sortDirectionExist ? `${sortColumnsName} ${sortDirection}` : undefined;
    const serverQueryFilter = getServerQueryFilter(page, where, order);

    const customerId = this.getCustomerId();

    const getItemsPromise = getCustomerStudents(user, customerId, serverQueryFilter);
    const getItemsCountPromise = getCustomerStudentsCount(user, customerId, where);

    const promises = [getItemsCountPromise, getItemsPromise];

    return Promise.all(promises).then(([countObj, items]) => {
      this.setState({
        currentPage: page,
        sortDirection: sortDirection as TABLE_SORT_DIRECTION,
        sortColumnsName: sortColumnsName,
        isShowFilter: isShowFilter,
        isDataFiltered: isDataFiltered,
        filters: filters,
        items: items,
        total: countObj.count,
        basePath: history.location.pathname,
        isLoading: false,
      });

      return true;
    });
  }

  setCurrentPageParams = (currentPage: number): void => {
    let search = [];

    const { filters, sortColumnsName, sortDirection } = this.state;

    if (currentPage !== 1) {
      search.push(`page=${currentPage}`);
    }

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const customerId = this.getCustomerId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `customer=${customerId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onItemClick = (index: number): void => {
    const { items, selectedItems } = this.state;
    const selectedItem = items[index];

    const selectedItemIndex = selectedItems.findIndex(item => selectedItem.id === item.id);
    let selectedItemsUpdated = [...selectedItems];

    if (selectedItemIndex !== -1) {
      selectedItemsUpdated.splice(selectedItemIndex, 1);
    } else {
      selectedItemsUpdated.push(selectedItem);
    }

    this.setState({
      selectedItems: selectedItemsUpdated,
    });
  };

  onSelectAllOnPageClick = (): void => {
    const { items, selectedItems } = this.state;

    const itemsFiltered = items.filter(item => {
      return selectedItems.every(selectedItem => selectedItem.id !== item.id);
    });

    const selectedItemsNext = [...selectedItems, ...itemsFiltered];

    this.setState({
      selectedItems: selectedItemsNext,
      isSelectAllChecked: true,
    });
  };

  onSelectAllClick = (): void => {
    const { selectedItems, filters } = this.state;

    this.setState({
      isLoading: true,
    });

    const user = this.props.user;

    const where = getServerFieldSectionWhere(filters);

    const customerId = this.getCustomerId();

    getAllCustomerStudents(user, customerId, where).then(items => {
      const itemsFiltered = items.filter(item => {
        return selectedItems.every(selectedItem => selectedItem.id !== item.id);
      });

      const selectedItemsNext = [...selectedItems, ...itemsFiltered];

      this.setState({
        selectedItems: selectedItemsNext,
        isSelectAllChecked: true,
        isLoading: false,
      });
    });
  };

  onUnselectAllClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });
  };

  onTableSortClick = (sortField: string): void => {
    const { sortDirection, sortColumnsName, filters } = this.state;

    const order = getOrder(sortField, sortDirection, sortColumnsName);

    let search = [];
    search.push(`order=${sortField}:${order}`);

    const isFilter = isFilterExist(filters);

    if (isFilter) {
      search.push(getSearchFilter(filters));
    }

    const customerId = this.getCustomerId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `customer=${customerId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onTableFilterChange = (event, filterField: string, options?): void => {
    const filterValue = event.target.value;
    const filters = this.state.filters;
    const currentFilterField = filters[filterField];

    let nextFilters = { ...filters };

    if (typeof options !== 'undefined') {
      switch (options) {
        case DATE_INTERVAL.FROM:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              from: filterValue,
            },
          };
          break;
        case DATE_INTERVAL.TO:
          nextFilters = {
            ...nextFilters,
            [filterField]: {
              ...currentFilterField,
              to: filterValue,
            },
          };
          break;
      }
    } else {
      const filter = COLUMNS.find(col => col.field === filterField);
      const filterType = filter.type;

      if (filterType === FILTER_TYPE.MULTISELECT) {
        const options = event.target.options;
        const value = [];
        for (let i = 0; i < options.length; i++) {
          if (options[i].selected) {
            value.push(options[i].value);
          }
        }
        nextFilters = {
          ...nextFilters,
          [filterField]: value,
        };
      } else {
        nextFilters = {
          ...nextFilters,
          [filterField]: filterValue,
        };
      }
    }

    this.setState({
      filters: nextFilters,
    });
  };

  onApplyFilterClick = (): void => {
    const { filters, sortDirection, sortColumnsName } = this.state;
    let search = [];

    if (isSortExist(sortDirection, sortColumnsName)) {
      search.push(getSearchOrder(sortDirection, sortColumnsName));
    }

    const isFilter = isFilterExist(filters);

    const filtersConverted = convertToFilterWithPlus(filters);

    if (isFilter) {
      search.push(getSearchFilter(filtersConverted));
    }

    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const customerId = this.getCustomerId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `customer=${customerId}&${search.join('&')}`,
      state: history.location.state,
    });
  };

  onClearFilterClick = (): void => {
    this.setState({
      selectedItems: [],
      isSelectAllChecked: false,
    });

    const customerId = this.getCustomerId();

    const { history } = this.props;

    this.props.history.push({
      pathname: this.state.basePath,
      search: `customer=${customerId}`,
      state: history.location.state,
    });
  };

  onTableFilterClick = (event): void => {
    event.preventDefault();

    const isShowFilter = this.state.isShowFilter;

    this.setState({
      isShowFilter: !isShowFilter,
    });
  };

  goBack = () => {
    const { history, location } = this.props;
    const { state } = location;
    const { search } = state as any;
    const customerId = this.getCustomerId();

    history.push({
      pathname: '/customers',
      search: search,
      state: { customerId: customerId },
    });
  };

  showConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: true,
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  onDeleteStudentsClick = () => {
    const { selectedItems } = this.state;
    const { user } = this.props;

    this.setState({
      isLoading: true,
      isConfirmationModalOpen: false,
    });

    const customerId = this.getCustomerId();

    Promise.all(
      selectedItems.map(selectedItem => deleteCustomerStudentNoNotification(user, customerId, selectedItem.id))
    ).then(res => {
      this.setState({
        selectedItems: [],
        isSelectAllChecked: false,
      });

      this.setAdditionalItems().then(res => {
        this.setItems();
      });
    });
  };

  onCreateCustomerStudentClick = () => {
    this.setState({
      isCustomerStudentFormModalOpen: true,
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isCustomerStudentFormModalOpen: false,
    });
  };

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen, selectedItems } = this.state;

    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        body={`Are you sure you want to remove ${selectedItems.length === 1 ? 'student' : 'students'}?`}
        buttonText={'Remove'}
        buttonCancelText={'Cancel'}
        onButtonClick={this.onDeleteStudentsClick}
        onCloseClick={this.hideConfirmationModal}
      />
    );
  }

  onSubmitForm = data => {
    const { selectedItems, total } = this.state;
    const { user } = this.props;

    const customerId = this.getCustomerId();

    this.setState({
      isCustomerStudentFormModalOpen: false,
      isLoading: true,
    });

    addCustomerStudent(user, customerId, data)
      .then(res => {
        const lastPage = getPagesCount(total + 1, LIMIT);
        this.setState({
          selectedItems: [res],
          isSelectAllChecked: false,
        });
        this.setCurrentPageParams(lastPage);
        this.setAdditionalItems().then(res => {
          this.setItems();
        });
      })
      .catch(error => {
        const errorText = propz.get(error, ['response', 'data', 'details', 'text'], '');
        this.setState({
          isLoading: false,
          isErrorModalOpen: true,
          errorMessage: errorText,
        });
      });
  };

  renderFormModal(): React.ReactNode {
    const { isCustomerStudentFormModalOpen, customer } = this.state;
    const { user } = this.props;

    return (
      <SimpleModal isOpen={isCustomerStudentFormModalOpen}>
        <CustomerStudentForm
          user={user}
          onCancel={this.onCancelButtonClick}
          onSubmit={this.onSubmitForm}
          customer={customer}
        />
      </SimpleModal>
    );
  }

  onCloseErrorClick = () => {
    this.setState({
      isErrorModalOpen: false,
      errorMessage: '',
      isLoading: true,
    });
    this.setAdditionalItems().then(res => {
      this.setItems();
    });
  };

  renderSendingError(): React.ReactNode {
    const { errorMessage, isErrorModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isErrorModalOpen}
        title={'Error'}
        body={errorMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderGrid(): React.ReactNode {
    const {
      items,
      sortDirection,
      sortColumnsName,
      isShowFilter,
      isDataFiltered,
      selectedItems,
      filters,
      isSelectAllChecked,
      currentPage,
      total,
      customer,
    } = this.state;

    const actionItems = [
      {
        itemText: 'Create customer student',
        onItemClick: this.onCreateCustomerStudentClick,
        isActive: selectedItems.length === 0,
      },
      {
        itemText: selectedItems.length === 1 ? 'Delete customer student' : 'Delete customer students',
        onItemClick: this.showConfirmationModal,
        isActive: selectedItems.length > 0,
      },
    ];

    const filterOptions = {};

    const gridTitle =
      typeof customer !== 'undefined' ? `Customer ${customer.firstName} ${customer.lastName} / Students` : '';

    return (
      <Grid
        dataItems={items}
        filters={filters}
        currentPage={currentPage}
        total={total}
        isSelectAllChecked={isSelectAllChecked}
        isDataFiltered={isDataFiltered}
        sortDirection={sortDirection}
        sortColumnsName={sortColumnsName}
        isShowFilter={isShowFilter}
        dataItemsSelected={selectedItems}
        columns={COLUMNS}
        actionItems={actionItems}
        options={filterOptions}
        onItemClick={this.onItemClick}
        onSortClick={this.onTableSortClick}
        onApplyFilterClick={this.onApplyFilterClick}
        onClearFilterClick={this.onClearFilterClick}
        onTableFilterChange={this.onTableFilterChange}
        onTableFilterClick={this.onTableFilterClick}
        setCurrentPageParams={this.setCurrentPageParams}
        onSelectAllClick={this.onSelectAllClick}
        onSelectAllOnPageClick={this.onSelectAllOnPageClick}
        onUnselectAllClick={this.onUnselectAllClick}
        gridTitle={gridTitle}
        goBack={this.goBack}
      />
    );
  }

  render() {
    const { isLoading, isConfirmationModalOpen, isCustomerStudentFormModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }

    const classes = isConfirmationModalOpen || isCustomerStudentFormModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {/*Place for render modal windows*/}
        {this.renderConfirmationModal()}
        {this.renderSendingError()}
        {this.renderFormModal()}
        <div className="row">
          <div className="col-md-12">{this.renderGrid()}</div>
        </div>
      </div>
    );
  }
}

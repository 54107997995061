import { EmptyComponent } from '../components/EmptyComponent/EmptyComponent';
import { Users } from '../views/App/GenericView/Views/SuperAdminViews/Users/Users';
import { AppUser } from '../views/App/App';
import { isUserTypeAdmin, isUserTypeSuperAdmin, isUserTypeUser } from '../helpers/user';
import { SchoolTypes } from '../views/App/GenericView/Views/SuperAdminViews/SchoolTypes/SchoolTypes';
import { PhotoTypes } from '../views/App/GenericView/Views/SuperAdminViews/PhotoTypes/PhotoTypes';
import { Products } from '../views/App/GenericView/Views/SuperAdminViews/Products/Products';
import { PackagesRouting } from '../views/App/GenericView/Views/SuperAdminViews/Packages/PackagesRouting';
import { PreRegistrationsUsers } from '../views/App/GenericView/Views/SuperAdminViews/PreRegistrationsUsers/PreRegistrationsUsers';
import { EmptyMainComponent } from '../components/EmptyMainComponent/EmptyMainComponent';
import { DeliveryCharges } from '../views/App/GenericView/Views/SuperAdminViews/DeliveryCharges/DeliveryCharges';
import { JobsRouting } from '../views/App/GenericView/Views/SuperAdminViews/Jobs/JobsRouting';
import { Offers } from '../views/App/GenericView/Views/SuperAdminViews/Offers/Offers';
import { OrdersRouting } from '../views/App/GenericView/Views/SuperAdminViews/Orders/OrdersRouting';
import { AllOrderImages } from '../views/App/GenericView/Views/SuperAdminViews/AllOrderImages/AllOrderImages';
import { Vouchers } from '../views/App/GenericView/Views/SuperAdminViews/Vouchers/Vouchers';
import { Images } from '../views/App/GenericView/Views/SuperAdminViews/Images/Images';
import { CustomersRouting } from '../views/App/GenericView/Views/SuperAdminViews/Customers/CustomersRouting';
import { SlidersRouting } from '../views/App/GenericView/Views/SuperAdminViews/Sliders/SlidersRouting';
import { IntegrationLogs } from '../views/App/GenericView/Views/SuperAdminViews/IntegrationLogs/IntegrationLogs';
import { ProductTypes } from '../views/App/GenericView/Views/SuperAdminViews/ProductTypes/ProductTypes';
import { SchoolsRouting } from '../views/App/GenericView/Views/SuperAdminViews/Schools/SchoolsRouting';
import { AllSchoolsRouting } from '../views/App/GenericView/Views/SuperAdminViews/AllSchools/AllSchoolsRouting';
import { CustomerWithStudents } from '../views/App/GenericView/Views/SuperAdminViews/Customers/CustomerWithStudents/CustomerWithStudents';
import { UploadFilesView } from '../views/AppSchools/SchoolAppGenericView/UploadFilesView/UploadFilesView';
import { UnsubscribedEmails } from '../views/App/GenericView/Views/SuperAdminViews/UnsubscribedEmails/UnsubscribedEmails';
import { DataExchange } from '../views/App/GenericView/Views/SuperAdminViews/DataExchange/DataExchange';
import { SchoolAppUploadFilesView2 } from '../views/AppSchools2/SchoolAppUploadFilesView2/SchoolAppUploadFilesView2';
import { SchoolAppSchoolUsers2 } from '../views/AppSchools2/SchoolAppSchoolUsers2/SchoolAppSchoolUsers2';
import { SchoolJobsRouting } from '../views/App/GenericView/Views/SuperAdminViews/SchoolJobs/SchoolJobsRouting';
import { SchoolAppSchoolJobsRouting } from '../views/AppSchools2/SchoolAppSchoolJobs2/SchoolAppSchoolJobsRouting';
import { SchoolAppSchoolInformation2 } from '../views/AppSchools2/SchoolAppSchoolInformation2/SchoolAppSchoolInformation2';
import { SchoolAppDashboard2 } from '../views/AppSchools2/SchoolAppDashboard2/SchoolAppDashboard2';
import { ContactDetails } from '../views/App/GenericView/Views/SuperAdminViews/ContactDetails/ContactDetails';
import { SchoolAppContactDetails2 } from '../views/AppSchools2/SchoolAppContactDetails2/SchoolAppContactDetails2';

interface Route {
  path: string;
  component: any;
  exact: boolean;
}

export const superAdminRoutes: Route[] = [
  {
    path: '/',
    component: EmptyMainComponent,
    exact: true,
  },
  {
    path: '/users',
    component: Users,
    exact: true,
  },
  {
    path: '/customers',
    component: CustomersRouting,
    exact: false,
  },
  {
    path: '/schoolTypes',
    component: SchoolTypes,
    exact: true,
  },
  {
    path: '/schools',
    component: SchoolsRouting,
    exact: false,
  },
  {
    path: '/allSchools',
    component: AllSchoolsRouting,
    exact: false,
  },
  {
    path: '/photoTypes',
    component: PhotoTypes,
    exact: true,
  },
  {
    path: '/productTypes',
    component: ProductTypes,
    exact: true,
  },
  {
    path: '/products',
    component: Products,
    exact: true,
  },
  {
    path: '/offers',
    component: Offers,
    exact: true,
  },
  {
    path: '/vouchers',
    component: Vouchers,
    exact: true,
  },
  {
    path: '/packages',
    component: PackagesRouting,
    exact: false,
  },
  {
    path: '/deliveryCharges',
    component: DeliveryCharges,
    exact: true,
  },
  {
    path: '/jobs',
    component: JobsRouting,
    exact: false,
  },
  {
    path: '/schoolJobs',
    component: SchoolJobsRouting,
    exact: false,
  },
  {
    path: '/preRegistrations',
    component: PreRegistrationsUsers,
    exact: true,
  },
  {
    path: '/orders',
    component: OrdersRouting,
    exact: false,
  },
  {
    path: '/allOrderImages',
    component: AllOrderImages,
    exact: true,
  },
  {
    path: '/images',
    component: Images,
    exact: true,
  },
  {
    path: '/sliders',
    component: SlidersRouting,
    exact: false,
  },
  {
    path: '/integrationLogs',
    component: IntegrationLogs,
    exact: true,
  },
  {
    path: '/customerWithStudents',
    component: CustomerWithStudents,
    exact: true,
  },
  {
    path: '/unsubscribedEmails',
    component: UnsubscribedEmails,
    exact: true,
  },
  {
    path: '/dataExchange',
    component: DataExchange,
    exact: true,
  },
  {
    path: '/contactDetails',
    component: ContactDetails,
    exact: true,
  },
];

export const adminRoutes: Route[] = [
  {
    path: '/',
    component: EmptyMainComponent,
    exact: true,
  },
  {
    path: '/users',
    component: Users,
    exact: true,
  },
  {
    path: '/customers',
    component: CustomersRouting,
    exact: false,
  },
  {
    path: '/schoolTypes',
    component: SchoolTypes,
    exact: true,
  },
  {
    path: '/schools',
    component: SchoolsRouting,
    exact: false,
  },
  {
    path: '/allSchools',
    component: AllSchoolsRouting,
    exact: false,
  },
  {
    path: '/photoTypes',
    component: PhotoTypes,
    exact: true,
  },
  {
    path: '/productTypes',
    component: ProductTypes,
    exact: true,
  },
  {
    path: '/products',
    component: Products,
    exact: true,
  },
  {
    path: '/offers',
    component: Offers,
    exact: true,
  },
  {
    path: '/packages',
    component: PackagesRouting,
    exact: false,
  },
  {
    path: '/deliveryCharges',
    component: DeliveryCharges,
    exact: true,
  },
  {
    path: '/jobs',
    component: JobsRouting,
    exact: false,
  },
  {
    path: '/schoolJobs',
    component: SchoolJobsRouting,
    exact: false,
  },
  {
    path: '/preRegistrations',
    component: PreRegistrationsUsers,
    exact: true,
  },
  {
    path: '/images',
    component: Images,
    exact: true,
  },
  {
    path: '/orders',
    component: OrdersRouting,
    exact: false,
  },
  {
    path: '/allOrderImages',
    component: AllOrderImages,
    exact: true,
  },
  {
    path: '/vouchers',
    component: Vouchers,
    exact: true,
  },
  {
    path: '/sliders',
    component: SlidersRouting,
    exact: false,
  },
  {
    path: '/integrationLogs',
    component: IntegrationLogs,
    exact: true,
  },
  {
    path: '/customerWithStudents',
    component: CustomerWithStudents,
    exact: true,
  },
  {
    path: '/unsubscribedEmails',
    component: UnsubscribedEmails,
    exact: true,
  },
  {
    path: '/dataExchange',
    component: DataExchange,
    exact: true,
  },
  {
    path: '/contactDetails',
    component: ContactDetails,
    exact: true,
  },
];

export const userRoutes: Route[] = [
  {
    path: '/',
    component: EmptyComponent,
    exact: true,
  },
];

export const defaultRoutes: Route[] = [
  {
    path: '/',
    component: EmptyComponent,
    exact: true,
  },
];

export function getRoutes(user: AppUser): Route[] {
  switch (true) {
    case isUserTypeSuperAdmin(user):
      return superAdminRoutes;
    case isUserTypeAdmin(user):
      return adminRoutes;
    case isUserTypeUser(user):
      return userRoutes;
    default:
      return defaultRoutes;
  }
}

export const adminSchoolAppRoutes: Route[] = [
  {
    path: '/upload',
    component: UploadFilesView,
    exact: true,
  },
];
export const parentSchoolAppRoutes: Route[] = [];
export const adminSUSchoolAppRoutes: Route[] = [
  {
    path: '/upload',
    component: UploadFilesView,
    exact: true,
  },
];

export const schoolUserAdminRoutes: Route[] = [
  {
    path: '/upload/bookings',
    component: SchoolAppUploadFilesView2,
    exact: true,
  },

  {
    path: '/upload/downloads',
    component: SchoolAppUploadFilesView2,
    exact: true,
  },
  {
    path: '/upload/links',
    component: SchoolAppUploadFilesView2,
    exact: true,
  },
  {
    path: '/upload/pdf',
    component: SchoolAppUploadFilesView2,
    exact: true,
  },
  {
    path: '/users',
    component: SchoolAppSchoolUsers2,
    exact: true,
  },
  {
    path: '/schoolJobs',
    component: SchoolAppSchoolJobsRouting,
    exact: false,
  },
  {
    path: '/schoolInformation',
    component: SchoolAppSchoolInformation2,
    exact: false,
  },
  {
    path: '/dashboard',
    component: SchoolAppDashboard2,
    exact: false,
  },
  {
    path: '/',
    component: SchoolAppDashboard2,
    exact: true,
  },
  {
    path: '/contactDetails',
    component: SchoolAppContactDetails2,
    exact: false,
  },
];

export const schoolUserNotAdminRoutes: Route[] = [
  {
    path: '/upload/bookings',
    component: SchoolAppUploadFilesView2,
    exact: true,
  },
  {
    path: '/upload/downloads',
    component: SchoolAppUploadFilesView2,
    exact: true,
  },
  {
    path: '/upload/links',
    component: SchoolAppUploadFilesView2,
    exact: true,
  },
  {
    path: '/upload/pdf',
    component: SchoolAppUploadFilesView2,
    exact: true,
  },
  {
    path: '/dashboard',
    component: SchoolAppDashboard2,
    exact: false,
  },
  {
    path: '/',
    component: SchoolAppDashboard2,
    exact: true,
  },
  {
    path: '/contactDetails',
    component: SchoolAppContactDetails2,
    exact: false,
  },
];

import * as React from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { checkAvailability, checkValidParentCode } from '../../../services/public/register';
import { History, Location } from 'history';
import './Registration.scss';
import { useState } from 'react';
import { Checkbox } from '../../../components/Checkbox/Checkbox';
import { getFromHistory } from '../../../helpers/history';
import { Link } from 'react-router-dom';
import { YUP_MUST_BE_VALID_EMAIL, YUP_REQUIRED } from '../../../consts/yup';
import { RegistrationInfoModal } from './RegistrationInfoModal/RegistrationInfoModal';
import { getSelectCountryOptions } from '../../../helpers/select';
import { getPhoneWithoutFirstZero } from '../../../helpers/phone';

interface Props {
  onSubmit: (data) => any;
  history: History;
  location: Location;
}

export function Registration(props: Props) {
  const { onSubmit, history } = props;

  const [phoneCode, setPhoneCode] = useState('+7');
  const [inputPasswordType, changeInputPasswordType] = useState('password');
  const [inputConfirmPasswordType, changeInputConfirmPasswordType] = useState('password');
  const [isRegistrationInfoFormModalOpen, setIsRegistrationInfoFormModalOpen] = useState(false);

  const onPasswordClick = (): void => {
    changeInputPasswordType(inputPasswordType === 'password' ? 'text' : 'password');
  };

  const onConfirmPasswordClick = (): void => {
    changeInputConfirmPasswordType(inputConfirmPasswordType === 'password' ? 'text' : 'password');
  };

  const onRegistrationInfoFormModalOpen = (): void => {
    setIsRegistrationInfoFormModalOpen(true);
  };

  const onRegistrationInfoFormModalClose = (): void => {
    setIsRegistrationInfoFormModalOpen(false);
  };

  const schoolCode = getFromHistory(history, 'schoolCode');
  const isSchoolCodeExist = typeof schoolCode !== 'undefined';

  const isParentInvite = new URLSearchParams(history.location.search).get('isParentInvite') === 'true';

  const voucherCode = getFromHistory(history, 'voucherCode');
  const isVoucherCodeExist = typeof voucherCode !== 'undefined';

  const customerForm = {
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    phoneCode: '+44',
    termsAndConditionsAgreement: false,
    promoEmailAgreement: false,
    parentCode: isSchoolCodeExist ? schoolCode : '',
    isParentInvite: isParentInvite ? isParentInvite : false,
    voucher: isVoucherCodeExist ? voucherCode : '',
  };

  const CustomerSchema = Yup.object().shape({
    email: Yup.string()
      .required(YUP_REQUIRED)
      .trim()
      .email(YUP_MUST_BE_VALID_EMAIL)
      .test('email', 'This email address has already been registered with us.', value =>
        checkAvailability({ email: value }).then(res => {
          return res.isAvailable;
        })
      ),
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    phoneCode: Yup.string().required('Required'),
    phone: Yup.string()
      .required('Required')
      .test('phone', 'Duplicate phone or incorrect symbol', function(value) {
        const phoneRegExp = /^\d+$|^\d+#\d+$/;
        const phone = getPhoneWithoutFirstZero(value);
        const result = phoneRegExp.test(phone);
        const phoneForCheck = `${phoneCode}${phone}`;
        return result
          ? checkAvailability({ phone: phoneForCheck }).then(res => {
              return res.isAvailable;
            })
          : false;
      }),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    termsAndConditionsAgreement: Yup.boolean().test(
      'termsAndConditionsAgreement',
      'Select the tick box to continue',
      value => {
        return value;
      }
    ),
    parentCode: Yup.string().test('parentCode', 'Not valid', value => {
      if (!value) {
        return true;
      }

      return checkValidParentCode({ parentCode: value }).then(res => {
        return res.isValid;
      });
    }),
  });

  const renderInfoModal = () => {
    return (
      <RegistrationInfoModal
        isOpen={isRegistrationInfoFormModalOpen}
        onButtonClick={onRegistrationInfoFormModalClose}
      />
    );
  };

  const classes = isRegistrationInfoFormModalOpen ? 'mt-3 modal-open' : 'mt-3';
  return (
    <div className={classes}>
      {renderInfoModal()}
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="bRegistrationForm">
              <div className="eRegistrationFormTitle">CREATE ACCOUNT</div>
              <div className="eRegistrationFormText">To create an account we just need a few details from you</div>
              <div className="mb-4">
                <a className="eRegistrationFormLinkText" onClick={onRegistrationInfoFormModalOpen}>
                  Why do we need this information?
                </a>
              </div>
              <Formik
                initialValues={customerForm}
                validationSchema={CustomerSchema}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={values => {
                  onSubmit(values).then(() => {
                    const { phone } = values;
                    const phoneWithoutFirstZero = getPhoneWithoutFirstZero(phone);
                    const params = new URLSearchParams();
                    if (isSchoolCodeExist) {
                      params.append('schoolCode', schoolCode);
                    }
                    if (isParentInvite) {
                      params.append('isParentInvite', 'true');
                    }

                    history.push({
                      pathname: '/verification',
                      search: new URLSearchParams({
                        ...(isSchoolCodeExist && { schoolCode }),
                        ...(isParentInvite && { isParentInvite: 'true' }),
                      }).toString(),
                      state: { email: values.email, phone: phoneWithoutFirstZero, code: values.phoneCode },
                    });
                  });
                }}
              >
                {({ setFieldValue, values }) => (
                  <Form className="d-flex justify-content-center">
                    <div className="eRegistrationFormInputItems">
                      <div className="">
                        <Field name="email" className="eRegistrationFormInput" placeholder="Email address" />

                        <ErrorMessage name="email">
                          {msg =>
                            msg === YUP_REQUIRED || msg === YUP_MUST_BE_VALID_EMAIL ? (
                              <div className="alert alert-danger mRegistrationError">{msg}</div>
                            ) : (
                              <div className="alert alert-danger mRegistrationError">
                                {msg}{' '}
                                <Link to={{ pathname: '/login', search: `username=${values.email}` }}>
                                  <strong>Click to use this account</strong>
                                </Link>{' '}
                                or choose different email address.
                              </div>
                            )
                          }
                        </ErrorMessage>
                      </div>

                      <div className="d-flex w-100">
                        <Field
                          name="password"
                          type={inputPasswordType}
                          className="eRegistrationFormInputPassword"
                          placeholder="Personal password"
                        />
                        <div
                          className="eRegistrationFormEyePassword"
                          onMouseDown={onPasswordClick}
                          onMouseUp={onPasswordClick}
                        >
                          {inputPasswordType === 'text' ? (
                            <svg
                              width="3em"
                              height="3em"
                              viewBox="0 0 16 16"
                              className="bi bi-eye"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="3em"
                              height="3em"
                              viewBox="0 0 16 16"
                              className="bi bi-eye-slash"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                              <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                              <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z" />
                              <path fillRule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z" />
                            </svg>
                          )}
                        </div>
                      </div>
                      <ErrorMessage component="div" className="alert alert-danger mRegistrationError" name="password" />

                      <div className="d-flex justify-content-center">
                        <Field
                          name="confirmPassword"
                          type={inputConfirmPasswordType}
                          className="eRegistrationFormInputPassword"
                          placeholder="Confirm password"
                        />
                        <div
                          className="eRegistrationFormEyePassword"
                          onMouseDown={onConfirmPasswordClick}
                          onMouseUp={onConfirmPasswordClick}
                        >
                          {inputConfirmPasswordType === 'text' ? (
                            <svg
                              width="3em"
                              height="3em"
                              viewBox="0 0 16 16"
                              className="bi bi-eye-fill"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"
                              />
                              <path
                                fillRule="evenodd"
                                d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="3em"
                              height="3em"
                              viewBox="0 0 16 16"
                              className="bi bi-eye-slash-fill"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
                              <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299l.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
                              <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709z" />
                              <path fillRule="evenodd" d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z" />
                            </svg>
                          )}
                        </div>
                      </div>
                      <ErrorMessage
                        component="div"
                        className="alert alert-danger mRegistrationError"
                        name="confirmPassword"
                      />

                      <div className="">
                        <Field name="firstName" className="eRegistrationFormInput" placeholder="First Name" />
                        <ErrorMessage
                          component="div"
                          className="alert alert-danger mRegistrationError"
                          name="firstName"
                        />
                      </div>

                      <div className="">
                        <Field name="lastName" className="eRegistrationFormInput" placeholder="Last Name" />
                        <ErrorMessage
                          component="div"
                          className="alert alert-danger mRegistrationError"
                          name="lastName"
                        />
                      </div>

                      <div className="">
                        <Field
                          name="phoneCode"
                          component="select"
                          className="eRegistrationFormInput"
                          onChange={event => {
                            setPhoneCode(event.target.value);
                            setFieldValue('phoneCode', event.target.value);
                          }}
                        >
                          {getSelectCountryOptions()}
                        </Field>

                        <div className="">
                          <Field name="phone" className="eRegistrationFormInput" placeholder="Phone" />
                          <ErrorMessage
                            component="div"
                            className="alert alert-danger mRegistrationError"
                            name="phone"
                          />
                        </div>
                      </div>

                      <Checkbox
                        customClass="eRegistrationFormCheckbox"
                        name="termsAndConditionsAgreement"
                        onChange={event => {
                          setFieldValue('termsAndConditionsAgreement', event.target.checked);
                        }}
                      >
                        {'I have read and agree to the '}
                        <a className="bLinkText" href="/termsAndConditions" target="_blank">
                          Terms and Conditions
                        </a>
                      </Checkbox>

                      <ErrorMessage
                        component="div"
                        className="alert alert-danger mRegistrationError"
                        name="termsAndConditionsAgreement"
                      />

                      <Checkbox
                        customClass="eRegistrationFormCheckbox"
                        name="promoEmailAgreement"
                        onChange={event => {
                          setFieldValue('promoEmailAgreement', event.target.checked);
                        }}
                      >
                        I agree to receive exciting personalised offers
                      </Checkbox>

                      <button className="eRegistrationFormButton" type="submit">
                        Next
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

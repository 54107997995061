import * as React from 'react';
import { Route, Switch } from 'react-router';
import { AllSchools } from './AllSchools';

export const AllSchoolsRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/allSchools'} render={props => <AllSchools {...props} user={allProps.user} />} />
    </Switch>
  );
};

AllSchoolsRouting.displayName = 'AllSchoolsRouting';

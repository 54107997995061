import * as React from 'react';
import { AppUser } from '../../../../../../App';
import { Customer } from '../../../../../../../../models/customers';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from '../../../../../../../../components/Button/Button';
import * as Yup from 'yup';
import './CustomerStudentsForm.scss';
import { Autocomplete } from '../../../../../../../../components/Autocomplete/Autocomplete';
import {
  getForenameAndSurname,
  getFormName,
  searchFunctionForms,
  searchFunctionSchoolStudent,
  searchFunctionStudents,
  searchFunctionSchoolsByCode,
  getSchoolNameAndCode,
} from '../../../../../../../../helpers/autocomplete';
import { Student } from '../../../../../../../../models/students';
import * as propz from 'propz';
import {
  REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING,
  REASON_FOR_CANCELLATION,
  STUDENT_REQUEST_STATUS,
} from '../../../../../../../../consts/customer';
import { getYesOrNoForBoolean } from '../../../../../../../../helpers/select';
import { getCustomerName } from '../../../../../../../../helpers/accessor';
import * as Moment from 'moment';
import { BIRTH_DATE_FORMAT } from '../../../../../../../../consts/consts';
import { updateCustomerStudent } from '../../../../../../../../services/superadmin/customerStudents';

interface Props {
  onCancel: () => void;
  onSubmit: (data: any) => void;
  user: AppUser;
  customer: Customer;
  allCustomerStudents: Student[];
  onDeleteStudentClick: (id: string) => void;
  updateRequestStatusToNewCustomerStudentClick: (id: string) => void;
  updateRequestStatusNoMatchCustomerStudentClick: (id: string) => void;
}

export function CustomerStudentsForm(props: Props) {
  const {
    customer,
    onSubmit,
    onCancel,
    allCustomerStudents,
    onDeleteStudentClick,
    updateRequestStatusToNewCustomerStudentClick,
    updateRequestStatusNoMatchCustomerStudentClick,
    user,
  } = props;

  const studentsForm = {
    students: customer.students.map(student => ({
      studentId: '',
      forename: '',
      surname: '',
      formDisabled: true,
      isApproved: false,
      commentEnabled: student.requestStatus === STUDENT_REQUEST_STATUS.POSTPONED,
      comment: propz.get(student, ['comment'], ''),
      schoolId: student.school ? student.school.schoolId.toString() : '',
    })),
    forms: customer.students.map(student => ({
      formId: undefined,
      form: {
        id: '',
        name: '',
      },
    })),
  };

  const StudentsSchema = Yup.object().shape({
    students: Yup.array().of(
      Yup.object().shape({
        schoolId: Yup.string().when('isApproved', {
          is: true,
          then: Yup.string().required('School is required'),
          otherwise: Yup.string(),
        }),
        studentId: Yup.string().when('isApproved', {
          is: true,
          then: Yup.string().required('Student is required'),
          otherwise: Yup.string(),
        }),
      })
    ),
    forms: Yup.array().of(
      Yup.object().shape({
        formId: Yup.string().when('isApproved', {
          is: true,
          then: Yup.string().required('Form is required'),
          otherwise: Yup.string(),
        }),
      })
    ),
  });

  const getSchools = (text: string) => {
    return searchFunctionSchoolsByCode(user, text);
  };

  const getStudents = (text, schoolId, setFieldValue, values, index) => {
    if (!schoolId) {
      return [];
    }

    const formId = values.forms[index]?.formId;

    if (!text) {
      return formId
        ? searchFunctionStudents(user, '', allCustomerStudents, schoolId, formId, values.students)
        : searchFunctionSchoolStudent(user, schoolId, allCustomerStudents, values.students, '');
    }

    if (formId) {
      return searchFunctionStudents(user, text, allCustomerStudents, schoolId, formId, values.students);
    } else {
      return searchFunctionSchoolStudent(user, schoolId, allCustomerStudents, values.students, text);
    }
  };

  const getForms = (text, schoolId, setFieldValue, values, index) => {
    const studentId = values.students[index].studentId;

    if (!studentId) {
      const forms = searchFunctionForms(user, schoolId, text);
      return forms;
    } else {
      return [
        {
          name: values.forms[index].form.name,
          id: values.forms[index].form.id,
        },
      ];
    }
  };

  const renderAlreadyLinkedStudents = () => {
    return allCustomerStudents
      .filter(student => {
        const studentId = propz.get(student, ['student', 'studentId']);
        return typeof studentId !== 'undefined';
      })
      .map((student, index) => {
        const forename = propz.get(student, ['student', 'forename'], '');
        const surname = propz.get(student, ['student', 'surname'], '');
        const schoolName = propz.get(student, ['school', 'schoolName'], '');
        const parentCode = propz.get(student, ['school', 'parentCode'], '');
        const schoolData = parentCode !== '' ? `${parentCode} ${schoolName}` : schoolName;

        return (
          <div key={`${student.id}`}>
            <div>
              {index + 1}. {forename} {surname}, School: {schoolData}
            </div>
          </div>
        );
      });
  };

  const customerName = getCustomerName(customer);

  return (
    <div>
      <div className="mb-4">
        <strong>Customer: </strong>
        {customerName}
      </div>
      <div className="mb-2">
        <strong>The students this customer is already linked to:</strong>
        {renderAlreadyLinkedStudents()}
      </div>
      <div>
        <strong>The students this customer has requested to be linked to:</strong>
      </div>
      <Formik initialValues={studentsForm} validationSchema={StudentsSchema} onSubmit={onSubmit}>
        {({ touched, errors, handleSubmit, values, setFieldValue, setErrors }) => (
          <Form>
            <div className="form-group custom-form-group">
              {customer.students.map((student, index) => {
                const {
                  forename,
                  surname,
                  school,
                  requestStatus,
                  form,
                  id,
                  isConfirmLegallyEntitled,
                  isLegalCarer,
                  isParentOrGuardian,
                  relation,
                  requestComment,
                  dayOfBorn,
                  birthday,
                  reasonForCancellation,
                } = student;
                const { schoolCode = '', schoolName = '', schoolId = '', leaNumber = '', dfesNumber = '' } =
                  school || {};
                const isNewRequestStatus = requestStatus === STUDENT_REQUEST_STATUS.NEW;
                const isNoMatchRequestStatus = requestStatus === STUDENT_REQUEST_STATUS.NO_MATCH;
                const isConfirmLegallyEntitledExist = typeof isConfirmLegallyEntitled !== 'undefined';
                const isDayOfBornExist = typeof dayOfBorn !== 'undefined';
                const isBirthdayExist = typeof birthday !== 'undefined';
                const studentBirthday = isBirthdayExist ? Moment(new Date(birthday)).format(BIRTH_DATE_FORMAT) : '';
                const isReasonForCancellationExist = typeof reasonForCancellation !== 'undefined';
                const isAutoAcceptUnavailable =
                  reasonForCancellation === REASON_FOR_CANCELLATION.AUTO_ACCEPT_UNAVAILABLE;
                const customerReasonForCancellation =
                  REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING[reasonForCancellation];

                const selectedSchool = propz.get(student, ['selectedSchool'], null);
                const selectedSchoolName = selectedSchool?.schoolName || '';
                const selectedSchoolLeaNumber = selectedSchool?.leaNumber || '';
                const selectedSchoolDfesNumber = selectedSchool?.dfesNumber || '';

                return (
                  <div key={id} className="student-block mb-4 p-3 border rounded">
                    <div className="student-details">
                      <div className="student-detail-row">
                        <div className="student-detail-label">
                          <strong>Forename:</strong>
                        </div>
                        <div className="student-detail-value">{forename}</div>
                      </div>
                      <div className="student-detail-row">
                        <div className="student-detail-label">
                          <strong>Surname:</strong>
                        </div>
                        <div className="student-detail-value">{surname}</div>
                      </div>
                      {isReasonForCancellationExist && (
                        <div className="student-detail-row">
                          <div className="student-detail-label">
                            <strong>No auto-accept reason:</strong>
                          </div>
                          <div className="student-detail-value">
                            <span className={isAutoAcceptUnavailable ? 'mReasonAutoAcceptUnavailable' : ''}>
                              {customerReasonForCancellation}
                            </span>
                          </div>
                        </div>
                      )}
                      {isDayOfBornExist && isBirthdayExist && (
                        <div className="student-detail-row">
                          <div className="student-detail-label">
                            <strong>Student's DOB:</strong>
                          </div>
                          <div className="student-detail-value">
                            {studentBirthday} ({dayOfBorn})
                          </div>
                        </div>
                      )}
                      {isConfirmLegallyEntitledExist && (
                        <>
                          <div className="student-detail-row">
                            <div className="student-detail-label">
                              <strong>Parent / Guardian:</strong>
                            </div>
                            <div className="student-detail-value">{getYesOrNoForBoolean(isParentOrGuardian)}</div>
                          </div>
                          <div className="student-detail-row">
                            <div className="student-detail-label">
                              <strong>Relation - Please state:</strong>
                            </div>
                            <div className="student-detail-value">{relation}</div>
                          </div>
                          <div className="student-detail-row">
                            <div className="student-detail-label">
                              <strong>Legal carer:</strong>
                            </div>
                            <div className="student-detail-value">{getYesOrNoForBoolean(isLegalCarer)}</div>
                          </div>
                          <div className="student-detail-row">
                            <div className="student-detail-label">
                              <strong>Other - Please state:</strong>
                            </div>
                            <div className="student-detail-value">{requestComment}</div>
                          </div>
                        </>
                      )}
                      <div className="student-detail-row">
                        <div className="student-detail-label">
                          <strong>Status:</strong>
                        </div>
                        <div className="student-detail-value">
                          <span className={isNewRequestStatus ? 'mRequestStatusNew' : 'mRequestStatusPostponed'}>
                            {requestStatus}
                          </span>
                        </div>
                      </div>
                      {student.manualSchoolName && (
                        <div className="student-detail-row">
                          <div className="student-detail-label">
                            <strong>Manual school name:</strong>
                          </div>
                          <div className="student-detail-value">{student.manualSchoolName}</div>
                        </div>
                      )}
                    </div>

                    <div className="action-icons-row d-flex justify-content-end mt-3">
                      <div className="bStudentLinkedIconBlock text-right">
                        <img
                          className="mr-2 mCursorPointer"
                          width="30px"
                          src="/dist/images/icon/mapsAndFlags.png"
                          title="Approve"
                          onClick={() => {
                            const students = [...values.students];
                            const currentStudent = values.students[index];
                            const formDisabled = currentStudent.formDisabled;

                            if (!formDisabled) {
                              const emptyStudent = {
                                studentId: '',
                                forename: '',
                                surname: '',
                                formDisabled: true,
                                isApproved: false,
                              };
                              students.splice(index, 1, emptyStudent);
                            } else {
                              const updatedStudent = { ...currentStudent };
                              updatedStudent.formDisabled = false;
                              updatedStudent.isApproved = true;
                              students.splice(index, 1, updatedStudent);
                            }

                            setFieldValue('students', students);
                          }}
                        />
                        {isNewRequestStatus ? (
                          <img
                            className="mr-2 mCursorPointer"
                            width="30px"
                            src="/dist/images/icon/clock.png"
                            title="Postpone"
                            onClick={() => {
                              const students = [...values.students];
                              const forms = [...values.forms];
                              const commentEnabled = values.students[index].commentEnabled;

                              const updatedStudent = {
                                studentId: '',
                                forename: '',
                                surname: '',
                                formDisabled: true,
                                commentEnabled: !commentEnabled,
                              };
                              students.splice(index, 1, updatedStudent);
                              setFieldValue('students', students);

                              const emptyForm = {
                                formId: undefined,
                                form: {
                                  id: '',
                                  name: '',
                                },
                              };
                              forms.splice(index, 1, emptyForm);
                              setFieldValue('forms', forms);
                            }}
                          />
                        ) : (
                          <img
                            className="mr-2 mCursorPointer"
                            width="30px"
                            src="/dist/images/icon/reverseArrow.png"
                            title="Back to New"
                            onClick={() => updateRequestStatusToNewCustomerStudentClick(id)}
                          />
                        )}
                        {!isNoMatchRequestStatus && (
                          <img
                            className="mr-2 mCursorPointer"
                            width="30px"
                            src="/dist/images/icon/information.png"
                            title="No match"
                            onClick={() => updateRequestStatusNoMatchCustomerStudentClick(id)}
                          />
                        )}
                        <img
                          className="mr-2 mCursorPointer"
                          width="30px"
                          src="/dist/images/icon/multiply.png"
                          title="Decline"
                          onClick={() => onDeleteStudentClick(id)}
                        />
                      </div>
                    </div>

                    {values.students[index].commentEnabled && (
                      <div className="form-group">
                        <label>Reason for postponing:</label>
                        <textarea
                          rows={4}
                          onChange={event => {
                            const commentText = event.target.value;
                            const students = [...values.students];
                            const currentStudent = { ...students[index] };
                            currentStudent.comment = commentText;
                            students.splice(index, 1, currentStudent);
                            setFieldValue('students', students);
                          }}
                          value={values.students[index].comment}
                          className="form-control mb-3"
                        />
                      </div>
                    )}

                    {selectedSchool && (
                      <div className="selected-school-block mt-3 p-3 border rounded bg-light">
                        <h5>Selected School</h5>
                        <div>
                          <strong>School Name:</strong> {selectedSchool.schoolName}
                        </div>
                        <div>
                          <strong>LEA Number:</strong> {selectedSchool.leaNumber}
                        </div>
                        <div>
                          <strong>DFES Number:</strong> {selectedSchool.dfesNumber}
                        </div>
                      </div>
                    )}

                    {school && (
                      <div className="matched-school-block mt-3 p-3 border rounded bg-light">
                        <h5>Matched School</h5>
                        <div>
                          <strong>School Name:</strong> {school.schoolName || 'N/A'}
                        </div>
                        <div>
                          <strong>LEA Number:</strong> {school.leaNumber || 'N/A'}
                        </div>
                        <div>
                          <strong>DFES Number:</strong> {school.dfesNumber || 'N/A'}
                        </div>
                      </div>
                    )}

                    <div className="form-group mt-3">
                      <label>
                        <strong>School: </strong>
                      </label>
                      <Field name={`students.${index}.schoolId`}>
                        {() => (
                          <div
                            onClick={() => {
                              const updatedStudents = [...values.students];
                              updatedStudents[index] = {
                                ...updatedStudents[index],
                                schoolId: '',
                                school: null,
                              };
                              setFieldValue('students', updatedStudents);

                              setFieldValue(`forms.${index}.formId`, '');
                              setFieldValue(`students.${index}.studentId`, '');

                              const updatedCustomerStudents = [...customer.students];
                              updatedCustomerStudents[index] = {
                                ...updatedCustomerStudents[index],
                                school: null,
                              };
                              customer.students = updatedCustomerStudents;
                            }}
                          >
                            <Autocomplete
                              searchFunction={getSchools}
                              getElementTitle={getSchoolNameAndCode}
                              customClass="mFullWidth mb-3"
                              defaultItem={
                                student.school
                                  ? {
                                      name: student.school.schoolName,
                                      code: student.school.schoolCode,
                                      schoolId: student.school.schoolId,
                                    }
                                  : { name: '', code: '', schoolId: '' }
                              }
                              disabled={values.students[index].formDisabled}
                              onSelect={async school => {
                                if (!school) {
                                  const updatedStudents = [...values.students];
                                  updatedStudents[index] = {
                                    ...updatedStudents[index],
                                    schoolId: '',
                                    school: null,
                                  };
                                  setFieldValue('students', updatedStudents);

                                  setFieldValue(`forms.${index}.formId`, '');
                                  setFieldValue(`students.${index}.studentId`, '');

                                  const updatedCustomerStudents = [...customer.students];
                                  updatedCustomerStudents[index] = {
                                    ...updatedCustomerStudents[index],
                                    school: null,
                                  };
                                  customer.students = updatedCustomerStudents;

                                  return;
                                }

                                const selectedSchoolId = school.schoolId
                                  ? school.schoolId.toString()
                                  : school.id.toString();

                                try {
                                  const updatedStudent = await updateCustomerStudent(user, customer.id, id, {
                                    schoolId: selectedSchoolId,
                                  });

                                  const updatedSchool = updatedStudent.school || school;
                                  const updatedStudents = [...values.students];
                                  updatedStudents[index] = {
                                    ...updatedStudents[index],
                                    schoolId: updatedSchool.schoolId,
                                    school: updatedSchool,
                                    formDisabled: false,
                                  };
                                  setFieldValue('students', updatedStudents);

                                  setFieldValue(`forms.${index}.formId`, '');
                                  setFieldValue(`students.${index}.studentId`, '');

                                  const updatedCustomerStudents = [...customer.students];
                                  updatedCustomerStudents[index] = {
                                    ...updatedCustomerStudents[index],
                                    school: updatedSchool,
                                  };
                                  customer.students = updatedCustomerStudents;
                                } catch (error) {
                                  console.error('Error updating student school:', error);
                                }
                              }}
                            />
                          </div>
                        )}
                      </Field>
                      <ErrorMessage
                        component="div"
                        className="alert alert-danger"
                        name={`students.${index}.schoolId`}
                      />
                    </div>

                    <div className="form-group">
                      <label>
                        <strong>Form: </strong>
                      </label>
                      <Field name="form">
                        {() => (
                          <Autocomplete
                            searchFunction={text => getForms(text, schoolId, setFieldValue, values, index)}
                            getElementTitle={getFormName}
                            disabled={values.students[index].formDisabled}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.forms[index]}
                            onSelect={form => {
                              const forms = [...values.forms].slice();
                              forms[index].formId = form.id;
                              forms[index].form.name = form.name;
                              forms[index].form.id = form.id;
                              setFieldValue('forms', forms);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage component="div" className="alert alert-danger" name={`forms.${index}.formId`} />
                    </div>

                    <div className="form-group">
                      <label>
                        <strong>Student: </strong>
                      </label>
                      <Field name="student">
                        {() => (
                          <Autocomplete
                            searchFunction={text => getStudents(text, schoolId, setFieldValue, values, index)}
                            getElementTitle={getForenameAndSurname}
                            customClass="mFullWidth mb-3"
                            defaultItem={values.students[index]}
                            disabled={values.students[index].formDisabled}
                            onSelect={student => {
                              const students = [...values.students];
                              students[index].studentId = student.id;
                              students[index].forename = student.forename;
                              students[index].surname = student.surname;
                              setFieldValue('students', students);
                              const isFormIdExist =
                                typeof values.forms[index].formId !== 'undefined' && values.forms[index].formId !== '';
                              if (!isFormIdExist) {
                                const forms = [...values.forms];
                                forms[index].formId = student.form.formId;
                                forms[index].form.name = student.form.formName;
                                forms[index].form.id = student.form.formId;
                                setFieldValue('forms', forms);
                              }
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        component="div"
                        className="alert alert-danger"
                        name={`students.${index}.studentId`}
                      />
                    </div>
                  </div>
                );
              })}
              <div className="d-flex justify-content-end">
                <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                <Button
                  onClick={handleSubmit}
                  text={'Save'}
                  customClass={'mt-3 mb-3 btn btn-primary'}
                  type={'submit'}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

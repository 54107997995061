import axios from 'axios';
import { ALL_LIMIT } from '../../consts/table';
import { AppSchoolsUser2 } from '../../views/AppSchools2/AppSchools2';

export function getSchoolUserSchoolJobImages(user: AppSchoolsUser2, schoolJobId: string, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/schoolUser/schoolJobs/${schoolJobId}/schoolImages`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUserAllSchoolJobImages(user: AppSchoolsUser2, schoolJobId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/schoolUser/schoolJobs/${schoolJobId}/schoolImages`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUserSchoolJobImagesCount(user: AppSchoolsUser2, schoolJobId: string, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios
    .get(`${window.apiBase}/schoolUser/schoolJobs/${schoolJobId}/schoolImages/count`, config)
    .then(response => {
      return response.data;
    });
}

export function getSchoolUserSchoolJobImage(user: AppSchoolsUser2, schoolJobId: string, schoolImageId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .get(`${window.apiBase}/schoolUser/schoolJobs/${schoolJobId}/schoolImages/${schoolImageId}?t=${Date.now()}`, config)
    .then(response => response.data);
}

export function updateSchoolUserSchoolJobImage(
  user: AppSchoolsUser2,
  schoolJobId: string,
  schoolImageId: string,
  data
) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios
    .put(`${window.apiBase}/schoolUser/schoolJobs/${schoolJobId}/schoolImages/${schoolImageId}`, data, config)
    .then(response => {
      return response.data;
    });
}

export function createSchoolUserSchoolJobImageTicket(user: AppSchoolsUser2, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/schoolUser/tickets/create`, data, config).then(response => {
    return response.data;
  });
}

export function createSchoolUserImagePreview(user: AppSchoolsUser2, previewParams: any) {
  const { sessionKey: key } = user;
  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/schoolUser/imagePreview`, previewParams, config).then(response => response.data);
}

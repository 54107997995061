import { SidebarItemExpandable, SimpleSidebarItem } from '../views/App/GenericView/Sidebar/Sidebar';
import { isUserTypeAccountManager, isUserTypeAdmin, isUserTypeSuperAdmin, isUserTypeUser } from './user';
import { AppUser, MenuItemsEnabled } from '../views/App/App';
import { MENU_ITEMS } from '../consts/sidebar';

function getSidebarItemsSuperAdmin(): (SidebarItemExpandable | SimpleSidebarItem)[] {
  return [
    {
      itemTitle: 'Users',
      to: { pathname: '/users' },
      isVisible: true,
    },
    {
      title: 'Schools',
      isVisible: true,
      items: [
        {
          to: { pathname: '/schoolTypes' },
          itemTitle: 'School types',
          isVisible: true,
        },
        {
          to: { pathname: '/schools' },
          itemTitle: 'Schools',
          isVisible: true,
        },
        {
          to: { pathname: '/allSchools' },
          itemTitle: 'All schools',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Products and Packages',
      isVisible: true,
      items: [
        {
          to: { pathname: '/photoTypes' },
          itemTitle: 'Photo types',
          isVisible: true,
        },
        {
          to: { pathname: '/productTypes' },
          itemTitle: 'Product types',
          isVisible: true,
        },
        {
          to: { pathname: '/products' },
          itemTitle: 'Products',
          isVisible: true,
        },
        {
          to: { pathname: '/packages' },
          itemTitle: 'Packages',
          isVisible: true,
        },
        {
          to: { pathname: '/offers' },
          itemTitle: 'Offers',
          isVisible: true,
        },
        {
          to: { pathname: '/vouchers' },
          itemTitle: 'Vouchers',
          isVisible: true,
        },
        {
          to: { pathname: '/deliveryCharges' },
          itemTitle: 'Delivery charges',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Jobs and Pre Registrations',
      isVisible: true,
      items: [
        {
          to: { pathname: '/preRegistrations' },
          itemTitle: 'Pre Registrations',
          isVisible: true,
        },
        {
          to: { pathname: '/jobs' },
          itemTitle: 'Jobs',
          isVisible: true,
        },
        {
          to: { pathname: '/images' },
          itemTitle: 'All images',
          isVisible: true,
        },
      ],
    },
    {
      title: 'School Jobs',
      isVisible: true,
      items: [
        {
          to: { pathname: '/schoolJobs' },
          itemTitle: 'Jobs',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Orders',
      isVisible: true,
      items: [
        {
          to: { pathname: '/orders' },
          itemTitle: 'Orders',
          isVisible: true,
        },
        {
          to: { pathname: '/allOrderImages' },
          itemTitle: 'All ordered images',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Customers',
      isVisible: true,
      items: [
        {
          to: { pathname: '/customers' },
          itemTitle: 'Customers',
          isVisible: true,
        },
        {
          to: { pathname: '/customerWithStudents' },
          itemTitle: 'Pending parental requests',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Website settings',
      isVisible: true,
      items: [
        {
          to: { pathname: '/sliders' },
          itemTitle: 'Promotions',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Integration',
      isVisible: true,
      items: [
        {
          to: { pathname: '/integrationLogs' },
          itemTitle: 'Integration log',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Unsubscribed emails',
      isVisible: true,
      items: [
        {
          to: { pathname: '/unsubscribedEmails' },
          itemTitle: 'Emails',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Data exchange',
      isVisible: true,
      items: [
        {
          to: { pathname: '/dataExchange' },
          itemTitle: 'Data exchange',
          isVisible: true,
        },
        {
          to: { pathname: 'contactDetails' },
          itemTitle: 'Contact details',
          isVisible: true,
        },
      ],
    },
  ];
}

function getSidebarItemsAdmin(menuItemsEnabled: MenuItemsEnabled): (SidebarItemExpandable | SimpleSidebarItem)[] {
  return [
    {
      itemTitle: 'Users',
      to: { pathname: '/users' },
      isVisible: menuItemsEnabled[MENU_ITEMS.USERS],
    },
    {
      title: 'Schools',
      isVisible: menuItemsEnabled[MENU_ITEMS.SCHOOL_TYPES] || menuItemsEnabled[MENU_ITEMS.SCHOOLS],
      items: [
        {
          to: { pathname: '/schoolTypes' },
          itemTitle: 'School types',
          isVisible: menuItemsEnabled[MENU_ITEMS.SCHOOL_TYPES],
        },
        {
          to: { pathname: '/schools' },
          itemTitle: 'Schools',
          isVisible: menuItemsEnabled[MENU_ITEMS.SCHOOLS],
        },
        {
          to: { pathname: '/allSchools' },
          itemTitle: 'All schools',
          isVisible: menuItemsEnabled[MENU_ITEMS.ALL_SCHOOLS],
        },
      ],
    },
    {
      title: 'Products and Packages',
      isVisible:
        menuItemsEnabled[MENU_ITEMS.PHOTO_TYPES] ||
        menuItemsEnabled[MENU_ITEMS.PRODUCT_TYPES] ||
        menuItemsEnabled[MENU_ITEMS.PRODUCTS] ||
        menuItemsEnabled[MENU_ITEMS.PACKAGES] ||
        menuItemsEnabled[MENU_ITEMS.OFFERS] ||
        menuItemsEnabled[MENU_ITEMS.VOUCHERS] ||
        menuItemsEnabled[MENU_ITEMS.DELIVERY_CHARGES],
      items: [
        {
          to: { pathname: '/photoTypes' },
          itemTitle: 'Photo types',
          isVisible: menuItemsEnabled[MENU_ITEMS.PHOTO_TYPES],
        },
        {
          to: { pathname: '/productTypes' },
          itemTitle: 'Product types',
          isVisible: menuItemsEnabled[MENU_ITEMS.PRODUCT_TYPES],
        },
        {
          to: { pathname: '/products' },
          itemTitle: 'Products',
          isVisible: menuItemsEnabled[MENU_ITEMS.PRODUCTS],
        },
        {
          to: { pathname: '/packages' },
          itemTitle: 'Packages',
          isVisible: menuItemsEnabled[MENU_ITEMS.PACKAGES],
        },
        {
          to: { pathname: '/offers' },
          itemTitle: 'Offers',
          isVisible: menuItemsEnabled[MENU_ITEMS.OFFERS],
        },
        {
          to: { pathname: '/vouchers' },
          itemTitle: 'Vouchers',
          isVisible: menuItemsEnabled[MENU_ITEMS.VOUCHERS],
        },
        {
          to: { pathname: '/deliveryCharges' },
          itemTitle: 'Delivery charges',
          isVisible: menuItemsEnabled[MENU_ITEMS.DELIVERY_CHARGES],
        },
      ],
    },
    {
      title: 'Jobs and Pre Registrations',
      isVisible:
        menuItemsEnabled[MENU_ITEMS.PRE_REGISTRATIONS] ||
        menuItemsEnabled[MENU_ITEMS.JOBS] ||
        menuItemsEnabled[MENU_ITEMS.IMAGES],
      items: [
        {
          to: { pathname: '/preRegistrations' },
          itemTitle: 'Pre Registrations',
          isVisible: menuItemsEnabled[MENU_ITEMS.PRE_REGISTRATIONS],
        },
        {
          to: { pathname: '/jobs' },
          itemTitle: 'Jobs',
          isVisible: menuItemsEnabled[MENU_ITEMS.JOBS],
        },
        {
          to: { pathname: '/images' },
          itemTitle: 'All images',
          isVisible: menuItemsEnabled[MENU_ITEMS.IMAGES],
        },
      ],
    },
    {
      title: 'School Jobs',
      isVisible: true,
      items: [
        {
          to: { pathname: '/schoolJobs' },
          itemTitle: 'Jobs',
          isVisible: menuItemsEnabled[MENU_ITEMS.SCHOOL_JOBS],
        },
      ],
    },
    {
      title: 'Orders',
      isVisible: menuItemsEnabled[MENU_ITEMS.ORDERS],
      items: [
        {
          to: { pathname: '/orders' },
          itemTitle: 'Orders',
          isVisible: menuItemsEnabled[MENU_ITEMS.ORDERS],
        },
        {
          to: { pathname: '/allOrderImages' },
          itemTitle: 'All ordered images',
          isVisible: menuItemsEnabled[MENU_ITEMS.ALL_ORDER_IMAGES],
        },
      ],
    },
    {
      title: 'Customers',
      isVisible: menuItemsEnabled[MENU_ITEMS.CUSTOMERS] || menuItemsEnabled[MENU_ITEMS.CUSTOMER_WITH_STUDENTS],
      items: [
        {
          to: { pathname: '/customers' },
          itemTitle: 'Customers',
          isVisible: menuItemsEnabled[MENU_ITEMS.CUSTOMERS],
        },
        {
          to: { pathname: '/customerWithStudents' },
          itemTitle: 'Pending parental requests',
          isVisible: menuItemsEnabled[MENU_ITEMS.CUSTOMER_WITH_STUDENTS],
        },
      ],
    },
    {
      title: 'Website settings',
      isVisible: menuItemsEnabled[MENU_ITEMS.SLIDERS],
      items: [
        {
          to: { pathname: '/sliders' },
          itemTitle: 'Promotions',
          isVisible: menuItemsEnabled[MENU_ITEMS.SLIDERS],
        },
      ],
    },
    {
      title: 'Integration',
      isVisible: true,
      items: [
        {
          to: { pathname: '/integrationLogs' },
          itemTitle: 'Integration log',
          isVisible: menuItemsEnabled[MENU_ITEMS.INTEGRATION_LOGS],
        },
      ],
    },
    {
      title: 'Unsubscribed emails',
      isVisible: true,
      items: [
        {
          to: { pathname: '/unsubscribedEmails' },
          itemTitle: 'Emails',
          isVisible: menuItemsEnabled[MENU_ITEMS.UNSUBSCRIBED_EMAILS],
        },
      ],
    },
    {
      title: 'Data exchange',
      isVisible: true,
      items: [
        {
          to: { pathname: '/dataExchange' },
          itemTitle: 'Data exchange',
          isVisible: menuItemsEnabled[MENU_ITEMS.DATA_EXCHANGE],
        },
        {
          to: { pathname: '/contactDetails' },
          itemTitle: 'Contact details',
          isVisible: menuItemsEnabled[MENU_ITEMS.CONTACT_DETAILS],
        },
      ],
    },
  ];
}

function getSidebarItemsAccountManager(): (SidebarItemExpandable | SimpleSidebarItem)[] {
  return [
    {
      title: 'Schools',
      isVisible: true,
      items: [
        {
          to: { pathname: '/schoolTypes' },
          itemTitle: 'School types',
          isVisible: true,
        },
        {
          to: { pathname: '/schools' },
          itemTitle: 'Schools',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Products and Packages',
      isVisible: true,
      items: [
        {
          to: { pathname: '/photoTypes' },
          itemTitle: 'Photo types',
          isVisible: true,
        },
        {
          to: { pathname: '/productTypes' },
          itemTitle: 'Product types',
          isVisible: true,
        },
        {
          to: { pathname: '/products' },
          itemTitle: 'Products',
          isVisible: true,
        },
        {
          to: { pathname: '/packages' },
          itemTitle: 'Packages',
          isVisible: true,
        },
      ],
    },
    {
      title: 'Jobs and Pre Registrations',
      isVisible: true,
      items: [
        {
          to: { pathname: '/preRegistrations' },
          itemTitle: 'Pre Registrations',
          isVisible: true,
        },
        {
          to: { pathname: '/jobs' },
          itemTitle: 'Jobs',
          isVisible: true,
        },
      ],
    },
  ];
}

export function getSidebarItemsUser(): (SidebarItemExpandable | SimpleSidebarItem)[] {
  return [];
}

export function getSidebarItemsDefault(): (SidebarItemExpandable | SimpleSidebarItem)[] {
  return [];
}

export function getSidebarItems(
  user: AppUser,
  menuItemsEnabled: MenuItemsEnabled
): (SidebarItemExpandable | SimpleSidebarItem)[] {
  switch (true) {
    case isUserTypeSuperAdmin(user):
      return getSidebarItemsSuperAdmin();
    case isUserTypeAdmin(user):
      return getSidebarItemsAdmin(menuItemsEnabled);
    case isUserTypeAccountManager(user):
      return getSidebarItemsAccountManager();
    case isUserTypeUser(user):
      return getSidebarItemsUser();
    default:
      return getSidebarItemsDefault();
  }
}

export function isSidebarItemExpandable(arg: SidebarItemExpandable | SimpleSidebarItem): arg is SidebarItemExpandable {
  return Array.isArray((arg as SidebarItemExpandable).items);
}

import axios from 'axios';
import { AppUser } from '../views/App/App';
import { AppSchoolsUser2 } from '../views/AppSchools2/AppSchools2';

export function uploadFile(user: AppUser, formData) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(`${window.apiFile}/storage/files/public/upload`, formData, config).then(response => {
    return response.data;
  });
}

export function uploadFileSchoolUser(user: AppSchoolsUser2, formData) {
  const key = user.sessionKey;

  const config = {
    headers: {
      usid: key,
      'Content-Type': 'multipart/form-data',
    },
  };

  return axios.post(`${window.apiFile}/storage/files/public/upload`, formData, config).then(response => {
    return response.data;
  });
}

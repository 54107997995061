export const CUSTOMER_TABS = [
  'Permissions',
  'Notification channels',
  'Notifications',
  'Address book',
  'Students',
  'Orders',
];
export const STUDENT_REQUEST_STATUS = {
  NEW: 'NEW',
  POSTPONED: 'POSTPONED',
  NO_MATCH: 'NO_MATCH',
  AUTO_MATCHED: 'AUTO_MATCHED',
};

export const STUDENT_REQUEST_STATUS_SERVER_TO_CLIENT_MAPPING = {
  NEW: 'New',
  POSTPONED: 'Postponed',
  NO_MATCH: 'No matched',
  AUTO_MATCHED: 'Auto matched',
};

export const REASON_FOR_CANCELLATION = {
  STUDENT_NOT_FOUND: 'STUDENT_NOT_FOUND',
  SURNAME_MISMATCH: 'SURNAME_MISMATCH',
  AUTO_ACCEPT_UNAVAILABLE: 'AUTO_ACCEPT_UNAVAILABLE',
  CUSTOMER_STUDENTS_OVERLOAD: 'CUSTOMER_STUDENTS_OVERLOAD',
  BIRTHDAY_MISMATCH: 'BIRTHDAY_MISMATCH',
  SCHOOL_NOT_SERVED: 'SCHOOL_NOT_SERVED',
  FORM_INPUTTED_MANUALLY: 'FORM_INPUTTED_MANUALLY',
  SCHOOL_INPUTTED_MANUALLY: 'SCHOOL_INPUTTED_MANUALLY',
};

export const REASON_FOR_CANCELLATION_SERVER_TO_CLIENT_MAPPING = {
  STUDENT_NOT_FOUND: 'No match found',
  SURNAME_MISMATCH: "Surnames don't match",
  AUTO_ACCEPT_UNAVAILABLE: 'Autoaccept is off',
  CUSTOMER_STUDENTS_OVERLOAD: 'Multiple surnames',
  BIRTHDAY_MISMATCH: "DOB doesn't match",
  SCHOOL_NOT_SERVED: 'School not served',
  FORM_INPUTTED_MANUALLY: 'Form inputted manually',
  SCHOOL_INPUTTED_MANUALLY: 'School name inputted manually',
};
